import React, { useEffect, useState } from 'react';
import getAuthHeaders from './functions/getAuthHeaders';
import { Link } from 'react-router-dom';
import ReportingAgents from './Reporting/ReportingAgents.js';
import { debounce } from 'lodash';

const Agents = () => {
  const [agents, setAgents] = useState([]);
  const [newAgent, setNewAgent] = useState({
    FirstName: '',
    LastName: '',
    Company: '',
    Email: '',
    Phone: '',
    Address: '',
    Jurisdiction: ''
  });
  const [activeTab, setActiveTab] = useState('Agents');
  const [searchBy, setSearchBy] = useState('');
  const [filterQuery, setFilterQuery] = useState('');
  const [filteredAgents, setFilteredAgents] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const agentsPerPage = 7;

  const indexOfLastAgent = currentPage * agentsPerPage;
  const indexOfFirstAgent = indexOfLastAgent - agentsPerPage;
  const currentFilteredAgents = filteredAgents.slice(indexOfFirstAgent, indexOfLastAgent);

  useEffect(() => {
    fetchAgents();
  }, []);

  useEffect(() => {
    filterAgents();
  }, [filterQuery, searchBy, agents]);

  const fetchAgents = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/agents`, {
        headers: await getAuthHeaders()
      });
      const data = await response.json();
      const sortedAgents = data.sort((a, b) => a.AgentID.localeCompare(b.AgentID));
      setAgents(sortedAgents);
      setFilteredAgents(sortedAgents); // Initialize filteredAgents with all agents
    } catch (error) {
      console.log('Error fetching agents:', error);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewAgent((prevAgent) => ({
      ...prevAgent,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/agents`, {
        method: 'POST',
        headers: await getAuthHeaders(),
        body: JSON.stringify(newAgent),
      });

      if (response.ok) {
        fetchAgents();
        setNewAgent({
          FirstName: '',
          LastName: '',
          Company: '',
          Email: '',
          Phone: '',
          Address: '',
          Jurisdiction: ''
        });
        setActiveTab('Agents'); // Switch back to Agents tab after adding a new agent
      } else {
        console.log('Failed to add a new agent:', response.statusText);
      }
    } catch (error) {
      console.log('Error adding a new agent:', error);
    }
  };

  const handleSearchByChange = (event) => {
    setSearchBy(event.target.value);
  };

  const handleSearchChange = (event) => {
    setFilterQuery(event.target.value);
  };

  const filterAgents = debounce(() => {
    const filtered = agents.filter((agent) => {
      if (searchBy === 'FirstName') {
        return agent.FirstName.toLowerCase().includes(filterQuery.toLowerCase());
      } else if (searchBy === 'LastName') {
        return agent.LastName.toLowerCase().includes(filterQuery.toLowerCase());
      } else if (searchBy === 'Company') {
        return agent.Company.toLowerCase().includes(filterQuery.toLowerCase());
      } else if (searchBy === 'Email') {
        return agent.Email.toLowerCase().includes(filterQuery.toLowerCase());
      } else if (searchBy === 'Phone') {
        return agent.Phone.includes(filterQuery);
      } else if (searchBy === 'Address') {
        return agent.Address.toLowerCase().includes(filterQuery.toLowerCase());
      } else if (searchBy === 'Jurisdiction') {
        return agent.Jurisdiction.toLowerCase().includes(filterQuery.toLowerCase());
      }
      return true;
    });
    setFilteredAgents(filtered);
  }, 300);

  const handleNext = () => {
    const totalPages = Math.ceil(filteredAgents.length / agentsPerPage);
    setCurrentPage(prevPage => (prevPage < totalPages ? prevPage + 1 : prevPage));
  };

  const handlePrevious = () => {
    setCurrentPage(prevPage => (prevPage > 1 ? prevPage - 1 : prevPage));
  };

  return (
    <div className="table-container">
      <div className="tabs-container">
        <button
          className={`tab ${activeTab === 'Agents' ? 'active-tab' : ''}`}
          onClick={() => setActiveTab('Agents')}
        >
          Agents
        </button>
        <button
          className={`tab ${activeTab === 'AddAgents' ? 'active-tab' : ''}`}
          onClick={() => setActiveTab('AddAgents')}
        >
          Add Agent
        </button>
      </div>
  
      {activeTab === 'Agents' && (
        <>
   <div className="add-search-sort-container">
          <div className="search-sort-container"></div>
            <div className="search-sort-container">
            <div style={{ marginBottom: '9px'}}><ReportingAgents /></div>
              <div>
                <input type="text" placeholder="Search" value={filterQuery} onChange={handleSearchChange} />
              </div>
              <div className="MD-button-container" style={{marginBottom: '25px'}}>
                <select value={searchBy} onChange={handleSearchByChange}>
                  <option value="">Filter</option>
                  <option value="FirstName">First Name</option>
                  <option value="LastName">Last Name</option>
                  <option value="Company">Company</option>
                  <option value="Email">Email</option>
                  <option value="Phone">Phone</option>
                  <option value="Address">Address</option>
                  <option value="Jurisdiction">Jurisdiction</option>
                </select>
              </div>
            </div>
          </div>
          <table className="matter-table">
            <thead>
              <tr>
                <th>Agent ID</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Company</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Address</th>
                <th>Jurisdiction</th>
              </tr>
            </thead>
            <tbody>
              {currentFilteredAgents.length > 0 ? (
                currentFilteredAgents.map((agent) => (
                  <tr key={agent.AgentID}>
                    <td>
                      <Link to={`/agents/${agent.AgentID}`}>
                        {agent.AgentID}
                      </Link>
                    </td>
                    <td>{agent.FirstName}</td>
                    <td>{agent.LastName}</td>
                    <td>{agent.Company}</td>
                    <td>{agent.Email}</td>
                    <td>{agent.Phone}</td>
                    <td>{agent.Address}</td>
                    <td>{agent.Jurisdiction}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8">No agents found</td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="pagination">
            <button onClick={handlePrevious}>Previous</button>
            <span>{currentPage}</span>
            <button onClick={handleNext}>Next</button>
          </div>
        </>
      )}
  
      {activeTab === 'AddAgents' && (
        <form onSubmit={handleSubmit}>
          <label htmlFor="firstName">First Name:</label>
          <input
            type="text"
            id="firstName"
            name="FirstName"
            value={newAgent.FirstName}
            onChange={handleInputChange}
            required
          />
  
          <label htmlFor="lastName">Last Name:</label>
          <input
            type="text"
            id="lastName"
            name="LastName"
            value={newAgent.LastName}
            onChange={handleInputChange}
          />
  
          <label htmlFor="company">Company:</label>
          <input
            type="text"
            id="company"
            name="Company"
            value={newAgent.Company}
            onChange={handleInputChange}
          />
  
          <label htmlFor="email">Email:</label>
          <input
            type="text"
            id="email"
            name="Email"
            value={newAgent.Email}
            onChange={handleInputChange}
            required
          />
  
          <label htmlFor="phone">Phone:</label>
          <input
            type="text"
            id="phone"
            name="Phone"
            value={newAgent.Phone}
            onChange={handleInputChange}
          />
  
          <label htmlFor="address">Address:</label>
          <input
            type="text"
            id="address"
            name="Address"
            value={newAgent.Address}
            onChange={handleInputChange}
          />
  
          <label htmlFor="jurisdiction">Jurisdiction:</label>
          <input
            type="text"
            id="jurisdiction"
            name="Jurisdiction"
            value={newAgent.Jurisdiction}
            onChange={handleInputChange}
          />
  
          <button type="submit">Add Agent</button>
        </form>
      )}
    </div>
  );
};

export default Agents;
