import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, Form } from 'react-router-dom';
import './styles/CreateMatter.css';
import { FaArrowLeft } from 'react-icons/fa';
import axios, { getAdapter } from 'axios';
import { useNavigate } from 'react-router-dom';
import getAuthHeaders from './functions/getAuthHeaders';


const CreateMatter = () => {
  const navigate = useNavigate(); // Access the navigate function
  const [newMatter, setNewMatter] = useState({
    mattername: '',
    clientid: '',
    employeeid: '',
    // employees: [],
    prioritylevel: '',
    mattertype: '',
    opendate: null,
    closedate: null,
    amount: 0,
    description: '',
    access: ''
  });
  const [clients, setClients] = useState([]);
  const [employees, setEmployees] = useState([]);
  const fetchClients = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/clients`, {
        headers: await getAuthHeaders()
      });
      if (response) {
        const data = await response.json();
        setClients(data); 
      }
    } catch (error) {
      console.log('Error fetching clients:', error);
    }
  };
  const fetchEmployees = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/employees`, {
        headers: await getAuthHeaders()
      });
      if (response) {
        const data = await response.json();
        console.log(data)
        setEmployees(data); 
      }
    } catch (error) {
      console.log('Error fetching employees:', error);
    }
  };
  
  useEffect(() => {
    fetchClients();
    fetchEmployees();
  }, []);

  const handleAddEmployee = () => {
    setNewMatter((prevState) => ({
      ...prevState,
      employees: [...prevState.employees, ''], // Add a new empty string for a new employee
    }));
  };
  const handleDeleteEmployee = (index) => {
    const newEmployees = [...newMatter.employees];
    newEmployees.splice(index, 1);
    setNewMatter((prevState) => ({
      ...prevState,
      employees: newEmployees,
    }));
  };

  const handleAddMatterInputChange = (e) => {
    const { name, value } = e.target;
    setNewMatter((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAddMatterSubmit = async (e) => {
    e.preventDefault();
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/addmatter`, newMatter, {
          headers: await getAuthHeaders()
        });
        alert("Matter Successfully Created");
        navigate(`/matters/${response.data.newMatterID}`);
    } catch (error) {
        console.log('Error adding matter:', error);
    }
  };

  return (
    <div>
      <form>
      <h4>Create Matter</h4>

      <div className="form-group">
        <label htmlFor="client">Client:</label>
        <select
          id="client"
          name="clientid"
          value={newMatter.clientid}
          onChange={handleAddMatterInputChange}
          required
        >
          <option value="">Select a client</option>
          {clients.map((client) => (
            <option key={client.ClientID} value={client.ClientID}>
              {`${client.ClientID} - ${client.FirstName} (${client.Company})`}
            </option>
          ))}
        </select>
      </div>

      <div className="form-group">
        <label htmlFor="matter-name">Matter Name:</label>
        <input
          type="text"
          id="matter-name"
          name="mattername"
          value={newMatter.mattername}
          onChange={handleAddMatterInputChange}
          required
        />
      </div>

      <div className="form-group">
        <label htmlFor="matter-type">Matter Type:</label>
        <input
          type="text"
          id="matter-type"
          name="mattertype"
          value={newMatter.mattertype}
          onChange={handleAddMatterInputChange}
        />
      </div>

      <div className="form-group">
        <label htmlFor="matter-leader">Matter Leader:</label>
        <select
          id="matter-leader"
          name="employeeid"
          value={newMatter.employeeid}
          onChange={handleAddMatterInputChange}
          required
        >
          <option value="">Select a matter leader</option>
          {employees.map((employee) => (
            <option key={employee.EmployeeID} value={employee.EmployeeID}>
              {`${employee.FirstName} ${employee.LastName}`}
            </option>
          ))}
        </select>
      </div>

      {/* <div className="form-group">
        <label>Employees:</label>
        <div>
          {newMatter.employees.map((employee, index) => (
            <div key={index}>
              <input
                type="text"
                value={employee}
                onChange={(e) => {
                  const newEmployees = [...newMatter.employees];
                  newEmployees[index] = e.target.value;
                  setNewMatter((prevState) => ({
                    ...prevState,
                    employees: newEmployees,
                  }));
                }}
              />
              <button type="button" onClick={() => handleDeleteEmployee(index)}>
                Delete
              </button>
            </div>
          ))}
        </div>
        <button type="button" onClick={handleAddEmployee}>
          + Add Employee
        </button>
      </div> */}

      <div className="form-group">
        <label htmlFor="budget">Budget:</label>
        <input
          type="text"
          id="budget"
          name="amount"
          value={newMatter.amount}
          onChange={handleAddMatterInputChange}
        />
      </div>

      <div className="form-group">
        <label htmlFor="creation-date">Creation Date:</label>
        <input
          type="date"
          id="creation-date"
          name="opendate"
          value={newMatter.opendate}
          onChange={handleAddMatterInputChange}
        />
      </div>

      <div className="form-group">
        <label htmlFor="due-date">Due Date:</label>
        <input
          type="date"
          id="due-date"
          name="closedate"
          value={newMatter.closedate}
          onChange={handleAddMatterInputChange}
        />
      </div>

      <div className="form-group">
        <label htmlFor="description">Description:</label>
        <textarea
          id="text"
          name="description"
          placeholder="Enter description"
          value={newMatter.description}
          onChange={handleAddMatterInputChange}
        ></textarea>
      </div>
      
      <div className="create-button">
        <button type="button" onClick={handleAddMatterSubmit}><strong>Create</strong></button>
      </div>
      </form>
    </div>
  );
};

export default CreateMatter;