import React, { useState, useEffect } from 'react';
import { Storage } from 'aws-amplify';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

const FilePreview = ({ fileKey }) => {
  const [fileUrl, setFileUrl] = useState(null);
  const [fileType, setFileType] = useState('');

  useEffect(() => {
    const fetchFile = async () => {
      try {
        const url = await Storage.get(fileKey);
        setFileUrl(url);
        setFileType(fileKey.split('.').pop().toLowerCase());
      } catch (error) {
        console.error('Error fetching file:', error);
      }
    };

    fetchFile();
  }, [fileKey]);

  const renderPreview = () => {
    if (fileType === 'pdf') {
      return (
        <div style={{ height: '750px', overflow: 'auto' }}>
          <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js">
            <Viewer fileUrl={fileUrl} />
          </Worker>
        </div>
      );
    } else if (fileType === 'jpg' || fileType === 'png' || fileType === 'jpeg') {
      return <img src={fileUrl} alt="Preview" />;
    } else {
      return <p>Unsupported file type.</p>;
    }
  };

  return (
    <div>
      {fileUrl ? renderPreview() : <p>Loading file preview...</p>}
    </div>
  );
};


export default FilePreview;
