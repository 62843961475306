import React, { useState, useEffect } from 'react';
import '../../styles/General.css';
import '../../styles/MatterDetails.css';
import '../../styles/FinancialsTabs.css';
import getAuthHeaders from '../../functions/getAuthHeaders';
import SuccessMessage from '../../components/SuccessMessage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons'; 

const Disbursements = ({matterId}) => {
    const [agents, setAgents] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [disbursements, setDisbursements] = useState([]);
    const [successMessage, setSuccessMessage] = useState('');
    const [editingDisbursementId, setEditingDisbursementId] = useState(null);
    const [showDisbursmentsDropdown, setShowDisbursmentsDropdown] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [formData, setFormData] = useState({
        agentid: '',
        description: '',
        fees: '',
        matterid: matterId
      });
      const EditableRow = ({ disbursement, onSave, onCancel, agents }) => {
        const [editFormData, setEditFormData] = useState({
            agentid: disbursement.agentid.toString(),
            description: disbursement.description,
            fees: disbursement.fees.toString(),
        });
      
        const handleEditFormChange = (event) => {
            const fieldName = event.target.getAttribute("name");
            const fieldValue = event.target.value;
            setEditFormData({ ...editFormData, [fieldName]: fieldValue });
        };
      
        return (
            <tr>
                <td>
                    <select
                        name="agentid"
                        value={editFormData.agentid}
                        onChange={handleEditFormChange}
                    >
                    {agents.map((agent) => (
                        <option key={agent.AgentID} value={agent.AgentID}>
                            {agent.FirstName} {agent.LastName}
                        </option>
                    ))}
                    </select>
                </td>
                <td>
                    <input
                        type="text"
                        name="description"
                        value={editFormData.description}
                        onChange={handleEditFormChange}
                    />
                </td>
                <td>
                    <input
                        type="number"
                        name="fees"
                        value={editFormData.fees}
                        onChange={handleEditFormChange}
                    />
                </td>
                <td>
                    <button type="button" onClick={() => onSave(disbursement.disbursementid, editFormData)}>Save</button>
                    <button type="button" onClick={onCancel}>Cancel</button>
                </td>
            </tr>
        );
      };
    const fetchAgents = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/agents`, {
            headers: await getAuthHeaders()
          });
          const data = await response.json();
          const sortedAgents = data.sort((a, b) => a.AgentID.localeCompare(b.AgentID));
          setAgents(sortedAgents);
        } catch (error) {
          console.log('Error fetching agents:', error);
        }
      };

    const fetchDisbursements = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/agent_disbursements/${matterId}`, {
            headers: await getAuthHeaders()
          });
          const data = await response.json();
          setDisbursements(data);
        } catch (error) {
          console.log('Error fetching disbursements:', error);
        }
      };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/agent_disbursements`, {
            method: 'POST',
            headers: await getAuthHeaders(),
            body: JSON.stringify(formData),
          });
          if (response.ok) {
            setSuccessMessage('Disbursement successfully added');
            setTimeout(() => {
                setSuccessMessage('');
            }, 3000);
            fetchDisbursements();
            setFormData({
                agentid: '',
                description: '',
                fees: '',
                matterid: matterId
            });
          } else {
            console.log('Failed to add disbursement');
          }
        } catch (error) {
          console.error('Error adding disbursement:', error);
        }
      };
    

    const saveEdit = async (id, editFormData) => {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/edit_agent_disbursements/${id}`, {
          method: 'PUT',
          headers: {
              ...await getAuthHeaders(),
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
              ...editFormData,
              disbursementid: id, // Make sure your backend expects this field or adjust accordingly
              matterid: matterId, // Ensure matterId is correctly included
          }),
      });
  
      if (response.ok) {
          setSuccessMessage('Disbursement successfully updated');
          setTimeout(() => {
              setSuccessMessage('');
          }, 3000);
          fetchDisbursements();
      } else {
          console.log('Failed to update disbursement');
      }
      // Reset editing state
      setEditingDisbursementId(null);
      setIsEditing(false);
  };
  
  const startEdit = (disbursement) => {
    setFormData({
        ...disbursement,
        agentid: disbursement.agentid.toString(),
        fees: disbursement.fees.toString()
    });
    setIsEditing(true);
    setShowPopup(true);
};

    useEffect(() => {
        fetchAgents();
        fetchDisbursements();
    }, []);

    return (
  <div className="Tab-Container">
     {showPopup && (          
        <form onSubmit={handleSubmit}>
        <div>
            <label htmlFor="agentid">Agent:</label>
            <select
            id="agentid"
            name="agentid"
            value={formData.agentid}
            onChange={handleInputChange}
            required
            >
            <option value="">Select Agent</option>
            {agents.map((agent) => (
                <option key={agent.AgentID} value={agent.AgentID}>
                {agent.AgentID}: {agent.FirstName} {agent.LastName}
                </option>
            ))}
            </select>
        </div>
        <div>
            <label htmlFor="description">Description:</label>
            <input
            type="text"
            id="description"
            name="description"
            value={formData.description}
            onChange={handleInputChange}
            required
            />
        </div>
        <div>
            <label htmlFor="fees">Fees:</label>
            <input
            type="number"
            id="fees"
            name="fees"
            value={formData.fees}
            onChange={handleInputChange}
            required
            />
        </div>
        <button type="submit">Add Disbursement</button>
        <button onClick={()=>{setShowPopup(false)}}>Cancel</button>
        {successMessage && <SuccessMessage message={successMessage} />}
    </form>
          
        )}
        
      <div>
      <h2 className="section-toggle">Disbursements:
            <button onClick={() => {setShowPopup(true)}} className="BT-button">
              {showDisbursmentsDropdown ? 'Hide Disbursment' : 'Create New'}
            </button> 
            </h2>
          <div className="Transactions">
              {successMessage && <SuccessMessage message={successMessage} />}
              <div className="table-container">
                  <table className="matter-table">
                      <thead>
                          <tr>
                              <th>Agent</th>
                              <th>Description</th>
                              <th>Fees</th>
                              <th>Actions</th>
                          </tr>
                      </thead>
                      <tbody>
                          {disbursements.map((disbursement) => (
                              editingDisbursementId === disbursement.disbursementid ? (
                                  <EditableRow
                                      key={disbursement.disbursementid}
                                      disbursement={disbursement}
                                      onSave={saveEdit}
                                      onCancel={() => setEditingDisbursementId(null)}
                                      agents={agents}
                                  />
                              ) : (
                                  <tr key={disbursement.disbursementid}>
                                      <td>{disbursement.firstname} {disbursement.lastname}</td>
                                      <td>{disbursement.description}</td>
                                      <td>{disbursement.fees}</td>
                                      <td>
                                          <button onClick={() => setEditingDisbursementId(disbursement.disbursementid)}><FontAwesomeIcon icon={faEdit} style={{ fontSize: '15px' }} /></button>
                                      </td>
                                  </tr>
                              )
                          ))}
                      </tbody>
                  </table>
              </div>
          </div>
      </div>
    </div>
  );
};

export default Disbursements;