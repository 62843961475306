import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import '../../styles/General.css';
import '../../styles/MatterDetails.css';
import '../../styles/FinancialsTabs.css';
import '../../styles/Calander.css';
import getAuthHeaders from '../../functions/getAuthHeaders';
import { useMsal } from '@azure/msal-react';

const BilliableCalendar = ({ matterId }) => {
    const { accounts } = useMsal();
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [events, setEvents] = useState([]);
    const [matterDetails, setMatterDetails] = useState(null);
    const [manualTime, setManualTime] = useState('00:00:00');
    const [date, setDate] = useState(new Date().toISOString().slice(0, 10));
    const [notes, setNotes] = useState('');
    const [loadingBilledTime, setLoadingBilledTime] = useState(false);
    const [billedTime, setBilledTime] = useState([]);
    const [usedBilledTime, setUsedBilledTime] = useState([]);
    const [userInputTime, setUserInputTime] = useState('');
    const [showPopup, setShowPopup] = useState(false);

    const calculateAmount = (billedRate, timeRecorded) => {
        billedRate = parseFloat(billedRate);
        if (isNaN(billedRate)) {
            console.error("Invalid billedRate:", billedRate);
            return "0.00";
        }

        if (!timeRecorded || typeof timeRecorded !== 'string' || !timeRecorded.includes(':')) {
            console.error("Invalid timeRecorded:", timeRecorded);
            return "0.00";
        }

        const [hours, minutes, seconds] = timeRecorded.split(':').map(Number);

        if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
            console.error("Invalid time components:", { hours, minutes, seconds });
            return "0.00";
        }

        const totalHours = hours + (minutes / 60) + (seconds / 3600);
        const amount = billedRate * totalHours;
        return amount.toFixed(2);
    };

        
    const handleInputChange = (e) => {
        const input = e.target.value;
        setUserInputTime(input); // Update user input directly
    
        // Convert input to HH:MM:SS format only if it's a valid number
        if (!isNaN(input) && input.trim() !== '') {
            const totalMinutes = parseFloat(input) * 60;
            const hours = Math.floor(totalMinutes / 60);
            const minutes = Math.floor(totalMinutes % 60);
            const formattedTime = `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:00`;
            setManualTime(formattedTime); // Update internal time representation
        } else {
            setManualTime('00:00:00'); // Reset or handle invalid input
        }
    };
    
    const fetchMatterDetails = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/matter_by_id/${matterId}`, {
                headers: await getAuthHeaders(),
            });
            if (response.ok) {
                const data = await response.json();
                setMatterDetails(data);
            } else {
                throw new Error('Failed to fetch matter details');
            }
        } catch (error) {
            console.log('Error fetching matter details: ' + error);
        }
    };

    const fetchBilledTimeData = async () => {
        setLoadingBilledTime(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/get_billed_time/${matterId}`, {
                headers: await getAuthHeaders(),
            });
            const data = await response.json();

            const updatedBilledTime = data.map((bt) => ({
                ...bt,
                amount: calculateAmount(bt.billedrate, bt.time_recorded),
            }));

            setBilledTime(updatedBilledTime.filter((bt) => !bt.invoiceid));
            setUsedBilledTime(updatedBilledTime.filter((bt) => bt.invoiceid));

            // Map billed time entries to calendar events
            setEvents(updatedBilledTime.map(bt => ({
                ...bt,
                subject: bt.notes || 'Billable Time Entry',
                start: bt.date ? new Date(bt.date) : null,
                billable: true
            })));
        } catch (error) {
            console.error('Error fetching billed time data:', error);
        } finally {
            setLoadingBilledTime(false);
        }
    };

    const renderTileContent = ({ date, view }) => {
        if (view === 'month') {
            const dayEvents = events.filter(event =>
                event.start && event.start.toDateString() === date.toDateString()
            );
    
            // Calculate total time for the day in seconds
            let totalSeconds = dayEvents.reduce((total, event) => {
                const [hours, minutes, seconds] = event.time_recorded.split(':').map(Number);
                return total + hours * 3600 + minutes * 60 + seconds;
            }, 0);
    
            // Convert total seconds to hours, minutes, and seconds
            const totalHours = Math.floor(totalSeconds / 3600);
            const totalMinutes = Math.floor((totalSeconds % 3600) / 60);
    
            // Format the time as "Xhrs Ymins" with the desired conditions
            let formattedTotalTime = '';
            if (totalHours > 0) {
                formattedTotalTime += `${totalHours}hr${totalHours > 1 ? 's' : ''}`;
            }
            if (totalMinutes > 0) {
                formattedTotalTime += `${formattedTotalTime ? ' ' : ''}${totalMinutes}m${totalMinutes > 1 ? 's' : ''}`;
            }
    
            const totalAmount = dayEvents.reduce((sum, event) => sum + parseFloat(event.amount || 0), 0);
    
            return (
                <div className="calendar-tile-content">
                    <div className="tile-info">
                        {formattedTotalTime && <p className="total-time">{formattedTotalTime}</p>}
                        {totalAmount > 0 && <p className="event-amount">${totalAmount.toFixed(2)}</p>}
                    </div>
                </div>
            );
        }
        return null;
    };
    
    
    

   
    const handleSubmitConfirmation = async () => {
        const { oid } = accounts[0].idTokenClaims;
        const timeToRecord = manualTime !== '00:00:00' ? manualTime : '00:00:00';

        const employee = billedTime.find(bt => bt.employeeid === oid);
        const billedRate = employee ? parseFloat(employee.billedrate) : NaN;

        const amount = calculateAmount(billedRate, timeToRecord);

        const submissionData = {
            matterId: matterDetails.matterid,
            timeRecorded: timeToRecord,
            date: date,
            notes: notes,
            employeeid: oid,
            amount: amount
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/billed_time`, {
                method: 'POST',
                headers: await getAuthHeaders(),
                body: JSON.stringify(submissionData),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            fetchBilledTimeData();
        } catch (error) {
            console.error('Error submitting time entry:', error);
        }
    };

    useEffect(() => {
        fetchMatterDetails();
        fetchBilledTimeData();
    }, []);

    const onDateChange = (date) => {
        setSelectedDate(date);
    };

    return (

        <div className="calendar-container">
            <div className="calendar-content">
                <Calendar
                    onChange={onDateChange}
                    value={selectedDate}
                    className="react-calendar"
                    tileContent={renderTileContent}
                />
            </div>
            <div className="calendar-events-list">
                <h2>Events on {selectedDate.toDateString()}</h2>
                {events.filter(event => event.start && event.start.toDateString() === selectedDate.toDateString()).length > 0 ? (
                    events.filter(event => event.start && event.start.toDateString() === selectedDate.toDateString()).map((event, index) => (
                        <div key={index} className={`calendar-event-item ${event.invoiceid ? 'has-invoice' : ''}`}>
                            <h3 className="event-title">{event.subject}</h3>
                            <p className="event-time">
                                {event.start ? event.start.toLocaleTimeString([], {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                }) : 'N/A'}
                            </p> 
                            <p className="event-amount">${event.amount}</p>
                        </div>
                    ))
                ) : (
                    <p className="no-events">No events for this day</p>
                )}
                {showPopup && (
    <div className="popup">
        <h3>Add Billable Time</h3>
        <input
            type="text"
            value={userInputTime}
            onChange={handleInputChange}
            placeholder="Enter time in hours"
        />
        <textarea
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            placeholder="Enter notes"
        ></textarea>
        <button onClick={handleSubmitConfirmation}>Submit</button>
        <button onClick={() => setShowPopup(false)}>Cancel</button>
    </div>
)}
            </div>
            
        </div>
    );
};

export default BilliableCalendar;






