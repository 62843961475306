import React, { useState, useEffect } from 'react';
import '../../styles/General.css';
import '../../styles/MatterDetails.css';
import '../../styles/FinancialsTabs.css';
import getAuthHeaders from '../../functions/getAuthHeaders';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons'; 

const FixedFees = ({matterId}) => {
    const [fixedFees, setFixedFees] = useState([]);
    const [assignedFees, setAssignedFees] = useState([]);
    const [showFixedFeesDropdown, setShowFixedFeesDropdown] = useState(false);
    const [newFee, setNewFee] = useState({ fee_code: '', narrative: '', fees: '' });
    const [editingFee, setEditingFee] = useState(null); // null when not editing
    const [showEditModal, setShowEditModal] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const toggleAddModal = () => setIsModalOpen(!isModalOpen);
    const toggleModal = () => setIsModalOpen(!isModalOpen);
    const toggleEditModal = (fee = null) => {
      if (fee) {
        setEditingFee(fee);
        setShowEditModal(true);
      } else {
        setEditingFee(null); 
        setShowEditModal(!showEditModal);
      }
    };

    const [visibleButtons, setVisibleButtons] = useState({});

    const toggleButtonVisibility = (index) => {
      setVisibleButtons((prev) => ({ ...prev, [index]: !prev[index] }));
    };

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setNewFee(prevState => ({
        ...prevState,
        [name]: value
      }));
    };
 
    const submitNewFixedFee = async (e) => {
      e.preventDefault(); // Prevents the default form submission behavior
      
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/add_fixed_fees`, {
          method: 'POST',
          headers: { ...await getAuthHeaders(), 'Content-Type': 'application/json' },
          body: JSON.stringify(newFee)
        });
    
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
    
        // Clear the form
        setNewFee({ fee_code: '', narrative: '', fees: '' });
    
        // Optionally, fetch the updated list of fixed fees
        fetchFixedFees();
    
        console.log('New fixed fee added successfully');
      } catch (error) {
        console.error('Error adding new fixed fee:', error);
      }
    };

    const addFixedFeeToLineItems = async (fee) => {
        const fixedFeeData = {
            fee_code: fee.fee_code,
            narrative: fee.narrative,
            fees: fee.fees,
            matterid: matterId 
        };
         try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/assigned_fixed_fees`, {
                method: 'POST',
                headers: await getAuthHeaders(),
                body: JSON.stringify(fixedFeeData),
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            fetchAssignedFees(); //fetch updated list
            console.log('Fixed fee added to database successfully');
        } catch (error) {
            console.error('Error adding fixed fee to database:', error);
        }
    };
    
    const fetchFixedFees = async () => {
        // Toggle visibility first
        setShowFixedFeesDropdown(prevState => !prevState);
      
        // If we are going to show the fees, fetch them
        if (!showFixedFeesDropdown) {
          try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/fixed_fees`, {
              headers: await getAuthHeaders()
            });
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();
            setFixedFees(data);
          } catch (error) {
            console.error('Error fetching fixed fees:', error);
          }
        }
    };
    const handleDeleteFixedFee = async (fee_code) => {
      if (window.confirm(`Are you sure you want to delete the fixed fee with code: ${fee_code}?`)) {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/delete_fixed_fees/${fee_code}`, {
            method: 'DELETE',
            headers: await getAuthHeaders(),
          });
    
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
    
          console.log('Fixed fee deleted successfully');
          fetchFixedFees(); // Refresh the list of fixed fees
        } catch (error) {
          console.error('Error deleting fixed fee:', error);
        }
      }
    };
    
    const handleEditFormSubmit = async (e) => {
      e.preventDefault();
      
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/edit_fixed_fees/${editingFee.fee_code}`, {
          method: 'PUT',
          headers: {
            ...await getAuthHeaders(),
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(editingFee),
        });
    
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
    
        console.log('Fixed fee updated successfully');
        setEditingFee(null); // Reset the editing fee
        setShowEditModal(false); // Close the modal
        fetchFixedFees(); // Refresh the list of fees
      } catch (error) {
        console.error('Error updating fixed fee:', error);
      }
    };

    
    const fetchAssignedFees = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/assigned_fixed_fees/${matterId}`, {
                headers: await getAuthHeaders()
            });
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();
            setAssignedFees(data);
        } catch (error) {
            console.error('Error fetching fixed fees load:', error);
        }
        
    };

    useEffect(() => {
        fetchAssignedFees();
    }, []);
    
      
    return (
      <div className="Tab-Container">
      <div className="Transactions">  
{isModalOpen || showEditModal ? (
  <div className="modal-overlay" onClick={isModalOpen ? toggleAddModal : () => setShowEditModal(false)}>
  <div className="modal-content" onClick={(e) => e.stopPropagation()}>
    <button className="modal-close-button" onClick={isModalOpen ? toggleAddModal : () => setShowEditModal(false)}>&times;</button>
    <div className="add-fixed-fee-form">
      <form onSubmit={editingFee ? handleEditFormSubmit : submitNewFixedFee}>
          <label>
            Fee Code:
            <input
  type="text"
  value={editingFee ? editingFee.fee_code : newFee.fee_code}
  onChange={handleInputChange}
  placeholder="Fee Code"
  readOnly={false} // Allows editing of the fee code
  name="fee_code"
  required
/>

          </label>
          <label>
            Narrative:
            <input
              type="text"
              value={editingFee ? editingFee.narrative : newFee.narrative}
              onChange={editingFee ? e => setEditingFee({ ...editingFee, narrative: e.target.value }) : handleInputChange}
              placeholder="Narrative"
              name="narrative"
              required
            />
          </label>
          <label>
            Fees:
            <input
              type="number"
              value={editingFee ? editingFee.fees : newFee.fees}
              onChange={editingFee ? e => setEditingFee({ ...editingFee, fees: e.target.value }) : handleInputChange}
              placeholder="Fees"
              name="fees"
              required
            />
          </label>
          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  </div>
) : null}


    
            {showFixedFeesDropdown && (
              <div className="FF-BT-Dropdown">
                <div class="side-banner-container">
                    <h2>Fixed Fees</h2>
                    <button onClick={toggleAddModal} className="add-fixed-fee-button">Add Fixed Fee</button>
                </div> 
              <ul>
              {fixedFees.map((fee, index) => (
                <li key={index}>
                  <strong>{fee.fee_code} - {fee.narrative} - ${fee.fees}</strong>
                  <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', gap:'9px' }}>
                  <button onClick={() => addFixedFeeToLineItems(fee)}><h5>Add</h5></button>
                  <button onClick={() => toggleButtonVisibility(index)}>
                      <h5>
                          <FontAwesomeIcon icon={faEdit} style={{ fontSize: '15px' }} />
                      </h5>
                  </button>
                  </div>
                {visibleButtons[index] && (
                  <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                    <button onClick={() => toggleEditModal(fee)} style={{ marginRight: '10px' }}>
                      <h5 style={{ fontSize: '10px' }}>Edit</h5>
                    </button>
                    <button onClick={() => handleDeleteFixedFee(fee.fee_code)}>
                      <h5 style={{ fontSize: '10px' }}>Delete</h5>
                    </button>
                  </div>
                )}
                </li>
              ))}
              </ul>
              </div>
            )}
            
            <div className="table-container">
          <h2 className="section-toggle">Fixed Fees:
            <button onClick={fetchFixedFees} className="BT-button">
              {showFixedFeesDropdown ? 'Hide Fixed Fees' : 'Add Fixed Fees'}
            </button> 
            </h2>
            <table className="matter-table">
              <thead>
                <tr>
                  <th>Fee Code</th>
                  <th>Narrative</th>
                  <th>Amount</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {assignedFees.map((feesList, index) => (
                  <tr key={index}>
                    <td>{feesList.fee_code}</td>
                    <td>{feesList.narrative}</td>
                    <td>{feesList.fees}</td>
                    <td>{feesList.date_added}</td>
                    <td>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        </div>
      );      
};

export default FixedFees;