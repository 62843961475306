import React, { useState, useEffect } from 'react';
import '../../styles/General.css';
import '../../styles/MatterDetails.css';
import '../../styles/FinancialsTabs.css';
import getAuthHeaders from '../../functions/getAuthHeaders';
import generateInvoicePDF from '../../functions/generateInvoicePDF';

const Billing = ({matterId}) => {
    const [matterDetails, setMatterDetails] = useState([]);
    const [client, setClient] = useState([]);
    const [invoiceClient, setInvoiceClient] = useState('');
    const [clientEmail, setClientEmail] = useState('');
    const [clientPhone, setClientPhone] = useState('');
    const [clientAddress, setClientAddress] = useState('');
    const [clientCompany, setClientCompany] = useState('');
    const [invoiceDate, setInvoiceDate] = useState('');
    const [showFixedFeesDropdown, setShowFixedFeesDropdown] = useState(false);
    const [showBillableTimesDropdown, setShowBillableTimesDropdown] = useState(false);
    const [showDisbursementsDropdown, setShowDisbursementsDropdown] = useState(false);
    const [billableTimes, setBillableTimes] = useState([]);
    const [fixedFees, setFixedFees] = useState([]);
    const [lineItems, setLineItems] = useState([]);
    const [taxRate, setTaxRate] = useState(0); // Initializes tax rate
    const [discount, setDiscount] = useState(0); // Initializes discount
    const [disbursements, setDisbursements] = useState([]);

    function calculateTotalAmount(lineItems, discountRate, taxRate){
      let totalAmount = 0;
      lineItems.forEach((item) => {
          const amount = parseFloat(item.amount) || 0;
          const discount = parseFloat(item.discount) || 0;
          const quantity = parseInt(item.qty) || 1;
          const discountAmount = amount * discount / 100;
          const finalAmount = amount - discountAmount;
          const totalItemAmount = finalAmount * quantity;
          totalAmount += totalItemAmount;
      });
      const discountAmount = totalAmount * (discountRate / 100); 
      const subtotalAfterDiscount = totalAmount - discountAmount; 
      const taxAmount = subtotalAfterDiscount * (taxRate / 100);
      const finalTotal = subtotalAfterDiscount + taxAmount;
      return finalTotal;
  }
  
    async function handleGenerateInvoice(e) {
        e.preventDefault();
  
        try {
            const data = {
                matterid: matterId,
                clientid: matterId.split('-')[0],
                due_date: invoiceDate,
                discount: discount,
                tax_rate: taxRate,
                total_amount: calculateTotalAmount(lineItems, discount, taxRate),
                line_items: lineItems 
            };
    
            // Make the POST request to create a new invoice
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/invoice`, {
                method: 'POST',
                headers: await getAuthHeaders(),
                body: JSON.stringify(data)
            });
    
            if (!response.ok) {
                throw new Error('Failed to create invoice');
            }
    
            const responseData = await response.json();
            const invoiceId = responseData.invoiceId;
  
            generateInvoicePDF(matterId, {
                "name": invoiceClient,
                "email": clientEmail,
                "phone": clientPhone
            }, invoiceDate, matterDetails.mattername, lineItems, discount, taxRate, invoiceId);

        } catch (error) {
            console.log('Error creating invoice:', error.message);
        }
    }      

    const fetchFixedFees = async () => {
        // Toggle visibility first
        setShowFixedFeesDropdown(prevState => !prevState);
      
        // If we are going to show the fees, fetch them
        if (!showFixedFeesDropdown) {
          try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/fixed_fees`, {
              headers: await getAuthHeaders()
            });
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();
            setFixedFees(data);
          } catch (error) {
            console.error('Error fetching fixed fees:', error);
          }
        }
    };

    const fetchBillableTimes = async () => {
      try {
          const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/get_billed_time/${matterId}`, {
              headers: await getAuthHeaders()
          });
          const data = await response.json();
    
          // Filter out billable times that have an invoice ID
          const filteredBillableTimes = data.filter(time => !time.invoiceid);
    
          console.log("Filtered Billable Times:", filteredBillableTimes); // Debug: Check the filtered data
          setBillableTimes(filteredBillableTimes); // Store only the billable times without an invoice ID
      } catch (error) {
          console.error('Error fetching billable times for matterId:', matterId, error);
      }
    };
    
  
  

    const handleToggleDisbursements = () => {
        setShowDisbursementsDropdown(!showDisbursementsDropdown);
        if (!showDisbursementsDropdown && disbursements.length === 0) {
            fetchDisbursements();
        }
    };
    const addDisbursementToLineItems = (disbursement) => {
      const newLineItem = {
          description: disbursement.description,
          qty: 1, // Assuming quantity is 1 for disbursements
          amount: disbursement.fees,
          discount: 0 // Assuming no discount for disbursements
      };
  
      setLineItems([...lineItems, newLineItem]);
  };
  
    
    const fetchDisbursements = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/agent_disbursements/${matterId}`, {
          headers: await getAuthHeaders()
        });
        const data = await response.json();
        setDisbursements(data);
      } catch (error) {
        console.log('Error fetching disbursements:', error);
      }
    };

    const generatePreBill = async () => {
      try {
          // Fetch all data concurrently
          const [assignedFeesResponse, disbursementsResponse, billableTimesResponse] = await Promise.all([
              fetch(`${process.env.REACT_APP_API_BASE_URL}/assigned_fixed_fees/${matterId}`, { headers: await getAuthHeaders() }),
              fetch(`${process.env.REACT_APP_API_BASE_URL}/agent_disbursements/${matterId}`, { headers: await getAuthHeaders() }),
              fetch(`${process.env.REACT_APP_API_BASE_URL}/get_billed_time/${matterId}`, { headers: await getAuthHeaders() })
          ]);
  
          // Await and process all responses
          const [assignedFees, disbursements, billableTimes] = await Promise.all([
              assignedFeesResponse.json(),
              disbursementsResponse.json(),
              billableTimesResponse.json()
          ]);
  
          // Filter billable times to include only those without an invoice ID
          const filteredBillableTimes = billableTimes.filter(time => !time.invoiceid);
  
          // Map each data set to the line item format
          const newLineItems = [
              ...assignedFees.map(fee => ({
                  type: 'fixed fee',
                  id: fee.assignedid,
                  description: `${fee.fee_code} - ${fee.narrative}`,
                  qty: 1, // Assuming quantity is 1 for fees
                  amount: fee.fees,
                  discount: 0 // Assuming no discount for fees
              })),
              ...disbursements.map(disbursement => ({
                  type: 'disbursement',
                  id: disbursement.disbursementid,
                  description: disbursement.description,
                  qty: 1, // Assuming quantity is 1 for disbursements
                  amount: disbursement.fees,
                  discount: 0 // Assuming no discount for disbursements
              })),
              ...filteredBillableTimes.map(time => ({
                  type: 'billed time',
                  id: time.bt_id,
                  description: `Billable Time - ${time.employee_name} - ${time.date} - ${time.time_recorded}`,
                  qty: 1, // Assuming quantity is 1 for billable times
                  amount: time.amount, // Assuming 'amount' is a property of billable times
                  discount: 0 // Assuming no discount for billable times
              }))
          ];
  
          // Update line items state
          setLineItems(currentItems => [...currentItems, ...newLineItems]);
      } catch (error) {
          console.error('Error generating pre bill:', error);
      }
  };
  
    const fetchMatterDetails = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/matter_by_id/${matterId}`, {
            headers: await getAuthHeaders()
          });
          if (response.ok) {
            const data = await response.json();
            setMatterDetails(data);
          } else {
            throw new Error('Failed to fetch matter details');
          }
        } catch (error) {
          console.log('Error fetching matter details: ' + error.message);
        }
    };

    const fetchClientDetails = async () => {
        try {
          const clientId = matterId.split('-')[0];
          const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/getclient/${clientId}`, {
            headers: await getAuthHeaders()
          });
          const clientData = await response.json();
          setClient(clientData);
          setInvoiceClient(`${clientData.firstname} ${clientData.lastname}`);
          setClientEmail(clientData.email);
          setClientPhone(clientData.phone);
          setClientAddress(clientData.address);
          setClientCompany(clientData.company);
          // Handle other fields like company, address etc., if needed
        } catch (error) {
          console.error('Error fetching client details:', error);
        }
    };

    const handleToggleBillableTimes = () => {
        setShowBillableTimesDropdown(!showBillableTimesDropdown);
        if (!showBillableTimesDropdown && billableTimes.length === 0) {
          fetchBillableTimes();
        }
    };

    const addAllBillableTimes = () => {
      billableTimes.forEach(BT => {
          const newLineItem = {
              description: `Billable Time - ID: ${BT.bt_id} - Time Spent: ${BT.time_recorded}`,
              qty: 1,
              amount: BT.amount // Use the correct amount for each billable time
          };
          setLineItems(currentItems => [...currentItems, newLineItem]);
      });
  };
  

    // Update a line item
    const updateLineItem = (index, field, value) => {
        const newLineItems = lineItems.map((item, idx) => {
        if (idx === index) {
            return { ...item, [field]: value };
        }
        return item;
        });
        setLineItems(newLineItems);
    };

    const addLineItem = () => {
        setLineItems([...lineItems, { description: '', qty: '', amount: 0 }]);
    };
      
    const removeLineItem = (index) => {
        const newLineItems = lineItems.filter((_, i) => i !== index);
        setLineItems(newLineItems);
    };

    const addFixedFeeToLineItems = (fee) => {
        const newLineItem = {
        description: `${fee.fee_code} - ${fee.narrative}`,
        amount: fee.fees
        };
    
        setLineItems([...lineItems, newLineItem]);
    };

    const addBillableTimeToLineItems = (BT) => {
      const newLineItem = {
          description: `Billable Time - ID: ${BT.bt_id} - Time Spent: ${BT.time_recorded}`,
          qty: 1,
          amount: BT.amount // Use the amount directly from the billable time entry
      };
      setLineItems([...lineItems, newLineItem]);
  };
  
  

    useEffect(() => {
        fetchMatterDetails();
        fetchClientDetails(); 
    }, []);

    return (
      <div className="Tab-Container">
        <div className="billing">
          <h2 className="section-toggle">Create Invoice
          <button onClick={generatePreBill} className="BT-button" >Generate Pre Bill</button>
 </h2>
          <form onSubmit={handleGenerateInvoice}>
            <div className="form-group">
              <label htmlFor="matterDetails">Matter</label>
              <input
                id="matterDetails"
                type="matterDetails"
                value={` ${matterDetails.matterid} ${matterDetails.mattername}`}
                onChange={(e) => setClientEmail(e.target.value)}
                placeholder="Matter Name"
              />
            </div>
            <div className="APP-COUNTRYform-group APP-COUNTRY-row">
                    <div className="APP-COUNTRYform-group-half">
                        <label htmlFor="country">Country</label>
                        <input
                            id="country"
                            type="text"
                            value={matterDetails.country || ''}
                            readOnly
                        />
                    </div>
                    <div className="APP-COUNTRYform-group-half">
                        <label htmlFor="applicationNo">Application No</label>
                        <input
                            id="applicationNo"
                            type="text"
                            value={matterDetails.application_no || ''}
                            readOnly
                        />
                    </div>
                </div>
            <div className="form-group">
              <label htmlFor="clientName">Client Name</label>
              <input
                id="clientName"
                type="string"
                value={invoiceClient} 
                onChange={(e) => setInvoiceClient(e.target.value)}
                placeholder="Client Name"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="clientEmail">Client Email</label>
              <input
                id="clientEmail"
                type="email"
                value={clientEmail}
                onChange={(e) => setClientEmail(e.target.value)}
                placeholder="Client Email"
              />
            </div>
            <div className="form-group">
              <label htmlFor="clientPhone">Client Phone</label>
              <input
                id="clientPhone"
                type="tel"
                value={clientPhone}
                onChange={(e) => setClientPhone(e.target.value)}
                placeholder="Client Phone"
              />
            </div>
            <div className="form-group">
              <label htmlFor="clientaddress">Client Address</label>
              <input
                id="clientaddress"
                type="address"
                value={clientAddress}
                onChange={(e) => setClientAddress(e.target.value)}
                placeholder="Client Address"
              />
            </div>
            <div className="form-group">
              <label htmlFor="clientcompany">Client Company</label>
              <input
                id="clientcompany"
                type="company"
                value={clientCompany}
                onChange={(e) => setClientCompany(e.target.value)}
                placeholder="Client Company"
              />
            </div>
            <div className="form-group">
              <label htmlFor="invoiceDate">Invoice Due Date</label>
              <input
                id="invoiceDate"
                type="date"
                value={invoiceDate}
                onChange={(e) => setInvoiceDate(e.target.value)}
                required
              />
            </div>
            <div className="billing-item">
              <h3>Billing Items:</h3>
              <div className="FF-BT-Container">
                <button type="button" onClick={fetchFixedFees} className="FF-Button">
                  {showFixedFeesDropdown ? 'Hide Fixed Fees' : 'Show Fixed Fees'}
                </button>
                <button type="button" onClick={handleToggleBillableTimes} className="BT-Button">
                  {showBillableTimesDropdown ? 'Hide Billable Times' : 'Show Billable Times'}
                </button>
                <button type="button" onClick={handleToggleDisbursements} className="BT-Button">
                    {showDisbursementsDropdown ? 'Hide Disbursements' : 'Show Disbursements'}
                </button>
                {showFixedFeesDropdown && (
                  <div className="FF-BT-Dropdown">
                    <h2 className="side-banner-header">Fixed Fees</h2>
                    <ul>
                      {fixedFees.map((fee, index) => (
                        <li key={index}>
                          <strong>{fee.fee_code} - {fee.narrative} - ${fee.fees}</strong>
                          <button onClick={() => addFixedFeeToLineItems(fee)}><h5>Add</h5></button>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                {showBillableTimesDropdown && (
                  <div className="FF-BT-Dropdown">
                    <ul>
                      <h2 className="side-banner-header">Billiable Times  <button type="button" onClick={addAllBillableTimes} className="BT-Button"> Add All Billable Times </button> </h2>
                      {billableTimes.map((BT, index) => (
                        <li key={index}>
                          <strong>{BT.bt_id} - {BT.time_recorded} {BT.date} </strong>
                          <p>{BT.notes}</p>
                          <button onClick={() => addBillableTimeToLineItems(BT)}><h5>Add</h5></button>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                  {showDisbursementsDropdown && (
                      <div className="FF-BT-Dropdown">
                          <h2 className="side-banner-header">Disbursements</h2>
                          <ul>
                              {disbursements.map((disbursement, index) => (
                                  <li key={index}>
                                      <strong>{disbursement.description} - ${disbursement.fees}</strong>
                                      <button onClick={() => addDisbursementToLineItems(disbursement)}>Add</button>
                                  </li>
                              ))}
                          </ul>
                      </div>
                  )}

              </div>
              <table className="line-item-table">
              <thead>
                <tr>
                  <th scope="col">Service Description</th>
                  <th scope="col">QTY</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Discount (%)</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
    {lineItems.map((item, index) => (
        <tr key={index} className="line-item">
            <td>
                <input
                    type="text-SDFinancial"
                    value={item.description}
                    onChange={(e) => updateLineItem(index, 'description', e.target.value)}
                    placeholder="Service Description"
                    required
                />
            </td>
            <td>
                <input
                    type="text-SDFinancial"
                    value={item.qty}
                    onChange={(e) => updateLineItem(index, 'qty', e.target.value)}
                    placeholder="QTY"
                    required
                />
            </td>
            <td>
                <input
                    type="number-SDFinancial"
                    value={item.amount} // Ensure this reflects the correct amount
                    onChange={(e) => updateLineItem(index, 'amount', parseFloat(e.target.value))}
                    placeholder="Amount"
                    required
                />
            </td>
            <td>
                <input
                    type="number-SDFinancial"
                    min="0"
                    max="100"
                    value={item.discount}
                    onChange={(e) => updateLineItem(index, 'discount', parseFloat(e.target.value))}
                    placeholder="Discount"
                />
            </td>
            <td>
                <button type="button" onClick={() => removeLineItem(index)}>Remove</button>
            </td>
        </tr>
    ))}
</tbody>
            </table>
              <button className="" type="button" onClick={addLineItem}><h5>+ Add Row</h5></button>
            </div>
            <div className="form-group">
              <label htmlFor="discount">Discount (%)</label>
              <input
                id="discount"
                type="number"
                value={discount}
                onChange={(e) => setDiscount(parseFloat(e.target.value))}
                placeholder="Discount"
              />
            </div>
            <div className="form-group">
              <label htmlFor="taxRate">Tax Rate (%)</label>
              <input
                id="taxRate"
                type="number"
                value={taxRate} // Add state variable for tax rate
                onChange={(e) => setTaxRate(parseFloat(e.target.value))}
                placeholder="Tax Rate"
              />
            </div>
            <button type="submit" className="pdf-button"><strong>Generate Invoice</strong></button>
          </form>
        </div>
        </div>
    );      
};

export default Billing;