import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPlus, faDownload, faTrashAlt, faArrowLeft, faFolderOpen, faFile, faEdit } from '@fortawesome/free-solid-svg-icons';
import '../styles/General.css';
import '../styles/MatterDetails.css';
import '../styles/File.css';

// import { Storage } from "@aws-amplify/storage";
import FileUpload from './FileUpload.js';
// import config from '../aws-exports.js'
import WebViewer from '@pdftron/webviewer';
import getAuthHeaders from '../functions/getAuthHeaders.js';
import { saveAs } from 'file-saver';
import FileList from '../file-list.js';


const Files = ({ matterId, }) => {
  const viewer = useRef(null);
  const [webViewerInstance, setWebViewerInstance] = useState(null);
  const [currentDirectory, setCurrentDirectory] = useState(matterId + '/');
  const [isLoading, setIsLoading] = useState(true);
  const [isRoot, setIsRoot] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filesInSubdirectory, setFilesInSubdirectory] = useState([]);
  const [selectedFileKey, setSelectedFileKey] = useState(null);
  const [sortOrder, setSortOrder] = useState('newest') // valid orders: 'newest', 'oldest', 'alphabetical', 'reverse-alphabetical, 'largest', 'smallest'
  // const [matterDetails, setMatterDetails] = useState(null);

  const fetchMatterDetails = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/matter_data/${matterId}`, {
        headers: await getAuthHeaders()
      });
      if (response.ok) {
        const data = await response.json();
        return data
        // setMatterDetails(data);
      } else {
        return null
      }
    } catch (error) {
      console.log('Error fetching matter details: ' + error.message);
      return null
    }
  };

  const loadWebViewerDocument = async (documentURL, extension) => {
    const { documentViewer, annotationManager } = webViewerInstance.Core;
    // webViewerInstance.UI.setFitMode(webViewerInstance.UI.FitMode.FitWidth);
    webViewerInstance.UI.loadDocument(documentURL, {
      extension: extension,
      // enableOfficeEditing: true
    });
    documentViewer.addEventListener('documentLoaded', async () => {
      const doc = documentViewer.getDocument();
      const keys = await doc.getTemplateKeys();
      if(keys){
        const matterDetails = await fetchMatterDetails()
        // console.log("KEYS: ", keys);
        if(matterDetails){
          await doc.applyTemplateValues(matterDetails);
          const data = await doc.getFileData({
            downloadType: 'templateFilledOffice',
            officeOptions: {
              doTemplatePrep: true
            }
          });
          const arr = new Uint8Array(data);
          const blob = new Blob([arr], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
          saveAs(blob, 'template-filled.docx');
        }
      }
    });
  };

  useEffect(() => {
    // const initializeWebViewer = async () => {
    //   const instance = await WebViewer({
    //     path: '../lib',
    //     licenseKey: 'demo:1709879001877:7f2184640300000000567a2901e2a43ee3f7c5441b9cdb91949d35b6fa',
    //   }, viewer.current);

    //   // const { documentViewer } = instance.Core;
    //   // you can now call WebViewer APIs here...
    //   setWebViewerInstance(instance);
    // };

    // initializeWebViewer();
  }, []);

  useEffect(() => {
    // listFilesInSubdirectory();
    // if (currentDirectory === matterId + '/' ) {
    //   setIsRoot(true);
    // } else {
    //   setIsRoot(false);
    // }
  }, [currentDirectory]);

  useEffect(() => {
    // console.log(sortOrder)
    // const files = sortByParameter(filesInSubdirectory, sortOrder);
    // console.log(files);
    // setFilesInSubdirectory(files);
    // console.log(filesInSubdirectory);
  }, [sortOrder]);
  
  const handleFileClick = async (fileKey) => {
    // if (fileKey.endsWith('/')) {
    //   setIsLoading(true);
    //   setCurrentDirectory(fileKey);
    // } else {
    //   // setSelectedFileKey((prevKey) => (prevKey === fileKey ? null : fileKey));
    //   setSelectedFileKey(fileKey)
    //   try {
    //     const url = await Storage.get(fileKey);
    //     const extension = fileKey.split('.').pop() 
    //     // webViewerInstance.UI.loadDocument(url, {
    //     //   extension: extension,
    //     //   // enableOfficeEditing: true
    //     // });
    //     loadWebViewerDocument(url, extension)
    //   } catch (error) {
    //     console.error('Error loading document:', error);
    //   }
    // }
  };

  const listFilesInSubdirectory = async () => {
    // try {
    //   let files = (await Storage.list(`${currentDirectory}`)).results;
    //   files = files.filter(file => file.key !== currentDirectory);
    //   const sortedFiles = sortByParameter(files, sortOrder);
    //   setFilesInSubdirectory(sortedFiles);
    //   setIsLoading(false);
    // } catch (error) {
    //   console.error('Error listing files:', error);
    // }
  };

  function sortByParameter(list, parameter) {
    switch(parameter) {
        case 'newest':
            return list.sort((a, b) => new Date(b.lastModified) - new Date(a.lastModified));
        case 'oldest':
            return list.sort((a, b) => new Date(a.lastModified) - new Date(b.lastModified));
        case 'alphabetical':
            return list.sort((a, b) => a.key.localeCompare(b.key));
        case 'reverse-alphabetical':
            return list.sort((a, b) => b.key.localeCompare(a.key));
        case 'largest':
            return list.sort((a, b) => b.size - a.size);
        case 'smallest':
            return list.sort((a, b) => a.size - b.size);
        default:
            console.error('Invalid parameter for sorting.');
            return list;
    }
  }

  const numForwardSlashes = (inputString) => {
    // console.log(inputString)
    const slashes = inputString.match(/\//g);
    return slashes ? slashes.length : 0;
  };

  const formatDateString = (dateString) => {
    const originalDate = new Date(dateString);

    // Function to pad single digits with leading zeros
    const padZero = (num) => (num < 10 ? "0" : "") + num;

    // Format the date into the desired format
    const formattedDateString =
    padZero(originalDate.getDate()) +
    "-" +
    originalDate.toLocaleString("default", { month: "short" }) +
    "-" +
    originalDate.getFullYear() +
    " " +
    (originalDate.getHours() % 12 !== 0 ? originalDate.getHours() % 12 : 12) +
    ":" +
    padZero(originalDate.getMinutes()) +
    " " +
    (originalDate.getHours() < 12 ? "AM" : "PM");

    return formattedDateString;
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const backFolder = () => {
      // const trimmedDirectory = currentDirectory.replace(/\/+$/, '');
      // const folders = trimmedDirectory.split('/');
      // folders.pop();
      // const newDirectory = folders.join('/');
      // setIsLoading(true);
      // setCurrentDirectory(newDirectory + '/');
  };
  
  const deleteFile = async (fileKey) => {
    // try {
    //   await Storage.remove(fileKey);
    //   console.log("File deleted successfully");
    //   listFilesInSubdirectory();
    // } catch (error) {
    //   console.error("Error deleting file:", error);
    // }
  };

  const handleEditFileName = async (fileKey) => {
    // const newName = prompt("Rename File: ");
    // if (newName) {
    //   try {
    //     // Extracting information from the fileKey
    //     const parts = fileKey.split('/');
    //     const oldFileName = parts.pop();
    //     const fileExtension = oldFileName.split('.').pop();
    //     const directory = parts.join('/') + '/';
  
    //     // Generating the new file key
    //     const newFileKey = directory + newName + '.' + fileExtension;
  
    //     // Moving/rename the file in S3
    //     await renameFileInS3(fileKey, newFileKey);
  
    //     console.log("File name changed successfully");
  
    //     // Update the UI by listing files in the subdirectory again
    //     listFilesInSubdirectory();
    //   } catch (error) {
    //     console.error("Error renaming file:", error);
    //   }
    // }
  };
  
  const renameFileInS3 = async (oldKey, newKey) => {
    // try {
    //   const response = await Storage.copy({ bucket: config.aws_user_files_s3_bucket, key: oldKey }, { bucket: config.aws_user_files_s3_bucket, key: newKey });
    //   await Storage.remove(oldKey);
    //   return response;
    // } catch (error) {
    //   throw error;
    // }
  };

  const createNewFolder = async () => {
    // const folderName = prompt("Enter folder name:");
    // if (folderName) {
    //   await Storage.put(currentDirectory+`${folderName}/`, '');
    //   listFilesInSubdirectory();
    //   console.log('Folder created');
    // }
  };

  const downloadFile = async (key) => {
    // try {
    //   const url = await Storage.get(key);
    //   window.open(url, '_blank');
    // } catch (error) {
    //   console.error('Error downloading file:', error);
    // }
  };

  const handleSortChange = (event) => {
    setSortOrder(event.target.value);
    console.log(event.target.value)
  };

  // return (
  //   <div>
  //     <div className="Tab-Container" style={{ display: 'flex' }}>
  //       <div className="files-sidebar">
  //         <div className="file-nav-buttons">
  //           <button onClick={backFolder} className="create-button" disabled={isRoot}>
  //             <FontAwesomeIcon icon={faArrowLeft} className="icon-circle" />
  //           </button>
  //           <button onClick={createNewFolder} className="create-button">
  //             <FontAwesomeIcon icon={faFolderOpen} className="icon-circle" />
  //           </button>
  //           <button onClick={toggleModal} className="create-button">
  //             <FontAwesomeIcon icon={faPlus} className="icon-circle" />
  //           </button>
  //           <div className='create-button'>
  //             <select defaultValue="newest" onChange={handleSortChange}>
  //               <option value="newest">Newest</option>
  //               <option value="oldest">Oldest</option>
  //               <option value="alphabetical">Alphabetical</option>
  //               <option value="reverse-alphabetical">Reverse Alphabetical</option>
  //               <option value="largest">Largest</option>
  //               <option value="smallest">Smallest</option>
  //             </select>
  //           </div>
  //         </div>
    
  //         <FileUpload isOpen={isModalOpen}
  //                     onRequestClose={toggleModal}
  //                     currentDirectory={currentDirectory}
  //                     listFilesInSubdirectory={listFilesInSubdirectory}>
  //         </FileUpload>
    
  //         <div className="files-Subdirectory-section">
  //           {isLoading ? (
  //             <p>Loading...</p>
  //           ) : (
  //             filesInSubdirectory.length > 0 ? (
  //               <ul className="files-list">
  //                 {filesInSubdirectory.map((file) => (
  //                   (numForwardSlashes(file.key.slice(0, -1)) > numForwardSlashes(currentDirectory)) ? (
  //                     null
  //                   ) : (
  //                     file.key.endsWith('/') ? (
  //                       <li key={file.key} className="folder-item">
  //                         <span onClick={() => handleFileClick(file.key)}>
  //                           {file.key.slice(currentDirectory.length)}
  //                         </span>
  //                       </li>
  //                     ) : (
  //                       <div key={file.key} className="file-item-container">
  //                         <li className="file-item">
  //                           <span onClick={() => handleFileClick(file.key)}>
  //                             <FontAwesomeIcon icon={faFile} /> {file.key.slice(currentDirectory.length)}
  //                           </span>
  //                           <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '10px' }}>
  //                             <button onClick={() => handleEditFileName(file.key)} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
  //                               <FontAwesomeIcon icon={faEdit} />
  //                             </button>
  //                             <button onClick={() => downloadFile(file.key)} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
  //                               <FontAwesomeIcon icon={faDownload} />
  //                             </button>
  //                             <button onClick={() => deleteFile(file.key)} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
  //                               <FontAwesomeIcon icon={faTrashAlt} />
  //                             </button>
  //                           </div>
  //                           <div className="date-size-popup">
  //                             <p className="popup-date">{'Last Modified: ' + formatDateString(file.lastModified)}</p>
  //                             <p className="popup-size">{'Size: ' + file.size}</p>
  //                           </div>
  //                         </li>
  //                       </div>
  //                     )
  //                   )
  //                 ))}
  //               </ul>
  //             ) : (
  //               <p>No files found.</p>
  //             )
  //           )}
  //         </div>
  //       </div>
    
  //       {/* <div className="file-preview-area">
  //         <div className="doc-bar">
  //           <button onClick={() => selectedFileKey && downloadFile(selectedFileKey)}>
  //             <FontAwesomeIcon icon={faDownload} />
  //           </button>
  //         </div>
  //         <div className="file-preview-container" style={{ flex: 1, padding: '20px' }}>
  //           {selectedFileKey && <FilePreview fileKey={selectedFileKey} />}
  //         </div>
  //       </div> */}
  //     </div>
  //     <div className="MyComponent">
  //       <div className="webviewer" ref={viewer} style={{height: "100vh"}}></div>
  //     </div>
  //   </div>
  // );

  return (
    <div>
      {/* Files: {matterId} */}
      <FileList matterId={matterId}/>
    </div>
  );
  
};

export default Files;
