import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import '../styles/Home.css';
import '../styles/General.css';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import getAuthHeaders from '../functions/getAuthHeaders';

function TasksWidget() {

  const chartRef = useRef(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const headers = await getAuthHeaders(); // Get authentication headers
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/task_status_distribution`, { headers });
        const data = await response.json();

        let root = am5.Root.new(chartRef.current);
        root.setThemes([am5themes_Animated.new(root)]);

        let chart = root.container.children.push(am5percent.PieChart.new(root, {}));
        let series = chart.series.push(am5percent.PieSeries.new(root, {
          valueField: "count",
          categoryField: "status",
          // Adjust the size of the pie chart here
          radius: am5.percent(100), // Making the pie chart smaller by adjusting the radius
          innerRadius: am5.percent(0) // Optionally, adjust for a donut appearance
        }));
   

        series.data.setAll(data);

        chart.children.push(am5.Legend.new(root, {
          centerY: am5.percent(50),
          y: am5.percent(50)
        }));
        
      } catch (error) {
        console.error('Error fetching matter status distribution:', error);
      }
    };

    fetchData();
  }, []);


  return (
    <div className="box open-matters-widget">
      <h2>My Tasks Status</h2>
        <div ref={chartRef} style={{ width: "500px", height: "200px" }}></div>
    </div>
  );
};

export default TasksWidget;