import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import getAuthHeaders from '../functions/getAuthHeaders';
import '../styles/Checklist.css';
import { useMsal } from '@azure/msal-react';

const EditableText = ({ type = "text", fieldName, value, onSave }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleSave = () => {
    onSave(fieldName, inputValue);
    setIsEditing(false);
  };

  return isEditing ? (
    <div>
      <input
        type={type}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onBlur={handleSave}
        autoFocus
      />
      <button onClick={handleSave}>Save</button>
    </div>
  ) : (
    <div onClick={() => setIsEditing(true)}>{value || `Click to edit ${fieldName}`}</div>
  );
};

const Checklist = () => {
  const { matterId } = useParams();
  const { accounts } = useMsal();
  const [checklistItems, setChecklistItems] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    fetchChecklistItems();
  }, [matterId]);

  const fetchChecklistItems = async () => {
    try {
      const headers = await getAuthHeaders();
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/checklistitems/${matterId}`, {
        method: 'GET',
        headers,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setChecklistItems(data);
    } catch (error) {
      console.error('Error fetching checklist items:', error);
    }
  };

  const toggleStatus = async (itemId, currentStatus) => {
    if (!itemId) {
      console.error('Item ID is undefined when trying to toggle status');
      return;
    }
  
    const newStatus = !currentStatus;
    try {
      const headers = await getAuthHeaders();
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/statuschecklistitems/${itemId}`, {
        method: 'PATCH',
        headers: {
          ...headers,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ status: newStatus }),
      });
  
      if (response.ok) {
        // Update the state to reflect the new status
        setChecklistItems((prevItems) =>
          prevItems.map((item) =>
            item.checklistid === itemId ? { ...item, status: newStatus } : item
          )
        );
      } else {
        console.error('Failed to update status:', response.statusText);
      }
    } catch (error) {
      console.error('Error updating checklist item status:', error);
    }
  };
    
  
  const addChecklistItem = async (description) => {
    try {
      const { oid } = accounts[0].idTokenClaims; // Assuming you're using MSAL for authentication
      const headers = await getAuthHeaders();
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/addchecklistitems`, {
        method: 'POST',
        headers: {
          ...headers,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ description, matterid: matterId, employeeid: oid }),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const newItem = await response.json();
  
      // Ensure the new item has a valid checklistid
      if (!newItem.checklistid) {
        console.error('New checklist item is missing an ID');
        return;
      }
  
      // Update the state to include the new item immediately
      setChecklistItems((prevItems) => [
        ...prevItems,
        { ...newItem, description, status: false }, // Add the new item with status as false by default
      ]);
  
      // Optionally refetch if you encounter issues with state updates
      // await fetchChecklistItems();
    } catch (error) {
      console.error('Error adding checklist item:', error);
    }
  };
    
  

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const description = e.target.elements.description.value.trim();
    if (description) {
      addChecklistItem(description);
      e.target.elements.description.value = ''; // Reset input after submission
      setShowForm(false); // Close modal after submission
    }
  };

  const handleFieldSave = async (itemId, fieldName, newValue) => {
    try {
      const updatedData = { [fieldName]: newValue };
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/edit_checklistitem/${itemId}`, {
        method: 'PUT',
        headers: {
          ...await getAuthHeaders(),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedData),
      });

      if (response.ok) {
        setChecklistItems((prev) =>
          prev.map((item) =>
            item.checklistid === itemId
              ? { ...item, [fieldName]: newValue }
              : item
          )
        );
        alert('Update successful');
      } else {
        alert('Failed to update');
      }
    } catch (error) {
      console.error('Error updating checklist item:', error);
      alert('Error updating: ' + error.message);
    }
  };

  const handleDelete = async (itemId) => {
    try {
      const headers = await getAuthHeaders();
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/delete_checklistitem/${itemId}`, {
        method: 'DELETE',
        headers,
      });

      if (response.ok) {
        setChecklistItems((prev) => prev.filter((item) => item.checklistid !== itemId));
        alert('Delete successful');
      } else {
        alert('Failed to delete');
      }
    } catch (error) {
      console.error('Error deleting checklist item:', error);
      alert('Error deleting: ' + error.message);
    }
  };

  const renderModal = () => (
    <div className="checklist-modal" style={{ display: showForm ? 'block' : 'none' }}>
      <div className="checklist-modal-content">
        <span className="checklist-close" onClick={() => setShowForm(false)}>&times;</span>
        <form onSubmit={handleFormSubmit}>
          <input type="text" name="description" placeholder="Add new checklist item" required />
          <button type="submit">Add Item</button>
        </form>
      </div>
    </div>
  );

  const renderTable = (items, isInModal) => (
    <table className={isInModal ? 'checklist-table modal-checklist-table' : 'checklist-table'}>
      <thead>
        <tr>
          <th>Status</th>
          <th>         
            Description
          </th>
          <th>  {!isInModal && (
                <button onClick={(e) => { e.stopPropagation(); setShowForm(!showForm); }} className="checklistAdd-button">Add</button>
              )}</th>

        </tr>
      </thead>
      <tbody>
        {items.map(item => (
          <tr key={item.checklistid} onClick={() => setIsExpanded(true)}>
            <td>
              <input
                type="checkbox"
                checked={item.status}
                onChange={(e) => {
                  e.stopPropagation(); // Prevent triggering the row click event
                  toggleStatus(item.checklistid, item.status);
                }}
              />
            </td>
            <td>
              {isEditMode ? (
                <EditableText
                  fieldName="description"
                  value={item.description || ''}
                  onSave={(field, value) => handleFieldSave(item.checklistid, field, value)}
                />
              ) : (
                item.description
              )}
            </td>
         
              <td>
                <button onClick={(e) => { e.stopPropagation(); handleDelete(item.checklistid); }}>Delete</button>
              </td>
      
          </tr>
        ))}
      </tbody>
    </table>
  );

  return (
    <div>
      {renderModal()}
      {isExpanded ? (
        <div className="checklist-expanded-modal">
          <div className="checklist-expanded-modal-content">
            <span className="checklist-close" onClick={() => setIsExpanded(false)}>&times;</span>
            <button onClick={() => setIsEditMode(!isEditMode)} className="edit-button">
              {isEditMode ? 'Cancel' : 'Edit'}
            </button>
            {renderTable(checklistItems, true)}
          </div>
        </div>
      ) : (
        renderTable(checklistItems.slice(0, 3), false)
      )}
    </div>
  );
};

export default Checklist;
