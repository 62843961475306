import React, { useRef, useEffect } from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

function TasksStatus() {
  const chartRef = useRef(null);

  useEffect(() => {
    const root = am5.Root.new(chartRef.current);
    root.setThemes([am5themes_Animated.new(root)]);

    const chart = root.container.children.push(am5xy.XYChart.new(root, {
      layout: root.verticalLayout,
      panX: true,
      panY: true,
      wheelX: "panX",
      wheelY: "zoomX",
      pinchZoomX: true
    }));

    const cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
      behavior: "zoomX"
    }));

    cursor.lineY.set("visible", false);

    const xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
      categoryField: "month",
      renderer: am5xy.AxisRendererX.new(root, {}),
      tooltip: am5.Tooltip.new(root, {})
    }));

    xAxis.data.setAll([
      { month: "January" },
      { month: "February" },
      { month: "March" },
      { month: "April" },
      { month: "May" },
      { month: "June" },
      { month: "July" },
      { month: "August" },
      { month: "September" },
      { month: "October" },
      { month: "November" },
      { month: "December" }
    ]);

    const yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      renderer: am5xy.AxisRendererY.new(root, {})
    }));

    const series = chart.series.push(am5xy.ColumnSeries.new(root, {
      name: "Money Outflow",
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: "amount",
      categoryXField: "month",
      tooltip: am5.Tooltip.new(root, {
        labelText: "{categoryX}: {valueY}"
      })
    }));

    // Hardcoded demo data
    const data = [
      { month: "January", amount: 1200 },
      { month: "February", amount: 1100 },
      { month: "March", amount: 900 },
      { month: "April", amount: 1400 },
      { month: "May", amount: 1300 },
      { month: "June", amount: 1600 },
      { month: "July", amount: 1500 },
      { month: "August", amount: 1700 },
      { month: "September", amount: 1800 },
      { month: "October", amount: 1900 },
      { month: "November", amount: 2000 },
      { month: "December", amount: 2100 }
    ];

    series.data.setAll(data);

    chart.children.push(am5.Legend.new(root, {
      centerX: am5.percent(50),
      x: am5.percent(50)
    }));

    return () => {
      root.dispose();
    };
  }, []);

  return (
    <div className="box open-matters-widget">
      <h2>Money Outflow</h2>
      <div ref={chartRef} style={{ width: "100%", height: "500px" }}></div>
    </div>
  );
}

export default TasksStatus;


