import React, { useState } from 'react';
import '../styles/General.css';
import '../styles/MatterDetails.css';
import '../styles/FinancialsTabs.css'; // Make sure to update this CSS file for sidebar styling
import { faFileInvoiceDollar, faDollarSign, faChartLine, faClock, faTag, faHandHoldingUsd } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FinancialTransactions from './FinancialSections/FinancialTransactions';
import BillableTime from './FinancialSections/BillableTime';
import FixedFees from './FinancialSections/FixedFees';
import Disbursements from './FinancialSections/Disbursements';
import Billing from './FinancialSections/Billing';

const Financials = ({ matterId }) => {
  const [activeSection, setActiveSection] = useState('transactions');
  const toggleSection = (section) => {
    setActiveSection(section);
  };

  return (
    <div className="Financials-Container">
      <div className="Financials-Sidebar">
      <button 
  onClick={() => toggleSection('transactions')} 
  className={`Financials-Sidebar-Button ${activeSection === 'transactions' ? 'active' : ''}`} 
>
  <FontAwesomeIcon className="TB-icon-color" icon={faChartLine} style={{ marginRight: '3px' }} /> Dashboard
</button>
<button 
  onClick={() => toggleSection('billing')} 
  className={`Financials-Sidebar-Button ${activeSection === 'billing' ? 'active' : ''}`} 
>
  <FontAwesomeIcon className="TB-icon-color" icon={faFileInvoiceDollar} style={{ marginRight: '3px' }} /> Invoicing
</button>
<button 
  onClick={() => toggleSection('BilliableTime')} 
  className={`Financials-Sidebar-Button ${activeSection === 'BilliableTime' ? 'active' : ''}`} 
>
  <FontAwesomeIcon className="TB-icon-color" icon={faClock} style={{ marginRight: '3px' }} /> Billable Time
</button>
<button 
  onClick={() => toggleSection('FixedFees')} 
  className={`Financials-Sidebar-Button ${activeSection === 'FixedFees' ? 'active' : ''}`} 
>
  <FontAwesomeIcon className="TB-icon-color" icon={faTag} style={{ marginRight: '3px' }} /> Fixed Fees
</button>
<button 
  onClick={() => toggleSection('Disbursements')} 
  className={`Financials-Sidebar-Button ${activeSection === 'Disbursements' ? 'active' : ''}`} 
>
  <FontAwesomeIcon className="TB-icon-color" icon={faHandHoldingUsd} style={{ marginRight: '3px' }} /> Disbursements
</button>

      </div>

      <div className="Financials-Content">
        {activeSection === 'transactions' && (<FinancialTransactions matterId={matterId}/>)}
        {activeSection === 'BilliableTime' && (<BillableTime matterId={matterId}/>)}
        {activeSection === 'FixedFees' && (<FixedFees matterId={matterId}/>)}
        {activeSection === 'Disbursements' && (<Disbursements matterId={matterId}/>)}
        {activeSection === 'billing' && (<Billing matterId={matterId}/>)}
      </div>
    </div>
  );
};

export default Financials;
