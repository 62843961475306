import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../styles/General.css';
import '../styles/MatterDetails.css';
import { Auth } from 'aws-amplify';
import getAuthHeaders from '../functions/getAuthHeaders';
import Reporting_MatterTasks from '../Reporting/Reporting_MatterTasks.js';
import { useMsal } from '@azure/msal-react';

const TasksTab = ({ matterId }) => {
  const [tasks, setTasks] = useState([]);
  const { instance, accounts } = useMsal();
  const [selectedTask, setSelectedTask] = useState(null);
  const [newTask, setNewTask] = useState({
    matterid: matterId,
    task_title: '',
    description: '',
    duedate: '',
    assignedto: '',
    status: 'Pending',
    createdby: '',
    task_type: '', 
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [tasksPerPage, setTasksPerPage] = useState(6);  // Change this value as per your preference
  const indexOfLastTask = currentPage * tasksPerPage;
  const indexOfFirstTask = indexOfLastTask - tasksPerPage;
  const currentTasks = tasks.slice(indexOfFirstTask, indexOfLastTask);
  const pageNumbers = [];
for (let i = 1; i <= Math.ceil(tasks.length / tasksPerPage); i++) {
  pageNumbers.push(i);
}
const renderPageNumbers = pageNumbers.map(number => {
  return (
    <li
      key={number}
      onClick={() => setCurrentPage(number)}
      style={{ cursor: 'pointer', padding: '5px' }}>
      {number}
    </li>
  );
});
const handleNext = () => {
  setCurrentPage(prevPage => (prevPage < pageNumbers.length ? prevPage + 1 : prevPage));
};

const handlePrevious = () => {
  setCurrentPage(prevPage => (prevPage > 1 ? prevPage - 1 : prevPage));
};


  const [employees, setEmployees] = useState([]);
  const [showAddTaskForm, setShowAddTaskForm] = useState(false);
  const handleAddTaskFormToggle = () => {
    setShowAddTaskForm(!showAddTaskForm); // Toggle the state to show/hide the form
    setNewTask({
      matterid: matterId,
        task_title: '',
        description: '',
        duedate: '',
        assignedto: '',
        status: 'Pending',
        createdby: '',
        task_type: '', 
    });
  };
  const getProgressBarColor = () => {
    if(selectedTask){
      if (selectedTask.status === 'Pending') {
        return 'red';
      } else if (selectedTask.status === 'In Progress') {
        return 'yellow';
      } else if (selectedTask.status === 'Completed') {
        return 'green';
      }
      return 'gray';
    }
  };

  const getProgressBarPartition = () => {
    if(selectedTask){
      if (selectedTask.status === 'Pending') {
        return '33.33%';
      } else if (selectedTask.status === 'In Progress') {
        return '66.66%';
      } else if (selectedTask.status === 'Completed') {
        return '100%';
      }
      return '0%';
    }
  };

  const handleAddTaskInputChange = async (e) => {
    const { name, value } = e.target;
    const { oid } = accounts[0].idTokenClaims;
    setNewTask((prevState) => ({
      ...prevState,
      [name]: value,
      createdby: oid,
    }));
  };  
 
  
  const progressBarContainerStyle = {
    width: '50%',
    maxWidth: '400px',
    height: '20px',
    backgroundColor: 'lightgray',
    borderRadius: '4px',
    overflow: 'hidden',
    position: 'relative',
  };

  const progressBarPartitionStyle = {
    height: '100%',
    width: getProgressBarPartition(),
    backgroundColor: getProgressBarColor(),
    transition: 'width 0.3s ease',
    position: 'relative',
  };

  const statusTextStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: 'black',
    fontWeight: 'bold',
  };

  const fetchEmployees = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/employees`, {
        headers: await getAuthHeaders()
      });
      if (response) {
        const data = await response.json();
        // console.log(data)
        setEmployees(data); 
      }
    } catch (error) {
      console.log('Error fetching employees:', error);
    }
  };

  useEffect(() => {
    fetchEmployees();
    fetchTasks();
  }, []);

  const handleAddTaskSubmit = async (e) => {
    e.preventDefault();
    try {
      // Make a POST request to add the new task
      await fetch(`${process.env.REACT_APP_API_BASE_URL}/addtask`, {
        method: 'POST',
        headers: await getAuthHeaders(),
        body: JSON.stringify(newTask),
      });
  
      fetchTasks(); // Fetch the updated tasks list
      setShowAddTaskForm(false); // Hide the form after submission
    } catch (error) {
      console.log('Error adding task:', error);
    }
  };

   const fetchTasks = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/tasks_by_matter/${matterId}`, {
        headers: await getAuthHeaders()
      });
      
      if (response.ok) {
        const data = await response.json();
        setTasks(data);
      } else {
        throw new Error('Network response was not ok.');
      }
    } catch (error) {
      console.log('Error fetching tasks:', error);
    }
  };
  
  const handleMarkPending = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/task-pending/${selectedTask.taskid}`, {
        method: 'PUT',
        headers: await getAuthHeaders(),
        body: JSON.stringify({ status: 'Pending' }),
      });

      if (response.ok) {
        // Update the task status locally after successful update
        setSelectedTask({ ...selectedTask, status: 'Pending' });
      }
    } catch (error) {
      console.log('Error marking task as Pending:', error);
    }
  };

  const handleMarkInProgress = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/task-inprogress/${selectedTask.taskid}`, {
        method: 'PUT',
        headers: await getAuthHeaders(),
        body: JSON.stringify({ status: 'In Progress' }),
      });

      if (response.ok) {
        // Update the task status locally after successful update
        setSelectedTask({ ...selectedTask, status: 'In Progress' });
      }
    } catch (error) {
      console.log('Error marking task as in progress:', error);
    }
  };

  const handleMarkCompleted = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/task-complete/${selectedTask.taskid}`, {
        method: 'PUT',
        headers: await getAuthHeaders(),
        body: JSON.stringify({ status: 'Completed' }),
      });

      if (response.ok) {
        // Update the task status locally after successful update
        setSelectedTask({ ...selectedTask, status: 'Completed' });
      }
    } catch (error) {
      console.log('Error marking task as completed:', error);
    }
  };



  return (
    <div className="Tab-Container">
    <div className="table-container">
    <div > 
      <div>
      <button onClick={handleAddTaskFormToggle} className="MD-edit-edit-matter-button" style={{ width:'70px' , marginbottom: '-1000px', marginLeft: '1570px'}} >Create Task</button>

      <div>
      <div style={{marginBottom: '-15px', marginTop: '-39px', marginLeft: '1490px'}}><Reporting_MatterTasks matterId={matterId} /></div>
      {showAddTaskForm && (
        <form onSubmit={handleAddTaskSubmit}>
          <h4>Add New Task</h4>
          <label>
            Task Title:
            <input
              type="text"
              name="task_title"
              value={newTask.task_title}
              onChange={handleAddTaskInputChange}
              required
            />
          </label>
          <label>
            Description:
            <input
              type="text"
              name="description"
              value={newTask.description}
              onChange={handleAddTaskInputChange}
              required
            />
          </label>
          <label>
            Due Date:
            <input
              type="date"
              name="duedate"
              value={newTask.duedate}
              onChange={handleAddTaskInputChange}
              required
            />
          </label>
          <label>
            Assigned To:
            <select
              name="assignedto"
              value={newTask.assignedto}
              onChange={handleAddTaskInputChange}
              required>
              <option value="" disabled>
                Select Employee
              </option>
              {employees.map((employee) => (
                <option key={employee.EmployeeID} value={employee.EmployeeID}>
                  {`${employee.FirstName} ${employee.LastName}`}
                </option>
              ))}
            </select>
          </label>
          <label for="task_type">Task Type:
          <select name="task_type" id="task_type" value={newTask.task_type} onChange={handleAddTaskInputChange} required>
              <option value="" disabled>Select Task Type</option>
              <option value="Internal">Internal</option>
              <option value="External">External</option>
            </select>
</label>

          <br />
          <button type="submit">
            <strong>Create New Task</strong>
          </button>
        </form>
      )}
       </div>
       <table className="matter-table">
  <thead>
    <tr>
      {/* <th>Task ID</th> */}
      <th>Title</th>
      <th>Description</th>
      <th>Assigned To</th>
      <th>Status</th>
      {/* <th>Created By</th> */}
      <th>Due Date</th>
    </tr>
  </thead>
  <tbody>
  {currentTasks.map((task, index) => (
    <tr key={index}>
      {/* <td><button onClick={() => setSelectedTask(task)}>{task.taskid}</button></td> */}
      <td> <button onClick={() => setSelectedTask(task)}>{task.task_title}</button></td>
      <td>{task.description.split(" ").slice(0, 4).join(" ") + (task.description.split(" ").length > 4 ? "..." : "")}</td>
      <td>{task.assignedto}</td>
      <td>{task.status}</td>
      {/* <td>{task.createdby}</td> */}
      <td>{task.duedate}</td>
      {/* <td><Link to={`/matters/${task.matterid}`}>{task.mattername}</Link></td> */}
    </tr>
  ))}
</tbody>
<div className="pagination">
  <button onClick={handlePrevious} disabled={currentPage === 1}>
    Previous
  </button>
  <ul className="pagination-numbers">
    {renderPageNumbers}
  </ul>
  <button onClick={handleNext} disabled={currentPage === pageNumbers.length}>
    Next
  </button>
</div>


</table>

      </div>
    </div>
    <div className={`side-banner ${selectedTask ? 'visible' : ''}`}>
    <div className="side-banner-header">
        <h3>Task Details</h3>
        <button className="side-banner-button" onClick={() => setSelectedTask(null)}>Close</button>
    </div>

    {selectedTask && (
        <div className='task-details-container'>
            <div className="task-title-container">
                <p className="task-title">{selectedTask.task_title}</p>
                <div className="task-metadata-subheading">
                    <span><strong>ID:</strong> {selectedTask.taskid}</span>
                    <span><strong>Created By:</strong> {selectedTask.createdby}</span>
                </div>
            </div>

            <div className="status-container">
                <div className="Task-progress-bar-container" style={progressBarContainerStyle}>
                    <div className="Task-progress-bar-partition" style={progressBarPartitionStyle}></div>
                    <span className="status-text">{selectedTask.status}</span>
                </div>

                <div className="Task-milestone-buttons-container">
                    {selectedTask.status === 'Pending' && (
                        <button className="task-milestone-buttons" onClick={handleMarkInProgress}>Mark In Progress</button>
                    )}
                    {selectedTask.status === 'In Progress' && (
                        <>
                            <button className="task-milestone-buttons" onClick={handleMarkPending}>Mark Pending</button>
                            <button className="task-milestone-buttons" onClick={handleMarkCompleted}>Mark Completed</button>
                        </>
                    )}
                    {selectedTask.status === 'Completed' && (
                        <button className="task-milestone-buttons" onClick={handleMarkInProgress}>Mark In Progress</button>
                    )}
                </div>
            </div>

            <div className="task-info-container">
                <p><strong>Description:</strong> {selectedTask.description}</p>
                <p><strong>Due Date:</strong> {selectedTask.duedate}</p>
                <p><strong>Assigned To:</strong> {selectedTask.assignedto}</p>
                <p><strong>Status:</strong> {selectedTask.status}</p>
                <p><strong>Notes:</strong> {selectedTask.notes}</p>
            </div>
        </div>
    )}
    </div>
  </div>
  </div>
  );
};

export default TasksTab;