import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './styles/General.css';
import './styles/TasksDetails.css';
import getAuthHeaders from './functions/getAuthHeaders';

const TaskDetails = () => {
  const { taskId } = useParams();
  const [task, setTask] = useState(null);

  useEffect(() => {
    fetchTaskDetails();
  }, []);

  const fetchTaskDetails = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/task_by_id/${taskId}`, {
        headers: await getAuthHeaders()
      });
      const data = await response.json();
      setTask(data);
      console.log(data);
    } catch (error) {
      console.log('Error fetching task details:', error);
    }
  };

  const getProgressBarColor = () => {
    if (task.status === 'Pending') {
      return 'red';
    } else if (task.status === 'In Progress') {
      return 'yellow';
    } else if (task.status === 'Completed') {
      return 'green';
    }
    return 'gray';
  };

  const getProgressBarPartition = () => {
    if (task.status === 'Pending') {
      return '33.33%';
    } else if (task.status === 'In Progress') {
      return '66.66%';
    } else if (task.status === 'Completed') {
      return '100%';
    }
    return '0%';
  };

  const handleMarkPending = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/task-pending/${taskId}`, {
        method: 'PUT',
        headers: await getAuthHeaders(),
        body: JSON.stringify({ status: 'Pending' }),
      });

      if (response.ok) {
        // Update the task status locally after successful update
        setTask({ ...task, status: 'Pending' });
      }
    } catch (error) {
      console.log('Error marking task as Pending:', error);
    }
  };

  const handleMarkInProgress = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/task-inprogress/${taskId}`, {
        method: 'PUT',
        headers: await getAuthHeaders(),
        body: JSON.stringify({ status: 'In Progress' }),
      });

      if (response.ok) {
        // Update the task status locally after successful update
        setTask({ ...task, status: 'In Progress' });
      }
    } catch (error) {
      console.log('Error marking task as in progress:', error);
    }
  };

  const handleMarkCompleted = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/task-complete/${taskId}`, {
        method: 'PUT',
        headers: await getAuthHeaders(),
        body: JSON.stringify({ status: 'Completed' }),
      });

      if (response.ok) {
        // Update the task status locally after successful update
        setTask({ ...task, status: 'Completed' });
      }
    } catch (error) {
      console.log('Error marking task as completed:', error);
    }
  };

  if (!task) {
    return <div>Loading...</div>;
  }

  const progressBarContainerStyle = {
    width: '50%',
    maxWidth: '400px',
    height: '20px',
    backgroundColor: 'lightgray',
    borderRadius: '4px',
    overflow: 'hidden',
    position: 'relative',
  };

  const progressBarPartitionStyle = {
    height: '100%',
    width: getProgressBarPartition(),
    backgroundColor: getProgressBarColor(),
    transition: 'width 0.3s ease',
    position: 'relative',
  };

  const statusTextStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: 'black',
    fontWeight: 'bold',
  };

  return (
    <div className='task-details-container'>
      <h2>Task Details</h2>
      <div style={progressBarContainerStyle}>
        <div style={progressBarPartitionStyle}>
          <span style={statusTextStyle}>{task.status}</span>
        </div>
      </div>
      {task.status === 'Pending' && (
        <button onClick={handleMarkInProgress}>Mark In Progress</button>
      )}
      {task.status === 'In Progress' && (
        <button onClick={handleMarkPending}>Mark Pending</button>
      )}
      {task.status === 'In Progress' && (
        <button onClick={handleMarkCompleted}>Mark Completed</button>
      )}
      {task.status === 'Completed' && (
        <button onClick={handleMarkInProgress}>Mark In Progress</button>
      )}
      <p>Task ID: {task.taskid}</p>
      <p>Assigned To: {task.assignedto}</p>
      <p>Description: {task.description}</p>
      <p>Status: {task.status}</p>
      <p>Created By: {task.createdby}</p>
      <p>Due Date: {task.duedate}</p>
      <p>Notes: {task.notes}</p>
    </div>
  );
};

export default TaskDetails;
