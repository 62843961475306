import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { FaArrowLeft, FaHome, FaTasks, FaFileAlt, FaMoneyBillWave, FaStickyNote } from 'react-icons/fa';
import './styles/General.css';
import './styles/MatterDetails.css';
import 'font-awesome/css/font-awesome.min.css';
import './styles/Matter.css';
import '@react-pdf-viewer/core/lib/styles/index.css';
import GeneralTab from './MatterDetails/GeneralTab';
import Tasks from './MatterDetails/Tasks';
import Files from './MatterDetails/Files';
import Financials from './MatterDetails/Financials';
import AdditionalNotes from './MatterDetails/AdditionalNotes';
import getAuthHeaders from './functions/getAuthHeaders';
import LoadingSpinner from './components/LoadingSpinner.js';

const MatterDetails = () => {
  const { matterId } = useParams();
  const [activeTab, setActiveTab] = useState('General');
  const [matterDetails, setMatterDetails] = useState(null);
  const [error, setError] = useState(null);

  const fetchMatterDetails = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/matter_by_id/${matterId}`, {
        headers: await getAuthHeaders()
      });
      if (response.ok) {
        const data = await response.json();
        setMatterDetails(data);
      } else {
        throw new Error('Failed to fetch matter details');
      }
    } catch (error) {
      setError('Error fetching matter details: ' + error.message);
    }
  };

  useEffect(() => {
    fetchMatterDetails();
  }, [matterId]);

  const truncateMatterName = (name) => {
    const words = name.split(' ');
    if (words.length > 3) {
      return words.slice(0, 3).join(' ') + '...';
    }
    return name;
  };

  if (!matterDetails) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="matterDetails-backdrop">
      <div className="top-bar-container">
        <Link to="/matters" className="matterDetails-back-button">
          <FaArrowLeft />   
          <span className='MatterID-MatterName-title'> 
            {matterId} - {truncateMatterName(matterDetails.mattername)}
          </span>
        </Link>

        <div className="matterDetails-top-bar">
          <button onClick={() => setActiveTab('General')} className={`top-bar-btn ${activeTab === 'General' ? 'active' : ''}`}>
            <FaHome className="TB-icon-color" /> General
          </button>
          <button onClick={() => setActiveTab('Tasks')} className={`top-bar-btn ${activeTab === 'Tasks' ? 'active' : ''}`}>
            <FaTasks className="TB-icon-color" /> Tasks
          </button>
          <button onClick={() => setActiveTab('Files')} className={`top-bar-btn ${activeTab === 'Files' ? 'active' : ''}`}>
            <FaFileAlt className="TB-icon-color" /> Files
          </button>
          <button onClick={() => setActiveTab('Financial')} className={`top-bar-btn ${activeTab === 'Financial' ? 'active' : ''}`}>
            <FaMoneyBillWave className="TB-icon-color" /> Financial
          </button>
          <button onClick={() => setActiveTab('Additional Notes')} className={`top-bar-btn ${activeTab === 'Additional Notes' ? 'active' : ''}`}>
            <FaStickyNote className="TB-icon-color" /> Additional Notes
          </button>
        </div>
      </div>

      <div className="matterDetails-content">
        {activeTab === 'General' && <GeneralTab matterId={matterId} />}
        {activeTab === 'Tasks' && <Tasks matterId={matterId} />}
        {activeTab === 'Files' && <Files matterId={matterId} />}
        {activeTab === 'Financial' && <Financials matterId={matterId} />}
        {activeTab === 'Additional Notes' && <AdditionalNotes matterId={matterId} />}
      </div>
    </div>
  );
};

export default MatterDetails;
















































// import React, { useState, useEffect, useRef } from 'react';
// import { useParams, Link } from 'react-router-dom';
// import { FaArrowLeft, FaBars, FaHome, FaTasks, FaFileAlt, FaMoneyBillWave, FaStickyNote } from 'react-icons/fa';
// import './styles/General.css';
// import './styles/MatterDetails.css';
// import 'font-awesome/css/font-awesome.min.css';
// import './styles/Matter.css';
// import '@react-pdf-viewer/core/lib/styles/index.css';
// import GeneralTab from './MatterDetails/GeneralTab';
// import Tasks from './MatterDetails/Tasks';
// import Files from './MatterDetails/Files';
// import Financials from './MatterDetails/Financials';
// import AdditionalNotes from './MatterDetails/AdditionalNotes';
// // import Reporting from './MatterDetails/Reporting';
// // import VendorsStakeholders from './MatterDetails/Vendors&Stakeholders';

// const MatterDetails = () => {
//   const { matterId } = useParams();
//   const [activeTab, setActiveTab] = useState('General');
//   const [isSidebarOpen, setIsSidebarOpen] = useState(false); // State to control sidebar visibility
//   const sidebarRef = useRef(); // Create a ref for the sidebar

//   // Function to close the sidebar if the click is outside
//   const handleClickOutside = (event) => {
//     if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
//       setIsSidebarOpen(false);
//     }
//   };

//   useEffect(() => {
//     // Add when mounted
//     document.addEventListener("mousedown", handleClickOutside);
//     // Return function to be called when unmounted
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, []); // Empty array ensures that effect is only run on mount and unmount

//   return (
//     <div className="matterDetails-backdrop">
//       <button className="matterDetails-sidebar-toggle" onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
//         <FaBars />
//       </button>
//       {isSidebarOpen && (
//         <div className="matterDetails-sidebar-container" ref={sidebarRef}>
//           <Link to="/matters" className="matterDetails-back-button">
//             <FaArrowLeft />
//           </Link>
//           <div className="matterDetails-sidebar">
//   <button onClick={() => setActiveTab('General')} className={`sidebar-btn ${activeTab === 'General' ? 'active' : ''}`}>
//     <FaHome /> General
//   </button>
//   <button onClick={() => setActiveTab('Tasks')} className={`sidebar-btn ${activeTab === 'Tasks' ? 'active' : ''}`}>
//     <FaTasks /> Tasks
//   </button>
//   <button onClick={() => setActiveTab('Files')} className={`sidebar-btn ${activeTab === 'Files' ? 'active' : ''}`}>
//     <FaFileAlt /> Files
//   </button>
//   <button onClick={() => setActiveTab('Financial')} className={`sidebar-btn ${activeTab === 'Financial' ? 'active' : ''}`}>
//     <FaMoneyBillWave /> Financial
//   </button>
//   <button onClick={() => setActiveTab('Additional Notes')} className={`sidebar-btn ${activeTab === 'Additional Notes' ? 'active' : ''}`}>
//     <FaStickyNote /> Additional Notes
//   </button>
// </div>

//         </div>
//       )}
//       <div className="matterDetails-content">
//         {activeTab === 'General' && <GeneralTab matterId={matterId} />}
//         {activeTab === 'Tasks' && <Tasks matterId={matterId} />}
//         {activeTab === 'Files' && <Files matterId={matterId} />}
//         {activeTab === 'Financial' && <Financials matterId={matterId} />}
//         {activeTab === 'Additional Notes' && <AdditionalNotes matterId={matterId} />}
//       </div>
//     </div>
//   );
// };

// export default MatterDetails;


