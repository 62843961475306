import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import './styles/General.css';
import './styles/MyTasks.css';
import './styles/tableStyle.css';
import './styles/Matter.css'; 
import getAuthHeaders from './functions/getAuthHeaders';
import SuccessMessage from './components/SuccessMessage';
import ReportingTasks from './Reporting/ReportingTasks.js';
import Reporting_MyTasks from './Reporting/Reporting_MyTasks.js';
import { useMsal } from '@azure/msal-react';

const MyTasks = () => {
  const { instance, accounts } = useMsal();
  const [tasks, setTasks] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [myTasks, setMyTasks] = useState([]);
  const [searchBy, setSearchBy] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [activeTab, setActiveTab] = useState('myTasks');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [matters, setMatters] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [filteredTasksList, setFilteredTasksList] = useState([]);
const [filteredMyTasksList, setFilteredMyTasksList] = useState([]);
const [filterQuery, setFilterQuery] = useState('');

  const handleAddTaskFormToggle = () => {
    setIsModalOpen(!isModalOpen);
  };
  const [selectedTask, setSelectedTask] = useState(null);
  const [newTask, setNewTask] = useState({
    matterid: '',
    task_title: '',
    description: '',
    duedate: '',
    assignedto: '',
    status: 'Pending',
    createdby: '',
    task_type: '',  // Add this line
  });
  
  useEffect(() => {
    if(activeTab === 'myTasks'){
      fetchMyTasks();
    } else if (activeTab === 'allTasks'){
      fetchTasks();
    } else if (activeTab === 'CreateTasks'){
      fetchEmployees();
      fetchMatters();
      setSuccessMessage('');
    }
  }, [activeTab]);

  const fetchMyTasks = async () => {
    try {
      // const user = await Auth.currentAuthenticatedUser();
      // const cognitoSub = user.attributes.sub;
      const { oid } = accounts[0].idTokenClaims;
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/mytasks/${oid}`, {
        headers: await getAuthHeaders()
      });
      const data = await response.json();
      const myTasksData = Array.isArray(data) ? data : [];
      setMyTasks(myTasksData);
    } catch (error) {
      console.log('Error fetching my tasks:', error);
    }
  };
  const fetchTasks = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/alltasks`, {
        headers: await getAuthHeaders()
      });
      const data = await response.json();
      const tasksData = Array.isArray(data) ? data : [];
      setTasks(tasksData);
    } catch (error) {
      console.log('Error fetching tasks:', error);
    }
  };


  const [currentPage, setCurrentPage] = useState(1);
  const [tasksPerPage, setTasksPerPage] = useState(6);

  const indexOfLastmyTask = currentPage * tasksPerPage;
  const indexOfFirstmyTask = indexOfLastmyTask - tasksPerPage;
  const currentMyTasks = myTasks.slice(indexOfFirstmyTask, indexOfLastmyTask);

const indexOfLastTask = currentPage * tasksPerPage;
const indexOfFirstTask = indexOfLastTask - tasksPerPage;
const currentTasks = tasks.slice(indexOfFirstTask, indexOfLastTask);

const pageNumbers = useMemo(() => {
  const pages = [];
  for (let i = 1; i <= Math.ceil(tasks.length / tasksPerPage); i++) {
      pages.push(i);
  }
  return pages;
}, [tasks.length, tasksPerPage]);

const handlemyNext = () => {
  setCurrentPage(prevPage => (prevPage < pageNumbers.length ? prevPage + 1 : prevPage));
};

const handlemyPrevious = () => {
  setCurrentPage(prevPage => (prevPage > 1 ? prevPage - 1 : prevPage));
};

const handleNext = () => {
  setCurrentPage(prevPage => (prevPage < pageNumbers.length ? prevPage + 1 : prevPage));
};

const handlePrevious = () => {
  setCurrentPage(prevPage => (prevPage > 1 ? prevPage - 1 : prevPage));
};


const renderPageNumbers = pageNumbers.map(number => {
  return (
    <li
      key={number}
      onClick={() => setCurrentPage(number)}
      style={{ cursor: 'pointer', padding: '5px' }}>
      {number}
    </li>
  );
});





  const fetchEmployees = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/employees`, {
        headers: await getAuthHeaders()
      });
      if (response) {
        const data = await response.json();
        console.log(data)
        setEmployees(data); 
      }
    } catch (error) {
      console.log('Error fetching employees:', error);
    }
  };

  const fetchMatters = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/allmatters`, {
        headers: await getAuthHeaders()
      });
      const data = await response.json();
      const mattersData = Array.isArray(data) ? data : [];
      setMatters(mattersData);
      // setMatters(data);
    } catch (error) {
      console.log('Error fetching matters:', error);
    }
  };

  
  const handleSearchByChange = (event) => {
    setSearchBy(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };



  useEffect(() => {
    const filterTasks = () => {
      const result = tasks.filter((task) => {
        if (searchBy === 'task_title') {
          return task.task_title.toLowerCase().includes(searchQuery.toLowerCase());
        } else if (searchBy === 'assignedto') {
          return task.assignedto.toLowerCase().includes(searchQuery.toLowerCase());
        } else if (searchBy === 'description') {
          return task.description.toLowerCase().includes(searchQuery.toLowerCase());
        } else if (searchBy === 'status') {
          return task.status.toLowerCase().includes(searchQuery.toLowerCase());
        } else if (searchBy === 'createdby') {
          return task.createdby.toLowerCase().includes(searchQuery.toLowerCase());
        } else if (searchBy === 'duedate') {
          return task.duedate.includes(searchQuery);
        } else if (searchBy === 'mattername') {
          return task.mattername.toLowerCase().includes(searchQuery.toLowerCase());
        }
        return true;
      });
      setFilteredTasksList(result);
    };
  
    filterTasks();
  }, [searchBy, searchQuery, tasks]);
  
  useEffect(() => {
    const filterMyTasks = () => {
      const result = myTasks.filter((task) => {
        if (searchBy === 'task_title') {
          return task.task_title.toLowerCase().includes(searchQuery.toLowerCase());
        } else if (searchBy === 'assignedto') {
          return task.assignedto.toLowerCase().includes(searchQuery.toLowerCase());
        } else if (searchBy === 'description') {
          return task.description.toLowerCase().includes(searchQuery.toLowerCase());
        } else if (searchBy === 'status') {
          return task.status.toLowerCase().includes(searchQuery.toLowerCase());
        } else if (searchBy === 'createdby') {
          return task.createdby.toLowerCase().includes(searchQuery.toLowerCase());
        } else if (searchBy === 'duedate') {
          return task.duedate.includes(searchQuery);
        } else if (searchBy === 'mattername') {
          return task.mattername.toLowerCase().includes(searchQuery.toLowerCase());
        }
        return true;
      });
      setFilteredMyTasksList(result);
    };
  
    filterMyTasks();
  }, [searchBy, searchQuery, myTasks]);
  
  

  const handleAddTaskInputChange = async (e) => {
    const { name, value } = e.target;
    const { oid } = accounts[0].idTokenClaims;
    setNewTask((prevState) => ({
      ...prevState,
      [name]: value,
      createdby: oid,
    }));
  };  

  const handleAddTaskSubmit = async (e) => {
    e.preventDefault();
    try {
      await fetch(`${process.env.REACT_APP_API_BASE_URL}/addtask`, {
        method: 'POST',
        headers: await getAuthHeaders(),
        body: JSON.stringify(newTask),
      });
      // fetchTasks(); // Fetch the updated tasks list
      // fetchMyTasks();
      setSuccessMessage('Task successfully created');
      setTimeout(() => setSuccessMessage(''), 2500);
      setNewTask({
        matterid: '',
        task_title: '',
        description: '',
        duedate: '',
        assignedto: '',
        status: 'Pending',
        createdby: '',
        task_type: '', 

      })
    } catch (error) {
      console.log('Error adding task:', error);
    }
    setIsModalOpen(false);
  };
  const handleMarkPending = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/task-pending/${selectedTask.taskid}`, {
        method: 'PUT',
        headers: await getAuthHeaders(),
        body: JSON.stringify({ status: 'Pending' }),
      });

      if (response.ok) {
        // Update the task status locally after successful update
        setSelectedTask({ ...selectedTask, status: 'Pending' });
      }
    } catch (error) {
      console.log('Error marking task as Pending:', error);
    }
  };

  const handleMarkInProgress = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/task-inprogress/${selectedTask.taskid}`, {
        method: 'PUT',
        headers: await getAuthHeaders(),
        body: JSON.stringify({ status: 'In Progress' }),
      });

      if (response.ok) {
        // Update the task status locally after successful update
        setSelectedTask({ ...selectedTask, status: 'In Progress' });
      }
    } catch (error) {
      console.log('Error marking task as in progress:', error);
    }
  };

  const handleMarkCompleted = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/task-complete/${selectedTask.taskid}`, {
        method: 'PUT',
        headers: await getAuthHeaders(),
        body: JSON.stringify({ status: 'Completed' }),
      });

      if (response.ok) {
        // Update the task status locally after successful update
        setSelectedTask({ ...selectedTask, status: 'Completed' });
      }
    } catch (error) {
      console.log('Error marking task as completed:', error);
    }
  };

  if (!myTasks) {
    return <div>Loading...</div>;
  }

  const getProgressBarColor = () => {
    if(selectedTask){
      if (selectedTask.status === 'Pending') {
        return 'red';
      } else if (selectedTask.status === 'In Progress') {
        return 'yellow';
      } else if (selectedTask.status === 'Completed') {
        return 'green';
      }
      return 'gray';
    }
  };

  const getProgressBarPartition = () => {
    if(selectedTask){
      if (selectedTask.status === 'Pending') {
        return '33.33%';
      } else if (selectedTask.status === 'In Progress') {
        return '66.66%';
      } else if (selectedTask.status === 'Completed') {
        return '100%';
      }
      return '0%';
    }
  };

  const progressBarContainerStyle = {
    width: '50%',
    maxWidth: '400px',
    height: '20px',
    backgroundColor: 'lightgray',
    borderRadius: '4px',
    overflow: 'hidden',
    position: 'relative',
  };

  const progressBarPartitionStyle = {
    height: '100%',
    width: getProgressBarPartition(),
    backgroundColor: getProgressBarColor(),
    transition: 'width 0.3s ease',
    position: 'relative',
  };

  const statusTextStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: 'black',
    fontWeight: 'bold',
  };

  return (
    <div className="backdrop">
      <div className="Add-matter-Matters-container"></div>
      <div className="tabs-container">
        <button
          className={`tab ${activeTab === 'myTasks' ? 'active-tab' : ''}`}
          onClick={() => setActiveTab('myTasks')}
        >
          My Tasks
        </button>
        <button
          className={`tab ${activeTab === 'allTasks' ? 'active-tab' : ''}`}
          onClick={() => setActiveTab('allTasks')}
        >
          All Tasks
        </button>
        <button
          className={`tab ${activeTab === 'CreateTasks' ? 'active-tab' : ''}`}
          onClick={() => setActiveTab('CreateTasks')}
        >
          Create a Task
        </button>
      </div>
  
      {activeTab === 'myTasks' && (
        <div className="table-container">
          <div className="add-search-sort-container">
            <div className="search-sort-container"></div>
            <div className="search-sort-container">
              <div style={{ marginBottom: '9px' }}>
                <Reporting_MyTasks />
              </div>
              <div>
                <input
                  type="text"
                  placeholder="Search"
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
              </div>
              <div className="MD-button-container" style={{ marginBottom: '25px' }}>
              <select value={searchBy} onChange={handleSearchByChange}>
                  <option value="">Filter</option>
                  <option value="task_title">Task Title</option>
                  <option value="assignedto">Assigned To</option>
                  <option value="description">Description</option>
                  <option value="status">Status</option>
                  <option value="createdby">Created By</option>
                  <option value="duedate">Due Date</option>
                  <option value="mattername">Matter Name</option>
                </select>
              </div>
            </div>
          </div>
          <table className="matter-table">
            <thead>
              <tr>
                <th>Title</th>
                <th>Description</th>
                <th>Assigned To</th>
                <th>Status</th>
                <th>Due Date</th>
                <th>Matter Name</th>
              </tr>
            </thead>
            <tbody>
              {filteredMyTasksList.map((task) => (
                <tr key={task.taskid}>
                  <td>
                    <button onClick={() => setSelectedTask(task)}>{task.task_title}</button>
                  </td>
                  <td>
                    {task.description.split(' ').slice(0, 4).join(' ') +
                      (task.description.split(' ').length > 4 ? '...' : '')}
                  </td>
                  <td>{task.assignedto}</td>
                  <td>{task.status}</td>
                  <td>{task.duedate}</td>
                  <td>
                    <Link to={`/matters/${task.matterid}`}>{task.mattername}</Link>
                  </td>
                </tr>
              ))}
                        <div className="pagination">
            <button onClick={handlePrevious} disabled={currentPage === 1}>
              Previous
            </button>
            <ul className="pagination-numbers">
              {pageNumbers.map((number) => (
                <li
                  key={number}
                  onClick={() => setCurrentPage(number)}
                  style={{ cursor: 'pointer', padding: '5px' }}
                >
                  {number}
                </li>
              ))}
            </ul>
            <button onClick={handleNext} disabled={currentPage === pageNumbers.length}>
              Next
            </button>
          </div>
            </tbody>
          </table>

          <div className={`side-banner ${selectedTask ? 'visible' : ''}`}>
            <div className="side-banner-header">
              <h3>Task Details</h3>
              <button className="side-banner-button" onClick={() => setSelectedTask(null)}>
                Close
              </button>
            </div>
  
            {selectedTask && (
              <div className="task-details-container">
                <div className="task-title-container">
                  <p className="task-title">{selectedTask.task_title}</p>
                  <div className="task-metadata-subheading">
                    <span>
                      <strong>ID:</strong> {selectedTask.taskid}
                    </span>
                    <span>
                      <strong>Created By:</strong> {selectedTask.createdby}
                    </span>
                  </div>
                </div>
  
                <div className="status-container">
                  <div className="Task-progress-bar-container" style={progressBarContainerStyle}>
                    <div className="Task-progress-bar-partition" style={progressBarPartitionStyle}></div>
                    <span className="status-text">{selectedTask.status}</span>
                  </div>
  
                  <div className="Task-milestone-buttons-container">
                    {selectedTask.status === 'Pending' && (
                      <button className="task-milestone-buttons" onClick={handleMarkInProgress}>
                        Mark In Progress
                      </button>
                    )}
                    {selectedTask.status === 'In Progress' && (
                      <>
                        <button className="task-milestone-buttons" onClick={handleMarkPending}>
                          Mark Pending
                        </button>
                        <button className="task-milestone-buttons" onClick={handleMarkCompleted}>
                          Mark Completed
                        </button>
                      </>
                    )}
                    {selectedTask.status === 'Completed' && (
                      <button className="task-milestone-buttons" onClick={handleMarkInProgress}>
                        Mark In Progress
                      </button>
                    )}
                  </div>
                </div>
  
                <div className="task-info-container">
                  <p>
                    <strong>Description:</strong> {selectedTask.description}
                  </p>
                  <p>
                    <strong>Due Date:</strong> {selectedTask.duedate}
                  </p>
                  <p>
                    <strong>Assigned To:</strong> {selectedTask.assignedto}
                  </p>
                  <p>
                    <strong>Status:</strong> {selectedTask.status}
                  </p>
                  <p>
                    <strong>Notes:</strong> {selectedTask.notes}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
  
      {activeTab === 'allTasks' && (
        <div className="table-container">
          <div className="add-search-sort-container">
            <div className="search-sort-container"></div>
            <div className="search-sort-container">
              <div style={{ marginBottom: '9px' }}>
                <ReportingTasks />
              </div>
  
              <div>
                <input
                  type="text"
                  placeholder="Search"
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
              </div>
  
              <div className="MD-button-container" style={{ marginBottom: '25px' }}>
              <select value={searchBy} onChange={handleSearchByChange}>
                <option value="">Filter</option>
                <option value="task_title">Task Title</option>
                <option value="assignedto">Assigned To</option>
                <option value="description">Description</option>
                <option value="status">Status</option>
                <option value="createdby">Created By</option>
                <option value="duedate">Due Date</option>
                <option value="mattername">Matter Name</option>
              </select>
              </div>
            </div>
          </div>
  
          <table className="matter-table">
            <thead>
              <tr>
                <th>Task Title</th>
                <th>Description</th>
                <th>Assigned To</th>
                <th>Status</th>
                <th>Due Date</th>
                <th>Matter Name</th>
              </tr>
            </thead>
            <tbody>
              {filteredTasksList.map((task) => (
                <tr key={task.taskid}>
                  <td>
                    <button onClick={() => setSelectedTask(task)}>{task.task_title}</button>
                  </td>
                  <td>
                    {task.description.split(' ').slice(0, 4).join(' ') +
                      (task.description.split(' ').length > 4 ? '...' : '')}
                  </td>
                  <td>{task.assignedto}</td>
                  <td>{task.status}</td>
                  <td>{task.duedate}</td>
                  <td>
                    <Link to={`/matters/${task.matterid}`}>{task.mattername}</Link>
                  </td>
                </tr>
              ))}
          <div className="pagination">
            <button onClick={handlemyPrevious} disabled={currentPage === 1}>
              Previous
            </button>
            <ul className="pagination-numbers">
              {pageNumbers.map((number) => (
                <li
                  key={number}
                  onClick={() => setCurrentPage(number)}
                  style={{ cursor: 'pointer', padding: '5px' }}
                >
                  {number}
                </li>
              ))}
            </ul>
            <button onClick={handlemyNext} disabled={currentPage === pageNumbers.length}>
              Next
            </button>
          </div>
            </tbody>
          </table>
  

  
          <div className={`side-banner ${selectedTask ? 'visible' : ''}`}>
            <div className="side-banner-header">
              <h3>Task Details</h3>
              <button className="side-banner-button" onClick={() => setSelectedTask(null)}>
                Close
              </button>
            </div>
  
            {selectedTask && (
              <div className="task-details-container">
                <div className="task-title-container">
                  <p className="task-title">{selectedTask.task_title}</p>
                  <div className="task-metadata-subheading">
                    <span>
                      <strong>ID:</strong> {selectedTask.taskid}
                    </span>
                    <span>
                      <strong>Created By:</strong> {selectedTask.createdby}
                    </span>
                  </div>
                </div>
  
                <div className="status-container">
                  <div className="Task-progress-bar-container" style={progressBarContainerStyle}>
                    <div className="Task-progress-bar-partition" style={progressBarPartitionStyle}></div>
                    <span className="status-text">{selectedTask.status}</span>
                  </div>
  
                  <div className="Task-milestone-buttons-container">
                    {selectedTask.status === 'Pending' && (
                      <button className="task-milestone-buttons" onClick={handleMarkInProgress}>
                        Mark In Progress
                      </button>
                    )}
                    {selectedTask.status === 'In Progress' && (
                      <>
                        <button className="task-milestone-buttons" onClick={handleMarkPending}>
                          Mark Pending
                        </button>
                        <button className="task-milestone-buttons" onClick={handleMarkCompleted}>
                          Mark Completed
                        </button>
                      </>
                    )}
                    {selectedTask.status === 'Completed' && (
                      <button className="task-milestone-buttons" onClick={handleMarkInProgress}>
                        Mark In Progress
                      </button>
                    )}
                  </div>
                </div>
  
                <div className="task-info-container">
                  <p>
                    <strong>Description:</strong> {selectedTask.description}
                  </p>
                  <p>
                    <strong>Due Date:</strong> {selectedTask.duedate}
                  </p>
                  <p>
                    <strong>Assigned To:</strong> {selectedTask.assignedto}
                  </p>
                  <p>
                    <strong>Status:</strong> {selectedTask.status}
                  </p>
                  <p>
                    <strong>Notes:</strong> {selectedTask.notes}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
  
      {activeTab === 'CreateTasks' && (
        <form onSubmit={handleAddTaskSubmit}>
          <h4>Add New Task</h4>
          <label>
            Matter:
            <select
              name="matterid"
              value={newTask.matterid}
              onChange={handleAddTaskInputChange}
              required
            >
              <option value="" disabled>
                Select Matter
              </option>
              {matters.map((matter) => (
                <option key={matter.matterid} value={matter.matterid}>
                  {matter.matterid}: {matter.mattername}
                </option>
              ))}
            </select>
          </label>
          <label>
            Task Title:
            <input
              type="text"
              name="task_title"
              value={newTask.task_title}
              onChange={handleAddTaskInputChange}
              required
            />
          </label>
          <label>
            Description:
            <input
              type="text"
              name="description"
              value={newTask.description}
              onChange={handleAddTaskInputChange}
              required
            />
          </label>
          <label>
            Due Date:
            <input
              type="date"
              name="duedate"
              value={newTask.duedate}
              onChange={handleAddTaskInputChange}
              required
            />
          </label>
          <label>
            Assigned To:
            <select
              name="assignedto"
              value={newTask.assignedto}
              onChange={handleAddTaskInputChange}
              required
            >
              <option value="" disabled>
                Select Employee
              </option>
              {employees.map((employee) => (
                <option key={employee.EmployeeID} value={employee.EmployeeID}>
                  {`${employee.FirstName} ${employee.LastName}`}
                </option>
              ))}
            </select>
          </label>
          <label htmlFor="task_type">
            Task Type:
            <select
              name="task_type"
              id="task_type"
              value={newTask.task_type}
              onChange={handleAddTaskInputChange}
              required
            >
              <option value="" disabled>
                Select Task Type
              </option>
              <option value="Internal">Internal</option>
              <option value="External">External</option>
            </select>
          </label>
  
          <br />
          <button type="submit">
            <strong>Create New Task</strong>
          </button>
          <br />
          {successMessage && <SuccessMessage message={successMessage} />}
        </form>
      )}
      <div className="add-task-form"></div>
    </div>
  );
};

export default MyTasks;