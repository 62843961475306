import React, { useEffect } from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

function Liabilities() {
  useEffect(() => {
    let root = am5.Root.new("liabilitiesChartdiv");

    root.setThemes([
      am5themes_Animated.new(root)
    ]);

    let chart = root.container.children.push(am5xy.XYChart.new(root, {
      layout: root.verticalLayout
    }));

    // Total liabilities data
    let data = [
      { category: "Current Liabilities", shortTermDebt: 15000, accountsPayable: 10000, accruedExpenses: 8000 },
      { category: "Long-term Liabilities", longTermDebt: 25000, deferredTax: 12000, otherLiabilities: 7000 }
    ];

    // Create X-axis
    let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
      categoryField: "category",
      renderer: am5xy.AxisRendererX.new(root, {})
    }));
    xAxis.data.setAll(data);

    // Create Y-axis
    let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      renderer: am5xy.AxisRendererY.new(root, {})
    }));

    // Create series for Short Term Debt
    let series1 = chart.series.push(am5xy.ColumnSeries.new(root, {
      name: "Short Term Debt",
      stacked: true,
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: "shortTermDebt",
      categoryXField: "category",
      tooltip: am5.Tooltip.new(root, {
        labelText: "{valueY}"
      })
    }));

    // Create series for Accounts Payable
    let series2 = chart.series.push(am5xy.ColumnSeries.new(root, {
      name: "Accounts Payable",
      stacked: true,
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: "accountsPayable",
      categoryXField: "category",
      tooltip: am5.Tooltip.new(root, {
        labelText: "{valueY}"
      })
    }));

    // Create series for Accrued Expenses
    let series3 = chart.series.push(am5xy.ColumnSeries.new(root, {
      name: "Accrued Expenses",
      stacked: true,
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: "accruedExpenses",
      categoryXField: "category",
      tooltip: am5.Tooltip.new(root, {
        labelText: "{valueY}"
      })
    }));

    // Create series for Long Term Debt
    let series4 = chart.series.push(am5xy.ColumnSeries.new(root, {
      name: "Long Term Debt",
      stacked: true,
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: "longTermDebt",
      categoryXField: "category",
      tooltip: am5.Tooltip.new(root, {
        labelText: "{valueY}"
      })
    }));

    // Create series for Deferred Tax
    let series5 = chart.series.push(am5xy.ColumnSeries.new(root, {
      name: "Deferred Tax",
      stacked: true,
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: "deferredTax",
      categoryXField: "category",
      tooltip: am5.Tooltip.new(root, {
        labelText: "{valueY}"
      })
    }));

    // Create series for Other Liabilities
    let series6 = chart.series.push(am5xy.ColumnSeries.new(root, {
      name: "Other Liabilities",
      stacked: true,
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: "otherLiabilities",
      categoryXField: "category",
      tooltip: am5.Tooltip.new(root, {
        labelText: "{valueY}"
      })
    }));

    // Set data for series
    series1.data.setAll(data);
    series2.data.setAll(data);
    series3.data.setAll(data);
    series4.data.setAll(data);
    series5.data.setAll(data);
    series6.data.setAll(data);

    // Add cursor
    chart.set("cursor", am5xy.XYCursor.new(root, {}));

    // Cleanup
    return () => {
      root.dispose();
    };
  }, []);

  return (
    <div className="box open-matters-widget">
      <h2>Total Liabilities</h2>
      <div id="liabilitiesChartdiv" style={{ width: "100%", height: "500px" }}></div>
    </div>
  );
}

export default Liabilities;

