import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './styles/General.css';

const NewMatters = () => {
  const [showApprovalPopup, setShowApprovalPopup] = useState(false);
  const [showGeneralPopup, setShowGeneralPopup] = useState(false);
  const [showFilesPopup, setShowFilesPopup] = useState(false);
  const [showFinancialPopup, setShowFinancialPopup] = useState(false);
  const [showClientPopup, setShowClientPopup] = useState(false);
  const [showSMEsPopup, setShowSMEsPopup] = useState(false);
  const [showAddNotesPopup, setShowAddNotesPopup] = useState(false);
  const location = useLocation();
  const [notesText, setNotesText] = useState('');
  
  const toggleApprovalPopup = () => {
    setShowApprovalPopup(!showApprovalPopup);
  };

  const toggleGeneralPopup = () => {
    setShowGeneralPopup(!showGeneralPopup);
  };

  const toggleFilesPopup = () => {
    setShowFilesPopup(!showFilesPopup);
  };

  const toggleFinancialPopup = () => {
    setShowFinancialPopup(!showFinancialPopup);
  };

  const toggleClientPopup = () => {
    setShowClientPopup(!showClientPopup);
  };

  const toggleSMEsPopup = () => {
    setShowSMEsPopup(!showSMEsPopup);
  };

  const toggleAddNotesPopup = () => {
    setShowAddNotesPopup(!showAddNotesPopup);
  };

  const handleNotesChange = (event) => {
    setNotesText(event.target.value);
  };

  return (
    <div>
      <Link to="/matter">
        <button>Return</button>
      </Link>

      <button onClick={toggleApprovalPopup}>Send for Approval</button>

      {showApprovalPopup && (
        <div className="popup">
          <h2>Send for Approval</h2>
          <div>
          <p>SME#1 [SME#1]</p>
          <p>SME#2 [SME#2]</p>
          <p>SME#3 [SME#3]</p>
          </div>
          <p>This is the content of the pop-up.</p>
          <button onClick={toggleApprovalPopup}>Close</button>
        </div>
      )}

      <h1>"Matter Name - ###-###" (staus) [Identification]-[Preservation]-[Early Assessment]-[Processing & Analysis]--[Production & Presentation]</h1>

      <div className="button-container">
        <button onClick={toggleGeneralPopup}>General</button>

        {showGeneralPopup && (
          <div className="popup">
            <h2>About</h2>
            <div>

              <p>Date Created: [Date]</p>
              <p>Current Timeline: [Timeline]</p>
              <p>Client: [Client Name]</p>
              <p>Internal/External: [Internal/External]</p>
              <p>Leader: [Leader Name]</p>
              <p>ID: [ID]</p>
              <p>Milestone: [Milestone]</p>
              <p>Contacts: [Contact]</p>
            </div>
            <button onClick={toggleGeneralPopup}>Close</button>
          </div>
        )}

        <button onClick={toggleFilesPopup}>Files</button>

        {showFilesPopup && (
          <div className="popup">
            <h2>Files Popup</h2>
            <div>
              <p> + Add Files [Files]</p>
              <p>+ Add Folder [Folder]</p>
            </div>
            <button onClick={toggleFilesPopup}>Close</button>
          </div>
        )}

        <button onClick={toggleFinancialPopup}>Financial</button>

        {showFinancialPopup && (
          <div className="popup">
            <h2>Financial Popup</h2>
            <p>This is the content of the financial pop-up.</p>
            <button onClick={toggleFinancialPopup}>Close</button>
          </div>
        )}

        <button onClick={toggleClientPopup}>Client</button>

        {showClientPopup && (
          <div className="popup">
            <h2>Client Popup</h2>
            <div
            ><p>Client name [client] </p>
            </div>
            <button onClick={toggleClientPopup}>Close</button>
          </div>
        )}

        <button onClick={toggleSMEsPopup}>SME's</button>

        {showSMEsPopup && (
          <div className="popup">
            <h2>SME's Popup</h2>
           <div>
             <p>SME#1 [SME#1] </p>
            </div>
            <button onClick={toggleSMEsPopup}>Close</button>
          </div>
        )}
        
    <button onClick={toggleAddNotesPopup}>Add Notes</button>

         {showAddNotesPopup && (
         <div className="popup">
         <h2>Add Notes Popup</h2>
        <p>This is the content of the add notes pop-up.</p>
        <input type="text" value={notesText} onChange={handleNotesChange} />
        <button onClick={toggleAddNotesPopup}>Close</button>
  </div>
)}
        
      </div>
    </div>
  );
};

export default NewMatters;
