import React, { useState, useEffect } from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import WorkflowEditPage from './WorkflowEditPage'; // Make sure to import the WorkflowEditPage
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import './styles/Milestone.css';
import './styles/MatterDetails.css';

import getAuthHeaders from './functions/getAuthHeaders';

function Milestone({ workflowid, matterId, matterStatus }) {
    const [selectedMilestone, setSelectedMilestone] = useState('');
    const [showChangeMilestonePopup, setShowChangeMilestonePopup] = useState(false);
    const [matterDetails, setMatterDetails] = useState({ status: matterStatus });
    // const [matterId, setMatterId] = useState(''); //
    const [isWorkflowSet, setIsWorkflowSet] = useState(workflowid);
    const [workflowName, setWorkflowName] = useState('');
    const [existingWorkflows, setExistingWorkflows] = useState([]);
    const [showExistingWorkflowsPopup, setShowExistingWorkflowsPopup] = useState(false);
    const [selectedWorkflow, setSelectedWorkflow] = useState(null);
    const [assignedWorkflowName, setAssignedWorkflowName] = useState('None');
    const [assignedWorkflowMilestones, setAssignedWorkflowMilestones] = useState([]);
    const [showEditOptions, setShowEditOptions] = useState(false);

    
    const fetchAssignedWorkflow = async () =>{
      if (!isWorkflowSet){
        return
      }
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/workflows/${isWorkflowSet}`, {
          headers: await getAuthHeaders()
        });
        if (response.ok) {
          const workflowData = await response.json();
          // Extract workflow details and set them in the state
          setAssignedWorkflowName(workflowData.name);
          setAssignedWorkflowMilestones(workflowData.milestones);
        } else {
          // Handle the case where the API call was not successful
          console.error('API call was not successful');
        }
      } catch (error) {
        console.error('Error fetching assigned workflow:', error);
        // Handle any other errors that may occur during the API call
      }
    }

    const handleMilestoneChange = (index, key, event) => {
        // Get the new value from the event target
        const newValue = event.target.value;
        // Create a new array with the updated milestone
        const updatedWorkflow = tempWorkflow.map((milestone, i) =>
            i === index ? { ...milestone, [key]: newValue } : milestone
        );
        // Update the tempWorkflow state with the new array
        setTempWorkflow(updatedWorkflow);
    };

const handleMilestoneSelectionChange = (event) => {
    setSelectedMilestone(event.target.value);
};
      
    
    const [workflow, setWorkflow] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
      const statusTextStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: 'black',
    fontWeight: 'bold',
  };
  const [showEditWorkflow, setShowEditWorkflow] = useState(false);
  const [tempWorkflow, setTempWorkflow] = useState([]);

  const handleInputChange = (event, index) => {
    const { name, value } = event.target;
    setTempWorkflow((currentWorkflow) => {
      const newWorkflow = [...currentWorkflow];
      newWorkflow[index] = { ...newWorkflow[index], [name]: value };
      return newWorkflow;
    });
  };

  useEffect(() => {
    fetchAssignedWorkflow(isWorkflowSet);
  }, [isWorkflowSet]);
  
  // Handling the workflow editing
  const handleAddMilestone = () => {
    const newMilestone = { name: ''};
    const updatedWorkflow = [...tempWorkflow, newMilestone];
    updatePartitionValues(updatedWorkflow);
};

const handleDeleteMilestone = (index) => {
    const updatedWorkflow = tempWorkflow.filter((_, i) => i !== index);
    updatePartitionValues(updatedWorkflow);
};

const handleAddExistingWorkflow = async () => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/workflows`, {
      headers: await getAuthHeaders()
    });
    if (!response.ok) {
      console.log("Error fetching workflows")
    }

    const data = await response.json();
    setExistingWorkflows(data);
    setShowExistingWorkflowsPopup(true);
    // Handle the data (e.g., update state with existing workflows)
    console.log('Existing workflows:', data);
  } catch (error) {
    console.error('Error fetching existing workflows:', error.message);
  }
}

const handleWorkflowRadioChange = (workflowId) => {
  // Update the selected workflow when a radio button is changed
  setSelectedWorkflow(workflowId);
};


const renderExistingWorkflowsPopup = () => {
  return (
    <div className="modal-overlay">
      <div className="changeMilestone-popup">
        <div className="popup-header">
          <h2>Existing Workflows</h2>
          <button className="close-button" onClick={() => setShowExistingWorkflowsPopup(false)}>
            &times;
          </button>
        </div>
        <form>
          <ul>
            {existingWorkflows.map((workflow, index) => (
              <label key={index} className={`milestone-label ${selectedWorkflow === workflow.workflowid ? 'selected' : ''}`}>
                <input
                  type="radio"
                  name="workflowRadio"
                  checked={selectedWorkflow === workflow.workflowid}
                  onChange={() => handleWorkflowRadioChange(workflow.workflowid)}
                  className="visually-hidden"
                />
                <div>
                  <strong>Name:</strong> {workflow.name}
                </div>
                <div>
                  <strong>Milestones:</strong> {workflow.milestones.join(', ')}
                </div>
              </label>
            ))}
          </ul>
          <div className="Approval-button-container">
            <button type="button" className="change-button" onClick={() => handleAssignWorkflow(selectedWorkflow)} disabled={!selectedWorkflow}>
              Assign Workflow
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const handleAssignWorkflow = async (selectedWorkflow) => {
  try {
    // Add logic to send a request to update the matter workflow
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/matter-workflow/${matterId}`, {
      method: 'PUT',
      headers: await getAuthHeaders(),
      body: JSON.stringify({ workflowid: selectedWorkflow }),
    });

    if (response.ok) {
      console.log('Matter workflow assigned successfully!');
      setIsWorkflowSet(selectedWorkflow)
      // Add any additional logic you need after successful assignment
    } else {
      console.error('Failed to assign matter workflow');
    }
  } catch (error) {
    console.error('Error assigning matter workflow:', error);
  } finally {
    
    setShowExistingWorkflowsPopup(false);
  }
};


const handleCreateWorkflow = async () => {
    if (Array.isArray(tempWorkflow)) {
        setWorkflow(tempWorkflow);
        const milestoneNames = tempWorkflow.map((milestone) => milestone.name);
        const workflowData = {
          name: workflowName, 
          milestones: milestoneNames,
        };
        try {
          const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/workflows`, {
            method: 'POST', // Use POST method for creating a new resource
            headers: await getAuthHeaders(),
            body: JSON.stringify(workflowData), 
          });
    
          if (response.ok) {
            // Handle the successful response
            console.log('Workflow saved successfully!');
          } else {
            // Handle the case where the API call was not successful
            console.error('API call was not successful');
          }
        } catch (error) {
          console.error('Error saving workflow:', error);
          // Handle any other errors that may occur during the API call
        } finally {
          // Regardless of success or failure, close the edit workflow popup
          setShowEditWorkflow(false);
          setWorkflow([]);
          setWorkflowName('');
        }
    } else {
        console.error('tempWorkflow is not an array:', tempWorkflow);
    }
};

const updatePartitionValues = (updatedWorkflow) => {
    const partitionValue = (100 / updatedWorkflow.length).toFixed(2) + '%';
    const newWorkflow = updatedWorkflow.map(milestone => ({ ...milestone, partition: partitionValue }));
    setTempWorkflow(newWorkflow);
};


const getProgressBarPartition = () => {
    if (Array.isArray(workflow)) {
        const currentMilestone = workflow.find(milestone => milestone.name === matterDetails.status);
        return currentMilestone ? currentMilestone.partition : '0%';
    }
    return '0%';
};

      const getProgressBarColor = () => {
        const currentMilestone = workflow.find(milestone => milestone.name === matterDetails.status);
        // console.log('Current Color:', currentMilestone ? currentMilestone.color : 'grey');
        // console.log(assignedWorkflowMilestones)
        return currentMilestone ? currentMilestone.color : 'grey';
      };
    
      // Conditional rendering for the editing page
      if (isEditing) {
        return <WorkflowEditPage onSave={handleCreateWorkflow} initialWorkflow={workflow} />;
      }
      
        
      const progressBarContainerStyle = {
        width: '50%',
        maxWidth: '400px',
        height: '20px',
        backgroundColor: 'lightgray',
        borderRadius: '4px',
        overflow: 'hidden',
        position: 'relative',
      };
    
      const progressBarPartitionStyle = {
        height: '100%',
        width: getProgressBarPartition(),
        backgroundColor: getProgressBarColor(),
        transition: 'width 0.3s ease',
      
      };

      const handleMatterStatus = async (status) => {
  
        try {
          const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/matter-status/${matterId}`, {
            method: 'PUT',
            headers: await getAuthHeaders(),
            body: JSON.stringify({ status }),
          });
      
          if (response.ok) {
            // Update the matterDetails state with the new status
            setMatterDetails({ ...matterDetails, status });
            setShowChangeMilestonePopup(false); // Hide the milestone pop-up after changing the milestone
          } else {
            // Handle the case where the API call was not successful
            console.error('API call was not successful');
          }
        } catch (error) {
          console.error('Error marking matter status:', error);
          // Handle any other errors that may occur during the API call
        }
      };


const renderWorkflowEditPopup = () => {
  return (
      <div className="workflow-modal-overlay">
          <div className="workflow-edit-modal">
              <h1>Workflow Editor</h1>

              <button className="workflow-close-modal" onClick={() => setShowEditWorkflow(false)}>
                  <FontAwesomeIcon icon={faTimes} />
              </button>
              <input
                  type="text"
                  placeholder="Workflow Name (required)"
                  value={workflowName}  
                  onChange={(e) => setWorkflowName(e.target.value)}
                  required
              />
              {tempWorkflow.map((milestone, index) => (
                  <div key={index}>
                      <input
                          type="text"
                          placeholder="Name"
                          value={milestone.name}
                          onChange={(e) => handleMilestoneChange(index, 'name', e)}
                      />
                      <button 
                          onClick={() => handleDeleteMilestone(index)}
                          className="workflow-delete-button"
                      >
                          <FontAwesomeIcon icon={faTimes} />
                      </button>
                  </div>
              ))}
              <div>
                  <button onClick={handleAddMilestone}>Add Milestone</button>
                  <button onClick={handleCreateWorkflow} disabled={!workflowName.trim()}>
                    Create Workflow
                  </button>
              </div>
          </div>
      </div>
  );
};
    
    
      
return (
  <div className="milestone-section-container">
<h1 style={{ fontSize: '20px', marginBottom: '-20px', display:'flex', justifyContent: 'center' }}>{assignedWorkflowName} </h1><br/>
    <div className="progressBarContainer">
      <div className="progressBarFill" style={{ width: getProgressBarPartition() }}>
        <span className="statusText">{matterDetails.status}</span>
      </div>
    </div>
    {/* Conditionally render the buttons */}
    {isWorkflowSet ? (
        <div className="workflow-button-container">  
        <button onClick={() => setShowEditOptions(!showEditOptions)} className="workflow-button">
           Edit
        </button>
        {showEditOptions && (
           <div className="edit-options">
          <button onClick={() => { setTempWorkflow([...workflow]); setShowEditWorkflow(true); }} className="workflow-button">
            Create Workflow
          </button>
          <button onClick={() => handleAddExistingWorkflow()} className="workflow-button">
            Switch Workflow
          </button>
          </div>
          )}
          <button onClick={() => setShowChangeMilestonePopup(true)} className="workflow-button">
            Change Milestone
          </button>
          {showExistingWorkflowsPopup && renderExistingWorkflowsPopup()}
        </div>
      ) : (
        <div className="workflow-button-container">
    
          <button onClick={() => { setTempWorkflow([...workflow]); setShowEditWorkflow(true); }} className="workflow-button">
            Create Workflow
          </button>
          <button onClick={() => handleAddExistingWorkflow()} className="workflow-button">
            Add Existing Workflow
          </button>
          {showExistingWorkflowsPopup && renderExistingWorkflowsPopup()}
        </div>
      )}
    {showChangeMilestonePopup && (
      <div className="modal-overlay">
        <div className="changeMilestone-popup">
          <div className="popup-header">
            <h2>Select Milestone</h2>
            <button className="close-button" onClick={() => setShowChangeMilestonePopup(false)}>
              &times;
            </button>
          </div>
          <form>
            {assignedWorkflowMilestones.map((milestone, index) => (
              <label key={index} className={`milestone-label ${selectedMilestone === milestone ? 'selected' : ''}`}>
                <input
                  type="radio"
                  name="milestone"
                  value={milestone}
                  checked={selectedMilestone === milestone}
                  onChange={handleMilestoneSelectionChange} // Pass the function reference directly
                  className="visually-hidden"
                />
                {milestone}
              </label>
            ))}
            <div className="Approval-button-container">
              <button type="button" className="change-button" onClick={() => handleMatterStatus(selectedMilestone)}>
                Change Milestone
              </button>
            </div>
          </form>
        </div>
      </div>
    )}
    {showEditWorkflow && renderWorkflowEditPopup()}
  </div>
);
}

export default Milestone;