import React, { useState, useEffect } from 'react';
import { FaUpload, FaPlay, FaStop, FaRedo, FaWindowMinimize, FaWindowMaximize, FaTimes } from 'react-icons/fa';
import './styles/Timer.css';
import getAuthHeaders from './functions/getAuthHeaders';
import ReactSelect from 'react-select';
import { useMsal } from '@azure/msal-react';

function Timer() {
  const { accounts } = useMsal();
  const [timerOn, setTimerOn] = useState(false);
  const [timerStart, setTimerStart] = useState(0);
  const [timerTime, setTimerTime] = useState(0);
  const [showTimer, setShowTimer] = useState(true); // Initially, show the timer
  const [timerVisible, setTimerVisible] = useState(true); // State for entire timer visibility
  const [matters, setMatters] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const options = matters.map(matter => ({ value: matter.id, label: matter.name }));
  const [selectedMatter, setSelectedMatter] = useState('');
  const [notes, setNotes] = useState('');
  const [date, setDate] = useState(new Date().toISOString().slice(0, 10)); // default to today's date
  const [isBillable, setIsBillable] = useState(false);
  
  const handleMatterChange = selectedOption => {
    setSelectedMatter(selectedOption.value); // This should be the matterId
  };
  
  useEffect(() => {
    fetchMatters();
  }, []); 
  
  const handleUploadClick = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const customFilterOption = (option, inputText) => {
    const words = inputText.toLowerCase().split(' ').filter(x => x);
    const optionText = `${option.label} ${option.value}`.toLowerCase();
    return words.every(word => optionText.includes(word));
  };
  
  const handleNotesChange = (e) => {
    setNotes(e.target.value);
  };

  const handleDateChange = (e) => {
    setDate(e.target.value);
  };

  const handleSubmitConfirmation = async () => {
    const submissionData = {
      matterId: selectedMatter, 
      timeRecorded: formatTime(timerTime),
      date: date,
      notes: notes,
      employeeid: accounts[0].idTokenClaims.oid, // Include employeeid directly in submissionData
    };
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/billed_time`, {
        method: 'POST',
        headers: await getAuthHeaders(),
        body: JSON.stringify(submissionData),
      });
      const responseBody = await response.json();
      console.log('Time entry submitted successfully:', responseBody);
    } catch (error) {
      console.error('Error submitting time entry:', error);
    }
  
    setShowPopup(false);
  };
  
  useEffect(() => {
    let interval;
    if (timerOn) {
      interval = setInterval(() => {
        setTimerTime(prevTime => prevTime + 10);
      }, 10);
    } else if (!timerOn) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timerOn]);

  const startTimer = () => {
    setTimerOn(true);
    setTimerStart(Date.now() - timerTime);
  };

  const stopTimer = () => {
    setTimerOn(false);
  };

  const resetTimer = () => {
    setTimerStart(0);
    setTimerTime(0);
  };

  const toggleTimerVisibility = () => {
    setShowTimer(!showTimer);
  };

  const closeTimer = () => {
    setTimerVisible(false); // Close the timer
  };

  const fetchMatters = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/allmatters`, {
        headers: await getAuthHeaders()
      });
      const data = await response.json();
      const mattersData = Array.isArray(data) ? data : [];
      setMatters(mattersData);
    } catch (error) {
      console.log('Error fetching matters:', error);
    }
  };
  
  const formatTime = time => {
    const seconds = ('0' + (Math.floor(time / 1000) % 60)).slice(-2);
    const minutes = ('0' + (Math.floor(time / 60000) % 60)).slice(-2);
    const hours = ('0' + Math.floor(time / 3600000)).slice(-2);
    return `${hours}:${minutes}:${seconds}`;
  };

  if (!timerVisible) return null; // Render nothing if the timer is closed

  return (
    <div className={`timer-container ${showTimer ? '' : 'timer-minimized'}`}>
      <div className="timer-header">
        <div>
        <button onClick={toggleTimerVisibility} className="minimize-btn" aria-label={showTimer ? "Minimize Timer" : "Maximize Timer"} style={{ marginBottom: '15px' }}>
          {showTimer ? <FaWindowMinimize /> : <FaWindowMaximize />}
        </button>
        </div>
        <div>
        <button onClick={closeTimer} className="close-btn" aria-label="Close Timer">
          <FaTimes />
        </button>
        </div>
      </div>
      
      {showTimer ? (
        <>
          <div className="timer-display">{formatTime(timerTime)}</div>
          <div className="matters-dropdown"></div>
          <div className="button-container">
            <button onClick={timerOn ? stopTimer : startTimer} className={`timer-btn ${timerOn ? 'active' : ''}`}>
              {timerOn ? <FaStop size={20} /> : <FaPlay size={20} />}
            </button>
            <button onClick={resetTimer} disabled={timerOn} className="timer-btn">
              <FaRedo size={20} />
            </button>
            <button onClick={handleUploadClick} className="timer-btn"><FaUpload /></button>

            {showPopup && (
              <div className="timer-backdrop">
                <div className="timer-popup">
                  <label>Submit this time to a matter:</label>
                  <ReactSelect
                        classNamePrefix="timer-select" 
                        className="timer-dropdown"
                        value={matters.find(matter => matter.matterid === selectedMatter) ? { value: selectedMatter, label: `${matters.find(matter => matter.matterid === selectedMatter).matterid}: ${matters.find(matter => matter.matterid === selectedMatter).mattername}` } : null}
                        onChange={selectedOption => setSelectedMatter(selectedOption ? selectedOption.value : '')}
                        options={matters.map(matter => ({ value: matter.matterid, label: `${matter.matterid}: ${matter.mattername}` }))}
                        placeholder="Select Matter"
                        isClearable={true}
                        isSearchable={true}
                        filterOption={customFilterOption}
                        styles={{
                          control: (base) => ({
                            ...base,
                            height: '40px',
                            display: 'flex',
                            alignItems: 'center',
                          }),
                          valueContainer: (base) => ({
                            ...base,
                            height: '40px',
                            display: 'flex',
                            alignItems: 'center',
                          }),
                          input: (base) => ({
                            ...base,
                            margin: '10px',
                            padding: '0px',
                          }),
                          placeholder: (base) => ({
                            ...base,
                            position: 'absolute',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            color: '#ccc',
                          }),
                          singleValue:  (base) => ({
                            ...base,
          
                          }),
                          indicatorsContainer: (base) => ({
                            ...base,
                            marginTop: '17px',
                            height: '40px',
                            alignItems: 'center',
                          }),
                      
                          dropdownIndicator: (base) => ({
                            ...base,
                            marginTop: '-29px',
                          }),
                          indicatorSeparator : (base) => ({
                            ...base,
                            marginTop: '-10px',
                            marginBottom: '25px',
                          }),
                        }}
                      />
                  <p><strong>Time Recorded:</strong> {formatTime(timerTime)}</p>
                  <label htmlFor="date">Date: </label>
                  <input 
                    type="date" 
                    id="date" 
                    value={date} 
                    onChange={handleDateChange} 
                  />
                  <label htmlFor="notes">Notes: </label>
                  <textarea 
                    id="notes" 
                    value={notes} 
                    onChange={handleNotesChange} 
                  />
                  <button onClick={handleSubmitConfirmation}>Submit</button>
                  <button onClick={handleClosePopup}>Cancel</button>
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="timer-display-minimized">
          {formatTime(timerTime)}
        </div>
      )}
    </div>
  );
}

export default Timer;
