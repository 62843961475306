import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import getAuthHeaders from '../functions/getAuthHeaders';
import '../styles/Deadline.css';
import { useMsal } from '@azure/msal-react';

const EditableText = ({ type = "text", fieldName, value, onSave }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleSave = () => {
    onSave(fieldName, inputValue);
    setIsEditing(false);
  };

  return isEditing ? (
    <div>
      <input
        type={type}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onBlur={handleSave}
        autoFocus
      />
      <button onClick={handleSave}>Save</button>
    </div>
  ) : (
    <div onClick={() => setIsEditing(true)}>{value || `Click to edit ${fieldName}`}</div>
  );
};

const Deadline = () => {
  const { matterId } = useParams();
  const { accounts } = useMsal();
  const [deadlines, setDeadlines] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    fetchDeadlines();
  }, [matterId]);

  const fetchDeadlines = async () => {
    try {
      const headers = await getAuthHeaders();
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/deadlines/${matterId}`, {
        method: 'GET',
        headers,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setDeadlines(data);
    } catch (error) {
      console.error('Error fetching deadlines:', error);
    }
  };

  const addDeadline = async (deadlineType, type, dueDate, internalExternal) => {
    try {
      const { oid } = accounts[0].idTokenClaims;
      const headers = await getAuthHeaders();
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/add-deadline`, {
        method: 'POST',
        headers: {
          ...headers,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          deadline_type: deadlineType, 
          type, 
          due_date: dueDate, 
          matterid: matterId, 
          employeeid: oid, 
          internal_external: internalExternal 
        }),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const newItem = await response.json();
      setDeadlines([...deadlines, { ...newItem, deadline_type: deadlineType, type, due_date: dueDate, internal_external: internalExternal }]);
    } catch (error) {
      console.error('Error adding deadline:', error);
    }
  };
  
  const handleFormSubmit = (e) => {
    e.preventDefault();
    const deadlineType = e.target.elements.deadlineType.value.trim();
    const type = e.target.elements.type.value.trim();
    const dueDate = e.target.elements.dueDate.value.trim();
    const internalExternal = e.target.elements.internalExternal.value.trim(); // Get the selected internal/external value
    
    if (deadlineType && type && dueDate && internalExternal) {
      addDeadline(deadlineType, type, dueDate, internalExternal);
      e.target.elements.deadlineType.value = ''; // Reset input after submission
      e.target.elements.type.value = ''; // Reset input after submission
      e.target.elements.dueDate.value = ''; // Reset input after submission
      e.target.elements.internalExternal.value = ''; // Reset dropdown after submission
      setShowForm(false); // Close modal after submission
    }
  };
  

  const handleFieldSave = async (deadlineId, fieldName, newValue) => {
    try {
      const updatedData = { [fieldName]: newValue };
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/edit_deadline/${deadlineId}`, {
        method: 'PUT',
        headers: {
          ...await getAuthHeaders(),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedData),
      });

      if (response.ok) {
        setDeadlines((prev) =>
          prev.map((deadline) =>
            deadline.deadline_id === deadlineId
              ? { ...deadline, [fieldName]: newValue }
              : deadline
          )
        );
        alert('Update successful');
      } else {
        alert('Failed to update');
      }
    } catch (error) {
      console.error('Error updating deadline:', error);
      alert('Error updating: ' + error.message);
    }
  };

  const handleDelete = async (deadlineId) => {
    try {
      const headers = await getAuthHeaders();
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/delete_deadline/${deadlineId}`, {
        method: 'DELETE',
        headers,
      });

      if (response.ok) {
        setDeadlines((prev) => prev.filter((deadline) => deadline.deadline_id !== deadlineId));
        alert('Delete successful');
      } else {
        alert('Failed to delete');
      }
    } catch (error) {
      console.error('Error deleting deadline:', error);
      alert('Error deleting: ' + error.message);
    }
  };

  const renderModal = () => (
    <div className="deadline-modal" style={{ display: showForm ? 'block' : 'none' }}>
      <div className="deadline-modal-content">
        <span className="deadline-close" onClick={() => setShowForm(false)}>&times;</span>
        <form onSubmit={handleFormSubmit}>
          <input type="text" name="deadlineType" placeholder="Deadline Type" required />
          <input type="text" name="type" placeholder="Type" required />
          <input type="date" name="dueDate" placeholder="Due Date" required />
            <select name="internalExternal" required>
            <option value="">Select Internal/External</option>
            <option value="internal">Internal</option>
            <option value="external">External</option>
          </select>
          <button type="submit">Create Deadline</button>
        </form>
      </div>
    </div>
  );
  

  const renderTable = (items, isInModal) => (
    <table className={isInModal ? 'deadline-table modal-deadline-table' : 'deadline-table'}>
      <thead>
        <tr>
          <th>Deadline</th>
          <th>Type</th> {/* New column for internal_external */}
          <th>Due Date</th>
          {isInModal && (
            <>
              <th>Subject</th>
              <th>Created At</th>
            </>
          )}
          {!isInModal && (
            <th>
              <button onClick={() => setShowForm(true)} className="deadlineAdd-button">Add</button>
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        {items.map(item => (
          <tr key={item.deadline_id} onClick={() => setIsExpanded(true)}>
            <td>
              {isEditMode ? (
                <EditableText
                  fieldName="deadline_type"
                  value={item.deadline_type || ''}
                  onSave={(field, value) => handleFieldSave(item.deadline_id, field, value)}
                />
              ) : (
                item.deadline_type
              )}
            </td>
            <td>
              {isEditMode ? (
                <EditableText
                  fieldName="internal_external"
                  value={item.internal_external || ''}
                  onSave={(field, value) => handleFieldSave(item.deadline_id, field, value)}
                />
              ) : (
                item.internal_external
              )}
            </td>
            <td>
              {isEditMode ? (
                <EditableText
                  type="date"
                  fieldName="due_date"
                  value={item.due_date || ''}
                  onSave={(field, value) => handleFieldSave(item.deadline_id, field, value)}
                />
              ) : (
                item.due_date
              )}
            </td>
            {isInModal && (
              <>
                <td>
                  {isEditMode ? (
                    <EditableText
                      fieldName="type"
                      value={item.type || ''}
                      onSave={(field, value) => handleFieldSave(item.deadline_id, field, value)}
                    />
                  ) : (
                    item.type
                  )}
                </td>
            
                <td>{item.created_at}</td>
              </>
            )}
            <td>
              <button onClick={() => handleDelete(item.deadline_id)}>Delete</button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
    
  return (
    <div>
      {renderModal()}
      {isExpanded ? (
        <div className="deadline-expanded-modal">
          <div className="deadline-expanded-modal-content">
            <span className="deadline-close" onClick={() => setIsExpanded(false)}>&times;</span>
            <button onClick={() => setIsEditMode(!isEditMode)} className="edit-button">
              {isEditMode ? 'Cancel' : 'Edit'}
            </button>
            {renderTable(deadlines, true)}
          </div>
        </div>
      ) : (
        <>
          {renderTable(deadlines.slice(0, 3), false)}
        </>
      )}
    </div>
  );
};

export default Deadline;
