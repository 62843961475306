import React, { useEffect, useState } from 'react';
import './styles/Clients.css';
import getAuthHeaders from './functions/getAuthHeaders';
import { Link } from 'react-router-dom';
import './styles/General.css';
import './styles/Matter.css';
import SuccessMessage from './components/SuccessMessage';
import ReportingClient from './Reporting/ReportingClient.js';
import { debounce } from 'lodash';

const Clients = () => {
  const [clients, setClients] = useState([]);
  const [newClient, setNewClient] = useState({
    FirstName: '',
    LastName: '',
    Company: '',
    Email: '',
    Phone: '',
    Address: '',
  });
  const [activeTab, setActiveTab] = useState('Clients');
  const [successMessage, setSuccessMessage] = useState('');
  const [searchBy, setSearchBy] = useState('');
  const [filterQuery, setFilterQuery] = useState('');
  const [filteredClients, setFilteredClients] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const clientsPerPage = 7; // You can adjust this as necessary

  const indexOfLastClient = currentPage * clientsPerPage;
  const indexOfFirstClient = indexOfLastClient - clientsPerPage;
  const currentFilteredClients = filteredClients.slice(indexOfFirstClient, indexOfLastClient);

  const handleNext = () => {
    const totalPages = Math.ceil(filteredClients.length / clientsPerPage);
    setCurrentPage(prevPage => (prevPage < totalPages ? prevPage + 1 : prevPage));
  };

  const handlePrevious = () => {
    setCurrentPage(prevPage => (prevPage > 1 ? prevPage - 1 : prevPage));
  };

  useEffect(() => {
    if (activeTab === 'Clients') {
      fetchClients();
    } else if (activeTab === 'AddClients') {
      setSuccessMessage('');
    }
  }, [activeTab]);

  useEffect(() => {
    filterClients();
  }, [filterQuery, searchBy, clients]);

  const fetchClients = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/clients`, {
        headers: await getAuthHeaders()
      });
      const data = await response.json();
      const sortedClients = data.sort((a, b) => a.ClientID.localeCompare(b.ClientID));
      setClients(sortedClients);
    } catch (error) {
      console.log('Error fetching clients:', error);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewClient((prevClient) => ({
      ...prevClient,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/clients`, {
        method: 'POST',
        headers: await getAuthHeaders(),
        body: JSON.stringify(newClient),
      });

      if (response.ok) {
        setSuccessMessage('Client successfully added');
        setNewClient({
          FirstName: '',
          LastName: '',
          Company: '',
          Email: '',
          Phone: '',
          Address: '',
        });
        setTimeout(() => {
          setSuccessMessage('');
        }, 3000);
        fetchClients(); // Refresh clients list after adding new client
      } else {
        console.log('Failed to add a new client:', response.statusText);
      }
    } catch (error) {
      console.log('Error adding a new client:', error);
    }
  };

  const handleSearchByChange = (event) => {
    setSearchBy(event.target.value);
  };

  const handleSearchChange = (event) => {
    setFilterQuery(event.target.value);
  };

  const filterBy = (column) => {
    const sortedClients = [...clients];

    sortedClients.sort((a, b) => {
      if (column === 'FirstName' || column === 'LastName' || column === 'Company' || column === 'Email' || column === 'Address') {
        return a[column].toLowerCase().localeCompare(b[column].toLowerCase());
      } else if (column === 'Phone') {
        return a[column].localeCompare(b[column]);
      }
      return 0;
    });

    setClients(sortedClients);
  };

  const highlightText = (text, query) => {
    if (!query) return text;

    const regex = new RegExp(`(${query})`, 'gi');
    return text.replace(regex, '<mark>$1</mark>');
  };

  const filterClients = debounce(() => {
    const filtered = clients.filter((client) => {
      if (searchBy === 'FirstName') {
        return client.FirstName.toLowerCase().includes(filterQuery.toLowerCase());
      } else if (searchBy === 'LastName') {
        return client.LastName.toLowerCase().includes(filterQuery.toLowerCase());
      } else if (searchBy === 'Company') {
        return client.Company.toLowerCase().includes(filterQuery.toLowerCase());
      } else if (searchBy === 'Email') {
        return client.Email.toLowerCase().includes(filterQuery.toLowerCase());
      } else if (searchBy === 'Phone') {
        return client.Phone.includes(filterQuery);
      } else if (searchBy === 'Address') {
        return client.Address.toLowerCase().includes(filterQuery.toLowerCase());
      }
      return true;
    });
    setFilteredClients(filtered);
  }, 300);

  return (
    <div className="table-container">
      <div className="tabs-container">
        <button
          className={`tab ${activeTab === 'Clients' ? 'active-tab' : ''}`}
          onClick={() => setActiveTab('Clients')}
        >
          Clients
        </button>
        <button
          className={`tab ${activeTab === 'AddClients' ? 'active-tab' : ''}`}
          onClick={() => setActiveTab('AddClients')}
        >
          Add Client
        </button>
      </div>
      {activeTab === 'Clients' && (
        <>
          <div className="add-search-sort-container">
          <div className="search-sort-container"></div>
            <div className="search-sort-container">
            <div style={{ marginBottom: '9px'}}> <ReportingClient /> </div>
            
              <div>
              <input type="text" placeholder="Search" value={filterQuery} onChange={handleSearchChange} />
              </div>
              <div className="MD-button-container" style={{marginBottom: '25px'}}>
              <select value={searchBy} onChange={handleSearchByChange}>
                <option value="">Filter</option>
                <option value="FirstName">First Name</option>
                <option value="LastName">Last Name</option>
                <option value="Company">Company</option>
                <option value="Email">Email</option>
                <option value="Phone">Phone</option>
                <option value="Address">Address</option>
              </select>
              </div>
            </div>
          </div>
          <table className="matter-table">
            <thead>
              <tr>
                <th>
                  <button onClick={() => filterBy(searchBy === 'ClientID' ? '' : 'ClientID')} className="matter-link">Client ID</button>
                </th>
                <th>
                  <button onClick={() => filterBy(searchBy === 'FirstName' ? '' : 'FirstName')} className="matter-link">First Name</button>
                </th>
                <th>
                  <button onClick={() => filterBy(searchBy === 'LastName' ? '' : 'LastName')} className="matter-link">Last Name</button>
                </th>
                <th>
                  <button onClick={() => filterBy(searchBy === 'Company' ? '' : 'Company')} className="matter-link">Company</button>
                </th>
                <th>
                  <button onClick={() => filterBy(searchBy === 'Email' ? '' : 'Email')} className="matter-link">Email</button>
                </th>
                <th>
                  <button onClick={() => filterBy(searchBy === 'Phone' ? '' : 'Phone')} className="matter-link">Phone</button>
                </th>
                <th>
                  <button onClick={() => filterBy(searchBy === 'Address' ? '' : 'Address')} className="matter-link">Address</button>
                </th>
              </tr>
            </thead>
            <tbody>
              {currentFilteredClients.length > 0 ? (
                currentFilteredClients.map((client) => (
                  <tr key={client.ClientID}>
                    <td>
                      <Link to={`/clients/${client.ClientID}`}>
                        {client.ClientID}
                      </Link>
                    </td>
                    <td>{client.FirstName}</td>
                    <td>{client.LastName}</td>
                    <td>{client.Company}</td>
                    <td>{client.Email}</td>
                    <td>{client.Phone}</td>
                    <td>{client.Address}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7">No clients found</td>
                </tr>
              )}
            </tbody>
            <div className="pagination">
            <button onClick={handlePrevious}>Previous</button>
            <span>{currentPage}</span>
            <button onClick={handleNext}>Next</button>
          </div>
          </table>
        
        </>
      )}
      {activeTab === 'AddClients' && (
        <form onSubmit={handleSubmit}>
          <label htmlFor="firstName">First Name:</label>
          <input
            type="text"
            id="firstName"
            name="FirstName"
            value={newClient.FirstName}
            onChange={handleInputChange}
            required
          />

          <label htmlFor="lastName">Last Name:</label>
          <input
            type="text"
            id="lastName"
            name="LastName"
            value={newClient.LastName}
            onChange={handleInputChange}
          />

          <label htmlFor="company">Company:</label>
          <input
            type="text"
            id="company"
            name="Company"
            value={newClient.Company}
            onChange={handleInputChange}
          />

          <label htmlFor="email">Email:</label>
          <input
            type="text"
            id="email"
            name="Email"
            value={newClient.Email}
            onChange={handleInputChange}
            required
          />

          <label htmlFor="phone">Phone:</label>
          <input
            type="text"
            id="phone"
            name="Phone"
            value={newClient.Phone}
            onChange={handleInputChange}
          />

          <label htmlFor="address">Address:</label>
          <input
            type="text"
            id="address"
            name="Address"
            value={newClient.Address}
            onChange={handleInputChange}
          />

          <button type="submit">Add Client</button>
          {successMessage && <SuccessMessage message={successMessage} />}
        </form>
      )}
      <div className="add-task-form"></div>
    </div>
  );
};

export default Clients;


