import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import '../styles/General.css';
import getAuthHeaders from '../functions/getAuthHeaders';

const ClientMattersTab = () => {
    const { clientId } = useParams();
    const [matters, setMatters] = useState([]);

    const fetchClientMatters = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/clientmatters/${clientId}`, {
            headers: await getAuthHeaders()
          });
          if (response.ok) {
            const data = await response.json();
            setMatters(data);
          } else {
            throw new Error('Failed to fetch client matters');
          }
        } catch (error) {
          console.log('Error fetching client matters: ' + error);
        }
      };

    useEffect(() => {
        fetchClientMatters();
    }, [clientId]);

    return (
        <div>
            <h3>Client Matters</h3>
            <table className="matter-table">
                <thead>
                    <tr>
                        <th>Matter Name</th>
                        <th>Matter ID</th>
                        <th>Leader</th>
                        <th>Status</th>
                        <th>Open Date</th>
                        <th>Close Date</th>
                    </tr>
                </thead>
                <tbody>
                    {matters.map((matter) => (
                        <tr key={matter.matterid}>
                            <td>
                                <Link to={`/matters/${matter.matterid}`}>
                                    {matter.mattername}
                                </Link>
                            </td>
                            <td>{matter.matterid}</td>
                            <td>{matter.employeename}</td>
                            <td>{matter.status}</td>
                            <td>{matter.opendate}</td>
                            <td>{matter.closedate}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ClientMattersTab;
