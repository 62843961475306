import React, { useState, useEffect } from 'react';
import '../styles/SuccessMessage.css'

const SuccessMessage = ({ message }) => {
    const [visible, setVisible] = useState(true);
    useEffect(() => {
        const timer = setTimeout(() => {
            setVisible(false);
        }, 2500);

        return () => clearTimeout(timer);
    }, []);
    return (
        <>
            {visible && (
                <div className="success-message">
                    {message}
                </div>
            )}
        </>
    )
};
export default SuccessMessage;