import React, { useEffect } from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

function ClientPayments() {
  useEffect(() => {
    let root = am5.Root.new("clientPaymentsChartdiv");

    root.setThemes([
      am5themes_Animated.new(root)
    ]);

    let chart = root.container.children.push(am5xy.XYChart.new(root, {
      layout: root.verticalLayout
    }));

    // Client payments data with action items
    let data = [
      { category: "January", research: 5000, consulting: 3000, implementation: 2000 },
      { category: "February", research: 6000, consulting: 4000, implementation: 2500 },
      { category: "March", research: 7000, consulting: 5000, implementation: 3000 },
      { category: "April", research: 8000, consulting: 6000, implementation: 3500 },
      { category: "May", research: 9000, consulting: 7000, implementation: 4000 },
      { category: "June", research: 10000, consulting: 8000, implementation: 4500 }
    ];

    // Create X-axis
    let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
      categoryField: "category",
      renderer: am5xy.AxisRendererX.new(root, {})
    }));
    xAxis.data.setAll(data);

    // Create Y-axis
    let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      renderer: am5xy.AxisRendererY.new(root, {})
    }));

    // Create series for Research
    let series1 = chart.series.push(am5xy.ColumnSeries.new(root, {
      name: "Research",
      stacked: true,
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: "research",
      categoryXField: "category",
      tooltip: am5.Tooltip.new(root, {
        labelText: "{valueY}"
      })
    }));

    // Create series for Consulting
    let series2 = chart.series.push(am5xy.ColumnSeries.new(root, {
      name: "Consulting",
      stacked: true,
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: "consulting",
      categoryXField: "category",
      tooltip: am5.Tooltip.new(root, {
        labelText: "{valueY}"
      })
    }));

    // Create series for Implementation
    let series3 = chart.series.push(am5xy.ColumnSeries.new(root, {
      name: "Implementation",
      stacked: true,
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: "implementation",
      categoryXField: "category",
      tooltip: am5.Tooltip.new(root, {
        labelText: "{valueY}"
      })
    }));

    series1.data.setAll(data);
    series2.data.setAll(data);
    series3.data.setAll(data);

    // Add cursor
    chart.set("cursor", am5xy.XYCursor.new(root, {}));

    // Cleanup
    return () => {
      root.dispose();
    };
  }, []);

  return (
    <div className="box open-matters-widget">
      <h2>Client Payments</h2>
      <div id="clientPaymentsChartdiv" style={{ width: "100%", height: "500px" }}></div>
    </div>
  );
}

export default ClientPayments;

