import React, { useEffect } from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

function Disbursements() {
  useEffect(() => {
    let root = am5.Root.new("disbursementsChartdiv");

    root.setThemes([
      am5themes_Animated.new(root)
    ]);

    let chart = root.container.children.push(am5percent.PieChart.new(root, {}));

    // Disbursements data
    let data = [
      { category: "Salaries", value: 40000 },
      { category: "Rent", value: 10000 },
      { category: "Utilities", value: 5000 },
      { category: "Office Supplies", value: 3000 },
      { category: "Travel Expenses", value: 7000 }
    ];

    // Create series
    let series = chart.series.push(am5percent.PieSeries.new(root, {
      valueField: "value",
      categoryField: "category",
      tooltip: am5.Tooltip.new(root, {
        labelText: "{category}: {value}"
      })
    }));

    series.data.setAll(data);

    // Add legend
    chart.children.push(am5.Legend.new(root, {
      centerX: am5.p50,
      x: am5.p50,
      centerY: am5.p100,
      y: am5.p100,
      layout: root.horizontalLayout
    }));

    // Cleanup
    return () => {
      root.dispose();
    };
  }, []);

  return (
    <div className="box open-matters-widget">
      <h2>Disbursements</h2>
      <div id="disbursementsChartdiv" style={{ width: "100%", height: "500px" }}></div>
    </div>
  );
};

export default Disbursements;
