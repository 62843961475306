import React, { useEffect, useRef } from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

function Revenue() {
  const chartRef = useRef(null);

  useEffect(() => {
    const root = am5.Root.new(chartRef.current);

    root.setThemes([
      am5themes_Animated.new(root)
    ]);

    let chart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: true,
      panY: true,
      wheelX: "panX",
      wheelY: "zoomX",
      layout: root.verticalLayout
    }));

    let generateData = () => {
      return [
        { date: new Date(2023, 0, 1).getTime(), value: 12000 },
        { date: new Date(2023, 1, 1).getTime(), value: 15000 },
        { date: new Date(2023, 2, 1).getTime(), value: 13000 },
        { date: new Date(2023, 3, 1).getTime(), value: 16000 },
        { date: new Date(2023, 4, 1).getTime(), value: 14000 },
        { date: new Date(2023, 5, 1).getTime(), value: 17000 },
        { date: new Date(2023, 6, 1).getTime(), value: 18000 },
        { date: new Date(2023, 7, 1).getTime(), value: 20000 },
        { date: new Date(2023, 8, 1).getTime(), value: 22000 },
        { date: new Date(2023, 9, 1).getTime(), value: 24000 },
        { date: new Date(2023, 10, 1).getTime(), value: 23000 },
        { date: new Date(2023, 11, 1).getTime(), value: 25000 }
      ];
    };

    let data = generateData();

    // Create X-axis
    let xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
      baseInterval: { timeUnit: "month", count: 1 },
      renderer: am5xy.AxisRendererX.new(root, {}),
      tooltip: am5.Tooltip.new(root, {})
    }));

    // Create Y-axis
    let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      renderer: am5xy.AxisRendererY.new(root, {})
    }));

    // Add series
    let series = chart.series.push(am5xy.LineSeries.new(root, {
      name: "Revenue",
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: "value",
      valueXField: "date",
      tooltip: am5.Tooltip.new(root, {
        labelText: "{valueY}"
      })
    }));

    series.data.setAll(data);

    // Add cursor
    chart.set("cursor", am5xy.XYCursor.new(root, {}));

    // Add scrollbar
    chart.set("scrollbarX", am5.Scrollbar.new(root, { orientation: "horizontal" }));

    // Cleanup
    return () => {
      root.dispose();
    };
  }, []);

  return (
    <div className="box open-matters-widget">
      <h2>Yearly Revenue</h2>
      <div ref={chartRef} id="chartdiv" style={{ width: "100%", height: "500px" }}></div>
    </div>
  );
}

export default Revenue;
