import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FaTimes } from 'react-icons/fa';
import { useMsal } from '@azure/msal-react';
import OpenMattersWidget from './Widgets/OpenMattersWidget';
import CurrentActionsWidget from './Widgets/CurrentActionsWidget';
import ClientsDispursed from './Widgets/ClientsDispursed';
import TasksWidget from './Widgets/TasksWidget';
import BarGraph from './Widgets/BarGraph'; // Import the BarGraph component
import getAuthHeaders from './functions/getAuthHeaders';
import './styles/Home.css';
import './styles/General.css';

const WidgetControlButtons = ({ addWidget, toggleOptions, showOptions }) => (
  <div className="widget-controls">
    <button className="button-with-icon" onClick={toggleOptions}>
      <FontAwesomeIcon icon={faPencilAlt} />
    </button>
    {showOptions && (
      <div className="widget-controls">
        <button onClick={() => addWidget('OpenMattersWidget')}>Open Matters</button>
        <button onClick={() => addWidget('CurrentActionsWidget')}>Financial Spend</button>
        <button onClick={() => addWidget('ClientsDispursed')}>Clients</button>
        <button onClick={() => addWidget('TasksWidget')}>Weekly Checklist Status</button>
        <button onClick={() => addWidget('BarGraph')}>Task Status Graph</button>
      </div>
    )}
  </div>
);

const Home = ({ searchQuery }) => {
  const [activeWidgets, setActiveWidgets] = useState(['OpenMattersWidget', 'CurrentActionsWidget']);
  const [showOptions, setShowOptions] = useState(false);
  const { accounts } = useMsal();

  useEffect(() => {
    checkCurrentUserDetails();
    loadSettings();
  }, []);

  const checkCurrentUserDetails = async () => {
    try {
      const account = accounts[0];
      const fullName = account.name;
      const email = account.username;
      const { oid } = account.idTokenClaims;

      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/check_user?cognitoSub=${oid}`, {
        method: 'GET',
        headers: await getAuthHeaders(),
      });
      const data = await response.json();
      if (!data.exists) {
        const [firstName, lastName] = fullName.split(' ');
        await fetch(`${process.env.REACT_APP_API_BASE_URL}/create_employee`, {
          method: 'POST',
          headers: await getAuthHeaders(),
          body: JSON.stringify({
            employeeId: oid,
            email: email,
            firstName: firstName,
            lastName: lastName,
          }),
        });
      }
    } catch (error) {
      console.log('Error checking current user details:', error);
    }
  };

  const addWidget = (widgetName) => {
    setActiveWidgets((prevWidgets) => {
      const updatedWidgets = [...prevWidgets, widgetName];
      localStorage.setItem('activeWidgets', JSON.stringify(updatedWidgets));
      return updatedWidgets;
    });
  };

  const removeWidget = (widgetName) => {
    setActiveWidgets((prevWidgets) => {
      const updatedWidgets = prevWidgets.filter(name => name !== widgetName);
      localStorage.setItem('activeWidgets', JSON.stringify(updatedWidgets));
      return updatedWidgets;
    });
  };

  const toggleOptions = () => {
    setShowOptions(!showOptions);
  };

  const loadSettings = () => {
    const savedWidgets = localStorage.getItem('activeWidgets');
    if (savedWidgets) {
      setActiveWidgets(JSON.parse(savedWidgets));
    }
  };

  return (
    <div className="Home_main">
      <WidgetControlButtons addWidget={addWidget} toggleOptions={toggleOptions} showOptions={showOptions} />
      <div className="left-widgets">
        {activeWidgets.includes('OpenMattersWidget') && (
          <div className="widget">
            <OpenMattersWidget searchQuery={searchQuery} />
            {showOptions && (
              <button onClick={() => removeWidget('OpenMattersWidget')} className="delete-button">
                <div>
                  <FaTimes />
                </div>
              </button>
            )}
          </div>
        )}
        {activeWidgets.includes('TasksWidget') && (
          <div className="widget">
            <TasksWidget searchQuery={searchQuery} />
            {showOptions && (
              <button onClick={() => removeWidget('TasksWidget')} className="delete-button">
                <div>
                  <FaTimes />
                </div>
              </button>
            )}
          </div>
        )}
      </div>
      <div className="right-widgets">
        <div className="top-right-widgets">
          {activeWidgets.includes('ClientsDispursed') && (
            <div className="widget" style={{ width: '50%' }}>
              <ClientsDispursed searchQuery={searchQuery} />
              {showOptions && (
                <button onClick={() => removeWidget('ClientsDispursed')} className="delete-button">
                  <div>
                    <FaTimes />
                  </div>
                </button>
              )}
            </div>
          )}
          {activeWidgets.includes('BarGraph') && (
            <div className="widget" style={{ width: '50%' }}>
              <BarGraph />
              {showOptions && (
                <button onClick={() => removeWidget('BarGraph')} className="delete-button">
                  <div>
                    <FaTimes />
                  </div>
                </button>
              )}
            </div>
          )}
        </div>
        {activeWidgets.includes('CurrentActionsWidget') && (
          <div className="widget">
            <CurrentActionsWidget searchQuery={searchQuery} />
            {showOptions && (
              <button onClick={() => removeWidget('CurrentActionsWidget')} className="delete-button">
                <div>
                  <FaTimes />
                </div>
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Home;
