import React, { useEffect, useState } from 'react';
import './styles/General.css';
import './styles/Profile.css';
import getAuthHeaders from './functions/getAuthHeaders.js';
import { useMsal } from '@azure/msal-react';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Templates from './Templates';

const Profile = () => { 
  const { accounts } = useMsal();
  const [employee, setEmployee] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [newBilledRate, setNewBilledRate] = useState('');
  const [deadlineRules, setDeadlineRules] = useState([]);
  const [newRule, setNewRule] = useState({ dateField: '', duration: '', durationType: 'months', matterType: '', country: '', internalExternal: '' });
  
  // State for active tab
  const [activeTab, setActiveTab] = useState('deadlineRules'); // Default tab

  useEffect(() => {
    fetchEmployee();
    fetchDeadlineRulesTable();
  }, []);

  const fetchEmployee = async () => {
    try {
      const { oid } = accounts[0].idTokenClaims;
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/getemployee/${oid}`, {
        headers: await getAuthHeaders()
      });
      const data = await response.json();
      setEmployee(data);
    } catch (error) {
      console.log('Error fetching employee data:', error);
    }
  };

  const handleUpdateEmployee = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/employee/${employee.employeeid}`, {
        method: 'PUT',
        headers: await getAuthHeaders(),
        body: JSON.stringify({ billedrate: newBilledRate })
      });
      if (response.ok) {
        setEmployee({ ...employee, billedrate: newBilledRate });
        setEditMode(false);
      } else {
        console.error('Failed to update billed rate');
      }
    } catch (error) {
      console.log('Error updating billed rate:', error);
    }
  };

  const fetchDeadlineRulesTable = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/deadline-rules`, {
        headers: await getAuthHeaders()
      });
      const data = await response.json();
      console.log('Fetched deadline rules:', data);
      setDeadlineRules(data);
    } catch (error) {
      console.log('Error fetching deadline rules:', error);
    }
  };

  const handleSaveDeadlineRuleToTable = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/save_deadline_rule`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...await getAuthHeaders()
        },
        body: JSON.stringify(newRule)
      });
      if (response.ok) {
        fetchDeadlineRulesTable(); // Refresh rules after saving
        setNewRule({ dateField: '', duration: '', durationType: 'months', matterType: '', country: '', internalExternal: '' });
      } else {
        console.error('Failed to save the deadline rule to the deadline_rules table');
      }
    } catch (error) {
      console.log('Error saving the deadline rule to the deadline_rules table:', error);
    }
  };

  const handleDeleteDeadlineRule = async (ruleId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/delete_deadline_rule/${ruleId}`, {
        method: 'DELETE',
        headers: await getAuthHeaders()
      });
      if (response.ok) {
        fetchDeadlineRulesTable(); // Refresh the rules after deletion
      } else {
        console.error('Failed to delete the deadline rule');
      }
    } catch (error) {
      console.log('Error deleting the deadline rule:', error);
    }
  };

  return (
    <div className="profile-container">
      <div>
        <div className="profile-popout">
          <div className="profile-header-with-button">
            <h3 style={{ fontSize: '21px' }}>{employee.firstname} {employee.lastname}</h3>       
          </div>
          <h1 className="profile-about-line"></h1>
          <div className="profile-info">
            <div className="profile-info-item"><strong>Email: {employee.email}</strong></div>
            <div className="profile-info-item"><strong>Phone: {employee.phone}</strong></div>
            {editMode ? (
              <form onSubmit={handleUpdateEmployee}>
                <label htmlFor="newBilledRate">Billed Rate ($/hr): </label>
                <input 
                  type="number"
                  id="newBilledRate"
                  name="newBilledRate"
                  value={newBilledRate}
                  placeholder={employee.billedrate}
                  onChange={(e) => setNewBilledRate(e.target.value)}
                  required
                  min="0"
                />
                <button type="submit">Save</button>
                <button type="button" onClick={() => setEditMode(false)}>Cancel</button>
              </form>
            ) : (
              <p>
                <div className="profile-bR-with-button">
                  <button onClick={() => setEditMode(true)}>
                    <FontAwesomeIcon icon={faEdit} style={{ fontSize: '15px' }} />
                  </button>
                  <strong> ($/hr): {employee.billedrate}</strong>
                </div> 
              </p>
            )}
          </div>
          <div className="tabs-container">
            <button onClick={() => setActiveTab('deadlineRules')} className= {`tab ${activeTab === 'deadlineRules' ? 'active-tab' : ''}`}>Manage Deadline Rules</button>
{/*            
            <button onClick={() => setActiveTab('taskRules')} className={activeTab === 'taskRules' ? 'active-tab' : ''}>Manage Task Rules</button> */}
            <button onClick={() => setActiveTab('templates')} className={`tab ${activeTab === 'templates' ? 'active-tab' : ''}`}>Templates</button>
          </div>

          {/* Conditional rendering of tab content */}
          {activeTab === 'deadlineRules' && (
            <div className="deadline-rules">
              <h2>Manage Deadline Rules</h2>
              <form className="deadline-form" onSubmit={handleSaveDeadlineRuleToTable}>
                <label>Select Date Field:</label>
                <select
                  value={newRule.dateField}
                  onChange={(e) => setNewRule({ ...newRule, dateField: e.target.value })}
                  required
                >
                  <option value="">Select Field</option>
                  <option value="opendate">Open Date</option>
                  <option value="expiry_date">Expiry Date</option>
                  <option value="office_action_date">Office Action Date</option>
                  <option value="application_date">Application Date</option>
                  <option value="registration_date">Registration Date</option>
                  <option value="publication_date">Publication Date</option>
                </select>

                <label>Duration:</label>
                <input
                  type="text"
                  value={newRule.duration}
                  onChange={(e) => setNewRule({ ...newRule, duration: e.target.value })}
                  required
                  min="1"
                  placeholder="Enter Number"
                />

                <label>Duration Type:</label>
                <select
                  value={newRule.durationType}
                  onChange={(e) => setNewRule({ ...newRule, durationType: e.target.value })}
                >
                  <option value="days">Days</option>
                  <option value="weeks">Weeks</option>
                  <option value="months">Months</option>
                </select>

                <label>Matter Type:</label>
                <select
                  value={newRule.matterType}
                  onChange={(e) => setNewRule({ ...newRule, matterType: e.target.value })}
                >
                  <option value="">Select Type</option>
                  <option value="Trademark">Trademark</option>
                  <option value="Patent">Patent</option>
                  <option value="Strategy">Strategy</option>
                  <option value="Design">Design</option>
                  <option value="Copyright">Copyright</option>
                  <option value="Commercial">Commercial</option>
                  <option value="Cautionary_Notice">Cautionary Notice</option>
                  <option value="Utility_Model">Utility Model</option>
                </select>
        
                <label>Country:</label>
                <input
                  type="text"
                  value={newRule.country}
                  onChange={(e) => setNewRule({ ...newRule, country: e.target.value })}
                  placeholder="Enter Country"
                />

                <label>Internal/External:</label>
                <select
                  value={newRule.internalExternal}
                  onChange={(e) => setNewRule({ ...newRule, internalExternal: e.target.value })}
                >
                  <option value="">Select Type</option>
                  <option value="internal">Internal</option>
                  <option value="external">External</option>
                </select>

                <button type="submit">Save Rule</button>
              </form>

              <div className="rules-list">
                <h3>Existing Rules</h3>
                <ul>
                  {deadlineRules.map((rule) => (
                    <li key={rule.rule_id}>
                      {rule.date_field} - {rule.duration} {rule.duration_type} 
                      {rule.matter_type && `, Matter Type: ${rule.matter_type}`} 
                      {rule.country && `, Country: ${rule.country}`} 
                      {rule.internal_external && `, Type: ${rule.internal_external}`} 
                      <button onClick={() => handleDeleteDeadlineRule(rule.rule_id)}>
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </button>
                    </li>
                  ))}
                </ul>
              </div>    
            </div>
          )}

          {activeTab === 'taskRules' && (
            <div>
              <h2>Manage Task Rules</h2>
              <p>This section is under construction.</p>
            </div>
          )}

          {activeTab === 'templates' && (
            <div>
              <h2>Templates</h2>
              <Templates />               
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Profile;
