import React, { useState, useEffect } from 'react';
import ReactModal from 'react-modal';
import axios from 'axios';
import NotificationSettings from './Notifications/NotificationSettings';
import './styles/General.css';
import './styles/Matter.css';
import './styles/Notifications.css';
import getAuthHeaders from './functions/getAuthHeaders';

const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [searchBy, setSearchBy] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [activeTab, setActiveTab] = useState('Notifications');
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [notificationsPerPage] = useState(7);  // Set to 7 notifications per page
  const userId = 1; // replace with actual user id

  useEffect(() => {
    fetchNotifications();
  }, []);

  const fetchNotifications = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/notifications`, {
        headers: await getAuthHeaders()
      });
      const data = await response.json();
      if (Array.isArray(data)) {
        setNotifications(data);
      } else {
        console.error('Expected an array but received:', data);
      }
    } catch (error) {
      console.log('Error fetching notifications:', error);
    }
  };

  const handleNotificationClick = async (notificationId) => {
    const notification = notifications.find(n => n.notificationid === notificationId);
    if (!notification) {
      console.error('Notification not found');
      return;
    }

    setSelectedNotification(notification);

    try {
      const response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/update-notification/${notificationId}`, null, {
        headers: await getAuthHeaders()
      });

      if (response.status === 200) {
        const updatedNotifications = notifications.map(n => {
          if (n.notificationid === notificationId) {
            return { ...n, is_read: true };
          }
          return n;
        });
        setNotifications(updatedNotifications);
      }
    } catch (error) {
      console.error('Error updating notification:', error);
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredNotifications = notifications.filter((notification) => {
    const searchLower = searchQuery.toLowerCase();
    if (searchBy === 'subject') {
      return notification.subject.toLowerCase().includes(searchLower);
    } else if (searchBy === 'description') {
      return notification.description.toLowerCase().includes(searchLower);
    } else if (searchBy === 'taskid') {
      return notification.taskid.toLowerCase().includes(searchLower);
    } else if (searchBy === 'startdate') {
      return notification.startdate.toLowerCase().includes(searchLower);
    } else if (searchBy === 'enddate') {
      return notification.enddate.toLowerCase().includes(searchLower);
    }
    return true;
  });

  const indexOfLastNotification = currentPage * notificationsPerPage;
  const indexOfFirstNotification = indexOfLastNotification - notificationsPerPage;
  const currentNotifications = filteredNotifications.slice(indexOfFirstNotification, indexOfLastNotification);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredNotifications.length / notificationsPerPage); i++) {
    pageNumbers.push(i);
  }

  const renderPageNumbers = pageNumbers
    .filter(number => number >= currentPage - 1 && number <= currentPage + 1)
    .map(number => (
      <li
        key={number}
        onClick={() => setCurrentPage(number)}
        style={{ cursor: 'pointer', padding: '5px' }}>
        {number}
      </li>
  ));

  const handleNext = () => {
    setCurrentPage(prevPage => (prevPage < pageNumbers.length ? prevPage + 1 : prevPage));
  };

  const handlePrevious = () => {
    setCurrentPage(prevPage => (prevPage > 1 ? prevPage - 1 : prevPage));
  };

  const truncateDescription = (description) => {
    const words = description.split(' ');
    if (words.length > 3) {
      return words.slice(0, 3).join(' ') + '...';
    }
    return description;
  };

  return (
    <div className="backdrop">
      <div className="Add-matter-Matters-container"></div>
      <div className="tabs-container">
        <button
          className={`tab ${activeTab === 'Notifications' ? 'active-tab' : ''}`}
          onClick={() => setActiveTab('Notifications')}
        >
          Notifications
        </button>
      </div>

      {activeTab === 'Notifications' && (
        <div className="table-container">
          <div className="add-search-sort-container">
            <div className="search-sort-container"></div>
            <div className="search-sort-container">
              <div>
                <input type="text" placeholder="Search" value={searchQuery} onChange={handleSearchChange} />
              </div>
              <div className="MD-button-container">
                <select value={searchBy} onChange={(e) => setSearchBy(e.target.value)}>
                  <option value="">Search By</option>
                  <option value="subject">Subject</option>
                  <option value="description">Description</option>
                  <option value="taskid">Task ID</option>
                  <option value="startdate">Start Date</option>
                  <option value="enddate">End Date</option>
                </select>
              </div>
            </div>
            {/* <div className="button-container">
              <button onClick={() => setIsSettingsOpen(true)}>Settings</button>
            </div> */}
          </div>

          <table className="matter-table">
            <thead>
              <tr>
                <th>Subject</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              {currentNotifications.map((notification) => (
                <tr
                  key={notification.notificationid}
                  onClick={() => handleNotificationClick(notification.notificationid)}
                  className={`notification-row ${notification.is_read ? "read-notification" : "unread-notification"}`}
                >
                  <td><span className="circle-indicator"></span> {notification.subject}</td>
                  <td>{truncateDescription(notification.description)}</td>
                </tr>
              ))}
            </tbody>
            <div className="pagination">
            <button onClick={handlePrevious} disabled={currentPage === 1}>
              Previous
            </button>
            <ul className="pagination-numbers">
              {renderPageNumbers}
            </ul>
            <button onClick={handleNext} disabled={currentPage === pageNumbers.length}>
              Next
            </button>
          </div>
          </table>
         
          <div className={`side-banner ${selectedNotification ? 'visible' : ''}`}>
            <div className="side-banner-header">
              <h3>Notification Details</h3>
              <button className="side-banner-button" onClick={() => setSelectedNotification(null)}>Close</button>
            </div>

            {selectedNotification && (
              <div className='notification-details-container'>
                <p><strong>ID:</strong> {selectedNotification.notificationid}</p>
                <p><strong>Description:</strong> {selectedNotification.description}</p>
                <p><strong>Created At:</strong> {selectedNotification.created_at}</p>
              </div>
            )}
          </div>
        </div>
      )}
      
      {isSettingsOpen && (
        <ReactModal isOpen={isSettingsOpen} onRequestClose={() => setIsSettingsOpen(false)}>
          <NotificationSettings userId={userId} onClose={() => setIsSettingsOpen(false)} />
        </ReactModal>
      )}
    </div>
  );
};

export default Notifications;
