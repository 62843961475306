import React, { useEffect } from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

function Equity() {
  useEffect(() => {
    let root = am5.Root.new("equityChartdiv");

    root.setThemes([
      am5themes_Animated.new(root)
    ]);

    let chart = root.container.children.push(am5xy.XYChart.new(root, {
      layout: root.verticalLayout
    }));

    // Total equity data
    let data = [
      { category: "Common Stock", value: 50000 },
      { category: "Preferred Stock", value: 20000 },
      { category: "Retained Earnings", value: 30000 },
      { category: "Additional Paid-in Capital", value: 15000 }
    ];

    // Create X-axis
    let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
      categoryField: "category",
      renderer: am5xy.AxisRendererX.new(root, {})
    }));
    xAxis.data.setAll(data);

    // Create Y-axis
    let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      renderer: am5xy.AxisRendererY.new(root, {})
    }));

    // Create series
    let series = chart.series.push(am5xy.ColumnSeries.new(root, {
      name: "Total Equity",
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: "value",
      categoryXField: "category",
      tooltip: am5.Tooltip.new(root, {
        labelText: "{valueY}"
      })
    }));

    series.data.setAll(data);

    // Add cursor
    chart.set("cursor", am5xy.XYCursor.new(root, {}));

    // Cleanup
    return () => {
      root.dispose();
    };
  }, []);

  return (
    <div className="box open-matters-widget">
      <h2>Total Equity</h2>
      <div id="equityChartdiv" style={{ width: "100%", height: "500px" }}></div>
    </div>
  );
}

export default Equity;

