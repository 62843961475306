import React, { useState, useRef, useEffect } from 'react'; // Import useState, useRef, useEffect
import '../styles/General.css';
import getAuthHeaders from '../functions/getAuthHeaders';
import { Auth } from 'aws-amplify';

const columnOptions = [
    'Task ID', 'Matter ID', 'Task Title', 'Description', 'Due Date', 'Assigned To',
    'Status', 'Created By', 'Task Type'
];

const Reporting_MyTasks = () => { // Assuming cognitoSub is passed as a prop
    const [selectedColumns, setSelectedColumns] = useState(columnOptions); // Initialize with all columns
    const [isModalOpen, setIsModalOpen] = useState(false);
    const editButtonRef = useRef(null);

    useEffect(() => {
        if (isModalOpen) {
            document.body.style.overflow = 'hidden';  // Disable scroll on body
        } else {
            document.body.style.overflow = 'auto';    // Enable scroll
        }
    }, [isModalOpen]);

    const handleColumnChange = (column) => {
        const newSelection = selectedColumns.includes(column)
            ? selectedColumns.filter(c => c !== column)
            : [...selectedColumns, column];
        setSelectedColumns(newSelection);
    };

    const toggleAllColumns = () => {
        if (selectedColumns.length === columnOptions.length) {
            setSelectedColumns([]); // Deselect all
        } else {
            setSelectedColumns(columnOptions); // Select all
        }
    };

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const downloadReport = async () => {
        try {
            const user = await Auth.currentAuthenticatedUser();
            const cognitoSub = user.attributes.sub;
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/mytasks/excel/${cognitoSub}`, {
                method: 'POST',
                headers: await getAuthHeaders(), 
                body: JSON.stringify({
                    cols: selectedColumns
                }),
            });
    
            if (!response.ok) {
                throw new Error(`Failed to download report: ${response.statusText}`);
            }
    
            const blob = await response.blob();
            const downloadUrl = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = downloadUrl;
            a.download = "MyTasksReport.xlsx";
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(downloadUrl);
            a.remove();
        } catch (error) {
            console.error('Error downloading report:', error);
        }
    };

    return (
        <div>
            <button ref={editButtonRef} onClick={toggleModal} className="MD-edit-edit-matter-button">Export</button>
            {isModalOpen && (
                <div className="reporting-modal-overlay">
                    <div className="reporting-modal">
                            <button className="close-button" onClick={toggleModal}>&times;</button>
                        <div className="reporting-modal-content">
                            <div className="reporting-button-container">
                               <button onClick={downloadReport} className="MD-edit-edit-matter-button">Export</button>
                               <button onClick={toggleAllColumns} className="reporting-button">
                                    {selectedColumns.length === columnOptions.length ? 'Deselect All' : 'Select All'}
                               </button>
                            </div>
                            {columnOptions.map((column, idx) => (
                                <label key={idx} className="reporting-checkbox">
                                    <input
                                        type="checkbox"
                                        checked={selectedColumns.includes(column)}
                                        onChange={() => handleColumnChange(column)}
                                        className="reporting-visually-hidden"
                                    />
                                    <span className="reporting-label">{column}</span>
                                </label>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
export default Reporting_MyTasks;
