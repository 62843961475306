import React, { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import Assets from '../../Widgets/Assets';
import Equity from '../../Widgets/Equity';
import CurrentActionsWidget from '../../Widgets/CurrentActionsWidget';
import Liabilities from '../../Widgets/Liabilities';
import Revenue from '../../Widgets/Revenue'; 
import ClientPayments from '../../Widgets/ClientPayments'; 
import Disbursements from '../../Widgets/Disbursements'; 
import getAuthHeaders from '../../functions/getAuthHeaders';
import '../../styles/Home.css';
import '../../styles/General.css';

const FinacialTransactions = ({ searchQuery }) => {
  const { accounts } = useMsal();

  useEffect(() => {
    checkCurrentUserDetails();
  }, []);

  const checkCurrentUserDetails = async () => {
    try {
      const account = accounts[0];
      const fullName = account.name;
      const email = account.username;
      const { oid } = account.idTokenClaims;

      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/check_user?cognitoSub=${oid}`, {
        method: 'GET',
        headers: await getAuthHeaders(),
      });
      const data = await response.json();
      if (!data.exists) {
        const [firstName, lastName] = fullName.split(' ');
        await fetch(`${process.env.REACT_APP_API_BASE_URL}/create_employee`, {
          method: 'POST',
          headers: await getAuthHeaders(),
          body: JSON.stringify({
            employeeId: oid,
            email: email,
            firstName: firstName,
            lastName: lastName,
          }),
        });
      }
    } catch (error) {
      console.log('Error checking current user details:', error);
    }
  };

  return (
    <div className="Finacial-dashboard-container">
      <div className="Finacial-widget-group">
        <h2>Matter Transactions</h2>
        <div className="Finacial-widget-row">
          <div className="Finacial-widget">
            <CurrentActionsWidget searchQuery={searchQuery} />
          </div>
          <div className="Finacial-widget">
            <ClientPayments />
          </div>
          <div className="Finacial-widget">
            <Disbursements />
          </div>
        </div>
      </div>
      <div className="Finacial-widget-group">
        <h2>General Firm</h2>
        <div className="Finacial-widget-row">
          <div className="Finacial-widget">
            <Assets />
          </div>
          <div className="Finacial-widget">
            <Equity />
          </div>
          <div className="Finacial-widget">
            <Liabilities />
          </div>
        </div>
        <div className="Finacial-widget-row">
          <div className="Finacial-widget">
            <Revenue />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinacialTransactions;
