import React, { useEffect, useState, useRef } from "react";
import { useMsal } from "@azure/msal-react";
import * as msal from "@azure/msal-browser";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faArrowLeft, faFile, faFolderOpen, faExternalLinkAlt, faFolder } from '@fortawesome/free-solid-svg-icons';
import WebViewer from '@pdftron/webviewer';
import './styles/General.css';
import './styles/File.css';

const Templates = () => {
  const viewer = useRef(null);
  const [webViewerInstance, setWebViewerInstance] = useState(null);
  const { instance, accounts } = useMsal();
  const [accessToken, setAccessToken] = useState(null);
  const [files, setFiles] = useState([]);
  const [folders, setFolders] = useState([]);
  const [siteId, setSiteId] = useState(process.env.REACT_APP_SHAREPOINT_SITE_ID);
  const [driveId, setDriveId] = useState(process.env.REACT_APP_SHAREPOINT_SITE_DRIVE_ID);
  const [folderHistory, setFolderHistory] = useState([]);
  const [currentFolder, setCurrentFolder] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState(""); 
  const [newFolderName, setNewFolderName] = useState("");
  const [newFileName, setNewFileName] = useState("");
  const [newFileType, setNewFileType] = useState("docx");

  // Handle token acquisition
  useEffect(() => {
    if (accounts.length > 0) {
      instance
        .acquireTokenSilent({
          scopes: ["Files.Read.All"],
          account: accounts[0],
        })
        .then((response) => {
          setAccessToken(response.accessToken);
        })
        .catch((error) => {
          if (error instanceof msal.InteractionRequiredAuthError) {
            instance
              .acquireTokenPopup({
                scopes: ["Files.Read.All"],
              })
              .then((response) => {
                setAccessToken(response.accessToken);
              })
              .catch((error) => console.error("Error acquiring token:", error));
          } else {
            console.error("Error acquiring token:", error);
          }
        });
    }
  }, [instance, accounts]);

  const handleBackButtonClick = async () => {
    if (folderHistory.length > 0) {
      const updatedHistory = [...folderHistory]; // Copy the history array
      const previousFolder = updatedHistory.pop(); // Remove the last folder
      setFolderHistory(updatedHistory); // Update state with new history
      setCurrentFolder(previousFolder); // Navigate back to the previous folder
    }
  };
    
  // Initialize root templates folder on component load
  useEffect(() => {
    if (accessToken) {
      initializeRootFolder();
    }
  }, [accessToken]);

  // Fetch folder contents when currentFolder changes
  useEffect(() => {
    if (currentFolder) {
      fetchFolderContent(currentFolder);
    }
  }, [currentFolder]);

  // Initialize WebViewer
  useEffect(() => {
    const initializeWebViewer = async () => {
      const instance = await WebViewer({
        path: '../lib',
        licenseKey: 'your-license-key',
      }, viewer.current);

      setWebViewerInstance(instance);
    };
    if (!webViewerInstance) {
      initializeWebViewer();
    }
  }, [webViewerInstance]);

  // Initialize root templates folder
  const initializeRootFolder = async () => {
    try {
    let graphURL = '';
    if (process.env.REACT_APP_FILE_SYSTEM == 'SHAREPOINT') {
      graphURL = `https://graph.microsoft.com/v1.0/sites/${siteId}/drives/${driveId}/root/children`;
    } else if (process.env.REACT_APP_FILE_SYSTEM == 'ONEDRIVE') {
      graphURL = 'https://graph.microsoft.com/v1.0/me/drive/root:/SEPT:/children';
    }
      const response = await fetch(graphURL, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      if (!response.ok) throw new Error('Failed to fetch folder details');

      const data = await response.json();
      let templatesFolder = data.value.find(item => item.folder && item.name === 'Templates');

      if (!templatesFolder) {
        templatesFolder = await createFolder('Templates');
      }

      if (templatesFolder) {
        setCurrentFolder(templatesFolder);
      } else {
        console.error("Failed to initialize root Templates folder.");
      }
    } catch (error) {
      console.error("Error initializing root folder:", error);
    }
  };
  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file && currentFolder) {
      const uploadUrl = `https://graph.microsoft.com/v1.0/sites/${siteId}/drives/${driveId}/items/${currentFolder.id}:/${file.name}:/content`;

      try {
        const response = await fetch(uploadUrl, {
          method: 'PUT',
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': file.type,
          },
          body: file
        });

        if (response.ok) {
          console.log('File uploaded successfully');
          fetchFolderContent(currentFolder); // Refresh the folder content
        } else {
          console.error('Error uploading file:', response.statusText);
        }
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
  };

  const createFolder = async (folderName) => {
    let graphURL = '';
    if (process.env.REACT_APP_FILE_SYSTEM == 'SHAREPOINT') {
      if (folderName === 'Templates') {
        graphURL = `https://graph.microsoft.com/v1.0/sites/${siteId}/drives/${driveId}/root/children`;
      } else {
        graphURL = `https://graph.microsoft.com/v1.0/sites/${siteId}/drives/${driveId}/items/${currentFolder.id}/children`;
      }
    } else if (process.env.REACT_APP_FILE_SYSTEM == 'ONEDRIVE') {
      if (folderName === 'Templates') {
        graphURL = 'https://graph.microsoft.com/v1.0/me/drive/root:/SEPT:/children';
      } else {
        graphURL = `https://graph.microsoft.com/v1.0/me/drive/items/${currentFolder.id}/children`;
      }
    }
    try {
      const response = await fetch(graphURL, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: folderName,
          folder: {},
          "@microsoft.graph.conflictBehavior": "rename",
        }),
      });
      if (!response.ok) throw new Error("Failed to create folder");

      return await response.json();
    } catch (error) {
      console.error("Error creating folder:", error);
      return null;
    }
  };

  const fetchFolderContent = async (folder) => {
    let graphURL = '';
    if (process.env.REACT_APP_FILE_SYSTEM == 'SHAREPOINT') {
      graphURL = `https://graph.microsoft.com/v1.0/sites/${siteId}/drives/${driveId}/items/${folder.id}/children`;
    } else if (process.env.REACT_APP_FILE_SYSTEM == 'ONEDRIVE') {
      graphURL = `https://graph.microsoft.com/v1.0/me/drive/items/${folder.id}/children`;
    }

    try {
      const response = await fetch(graphURL, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      const data = await response.json();
      setFolders(data.value.filter(item => item.folder));
      setFiles(data.value.filter(item => item.file));
    } catch (error) {
      console.error("Error fetching folder content:", error);
    }
  };

  const handleCreateSubFolder = async () => {
    if (newFolderName.trim() !== "" && currentFolder) {
      const newSubFolder = await createFolder(newFolderName);
      if (newSubFolder) {
        console.log('Subfolder created successfully:', newSubFolder.name);
        fetchFolderContent(currentFolder);
        setNewFolderName("");
      }
    } else {
      console.error("Folder name is empty or current folder is not set.");
    }
  };

  const handleShowModal = (type) => {
    setModalType(type);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setNewFolderName("");
    setNewFileName("");
  };

  const handleModalSubmit = () => {
    if (modalType === "folder") {
      handleCreateSubFolder();
    }
    handleCloseModal();
  };

  return (
    <div className="Tab-Container">
      <div className="File-files-sidebar">
        <div className="File-topbar-Create-Dropdown-Container">
          <div className="File-file-nav-buttons">
            {folderHistory.length > 0 && (
                <button className="create-button" onClick={handleBackButtonClick}>
                <FontAwesomeIcon icon={faArrowLeft} className="icon-circle" />
              </button>
          )}
            <div className="File-dropdown">
              <button className="create-button">
                <FontAwesomeIcon icon={faPlus} className="icon-circle" /> Add
              </button>
              <div className="File-dropdown-content">
                <button onClick={() => handleShowModal("folder")}>Create Folder</button>
                <button onClick={() => document.getElementById('file-upload').click()}>
                    Upload File
                  </button>
                  <input type="file" id="file-upload" onChange={handleFileUpload} style={{ display: 'none' }} />
              </div>
            </div>
          </div>
        </div>
        <div className="File-files-Subdirectory-section">
          <ul>
            {folders.map((folder) => (
              <div key={folder.id}>
                  <li onClick={() => {
                    setFolderHistory([...folderHistory, currentFolder]); // Add the current folder to history before changing
                    setCurrentFolder(folder);
                  }} className="File-folder-item" style={{ cursor: 'pointer' }}>
                  <FontAwesomeIcon icon={faFolder}/> {folder.name} 
                </li>
              </div>
            ))}
          </ul>
          <ul>
            {files.map((file) => (
              <div key={file.id}>
                <li>
                  <button className="File-file-button">
                    <div className="File-file-info">
                      <FontAwesomeIcon icon={faFile} /> {file.name}
                    </div>
                    <div className="File-file-actions">
                      <FontAwesomeIcon icon={faExternalLinkAlt} style={{ cursor: 'pointer', marginLeft: '10px' }} />
                    </div>
                  </button>
                </li>
              </div>
            ))}
          </ul>
        </div>
      </div>

      <div className="File-file-preview-area">
        <div className="MyComponent">
          <div className="webviewer" ref={viewer} style={{ height: "100vh" }}></div>
        </div>
      </div>

      {showModal && (
        <div id="myModal" className="File-modal" style={{ display: 'block' }}>
          <div className="File-modal-content">
            <span className="File-close" onClick={handleCloseModal}>&times;</span>
            <h2>{modalType === "folder" ? "Create New Folder" : ""}</h2>
            {modalType === "folder" && (
              <div>
                <label>Folder Name</label>
                <input type="text" value={newFolderName} onChange={(e) => setNewFolderName(e.target.value)} />
              </div>
            )}
            <button onClick={handleModalSubmit}>Create</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Templates;