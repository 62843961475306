import React, { useState, useEffect } from 'react';
import axios from 'axios';

const NotificationSettings = ({ accounts, onClose }) => {
  const [preferences, setPreferences] = useState({
    notification_type: '',
    frequency: '',
    frequency_unit: 'days', // Default to days
    active: true,
  });

  const [employeeId, setEmployeeId] = useState(null);

  const notificationTypes = [
    'Matter Creation Notification',
    'Task Assignment Notification',
    'Deadline Notification',
    'Checklist Item Notification',
    'Task Due Date Reminders',
    'Deadline Due Date Reminders'
  ];

  useEffect(() => {
    if (accounts && accounts.length > 0 && accounts[0].idTokenClaims) {
      setEmployeeId(accounts[0].idTokenClaims.oid);
    }
  }, [accounts]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setPreferences((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!employeeId) {
      alert('Unable to retrieve employee ID. Please try again.');
      return;
    }
    try {
      const response = await axios.post('/save_preferences', { employeeid: employeeId, ...preferences });
      if (response.status === 201) {
        alert('Preferences saved successfully');
        onClose();
      } else {
        alert('Failed to save preferences');
      }
    } catch (error) {
      console.error('Error saving preferences:', error);
      alert('An error occurred while saving preferences');
    }
  };

  useEffect(() => {
    const fetchPreferences = async () => {
      if (!employeeId) return;
      try {
        const response = await axios.get(`/get_preferences/${employeeId}`);
        if (response.data.length > 0) {
          setPreferences(response.data[0]);
        }
      } catch (error) {
        console.error('Error fetching preferences:', error);
      }
    };
    fetchPreferences();
  }, [employeeId]);

  return (
    <div className="notification-settings">
      <h2>Notification Settings</h2>
      <form onSubmit={handleSubmit}>
        <label>
          Notification Type:
          <select
            name="notification_type"
            value={preferences.notification_type}
            onChange={handleChange}
          >
            <option value="">Select Notification Type</option>
            {notificationTypes.map((type) => (
              <option key={type} value={type}>{type}</option>
            ))}
          </select>
        </label>
        <label>
          Frequency:
          <input
            type="number"
            name="frequency"
            value={preferences.frequency}
            onChange={handleChange}
          />
          <select
            name="frequency_unit"
            value={preferences.frequency_unit}
            onChange={handleChange}
          >
            <option value="days">Days</option>
            <option value="weeks">Weeks</option>
            <option value="every_other_day">Every Other Day</option>
          </select>
        </label>
        <label>
          Active:
          <input
            type="checkbox"
            name="active"
            checked={preferences.active}
            onChange={handleChange}
          />
        </label>
        <button type="submit">Save Preferences</button>
      </form>
      <button onClick={onClose}>Close</button>
    </div>
  );
};

export default NotificationSettings;

