import React, { useState, useEffect, useRef } from 'react';
import '../styles/General.css';
import getAuthHeaders from '../functions/getAuthHeaders';

const columnOptions = [
    'Client ID', 'First Name', 'Last Name', 'Company', 'Email', 'Phone',
    'Address', 'Type'
];

const ReportingClient = () => {
    const [selectedColumns, setSelectedColumns] = useState(columnOptions); // Initialize with all columns
    const [isModalOpen, setIsModalOpen] = useState(false);
    const editButtonRef = useRef(null);

    useEffect(() => {
        document.body.style.overflow = isModalOpen ? 'hidden' : 'auto';
    }, [isModalOpen]);

    const handleColumnChange = (column) => {
        const newSelection = selectedColumns.includes(column)
            ? selectedColumns.filter(c => c !== column)
            : [...selectedColumns, column];
        setSelectedColumns(newSelection);
    };

    const toggleAllColumns = () => {
        if (selectedColumns.length === columnOptions.length) {
            setSelectedColumns([]); // Deselect all
        } else {
            setSelectedColumns(columnOptions); // Select all
        }
    };

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const downloadReport = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/allClients/excel`, {
                method: 'POST',
                headers: {
                    ...await getAuthHeaders(),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ cols: selectedColumns })
            });

            if (!response.ok) {
                throw new Error(`Failed to download report: ${response.statusText}`);
            }

            const blob = await response.blob();
            let filename = 'ClientsReport.xlsx'; // Default filename

            // Extract filename from Content-Disposition header if present
            const contentDisposition = response.headers.get('Content-Disposition');
            if (contentDisposition && contentDisposition.includes('filename=')) {
                filename = contentDisposition.split('filename=')[1].trim().replace(/['"]/g, '');
            }

            const downloadUrl = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = downloadUrl;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(downloadUrl);
            a.remove();
        } catch (error) {
            console.error('Error downloading report:', error);
        }
    };

    return (
        <div>
            <button ref={editButtonRef} onClick={toggleModal} className="MD-edit-edit-matter-button">Export</button>
            {isModalOpen && (
                <div className="reporting-modal-overlay">
                    <div className="reporting-modal">
                        <button className="close-button" onClick={toggleModal}>&times;</button>
                        <div className="reporting-modal-content">
                            <div className="reporting-button-container">
                                <button onClick={downloadReport} className="MD-edit-edit-matter-button">Export</button>
                                <button onClick={toggleAllColumns} className="reporting-button">
                                    {selectedColumns.length === columnOptions.length ? 'Deselect All' : 'Select All'}
                                </button>
                            </div>
                            {columnOptions.map((column, idx) => (
                                <label key={idx} className="reporting-checkbox">
                                    <input
                                        type="checkbox"
                                        checked={selectedColumns.includes(column)}
                                        onChange={() => handleColumnChange(column)}
                                        className="reporting-visually-hidden"
                                    />
                                    <span className="reporting-label">{column}</span>
                                </label>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ReportingClient;
