import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import '../styles/General.css';
import '../styles/Clients.css';
import getAuthHeaders from '../functions/getAuthHeaders';
import { faEdit } from '@fortawesome/free-solid-svg-icons'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ClientTab = () => {
  const { clientId } = useParams();
  const [client, setClient] = useState([]);
  const [updateClient, setUpdateClient] = useState([]);
  const [editMode, setEditMode] = useState(false);

  const fetchClient = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/getclient/${clientId}`, {
        headers: await getAuthHeaders()
      });
      if (response.ok) {
        const data = await response.json();
        setClient(data);
        setUpdateClient(data);
      } else {
        throw new Error('Failed to fetch client');
      }
    } catch (error) {
      console.log('Error fetching client: ' + error);
    }
  };

  useEffect(() => {
    fetchClient();
  }, [clientId]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUpdateClient({ ...updateClient, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/updateclient/${clientId}`, {
        method: 'PUT',
        headers: await getAuthHeaders(),
        body: JSON.stringify(updateClient)
      });
      if (response.ok) {
        console.log('Client details updated successfully');
        fetchClient();
        setEditMode(false);
      } else {
        throw new Error('Failed to update client details');
      }
    } catch (error) {
      console.log('Error updating client details: ' + error);
    }
  };

  return (
    <div>

        {editMode ? (
            <form onSubmit={handleSubmit}>
            <div>
              <label htmlFor="firstName">First Name:</label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                value={updateClient.firstname}
                onChange={handleInputChange}
                placeholder="First Name"
              />
            </div>
            <div>
              <label htmlFor="lastName">Last Name:</label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                value={updateClient.lastname}
                onChange={handleInputChange}
                placeholder="Last Name"
              />
            </div>
            <div>
              <label htmlFor="company">Company:</label>
              <input
                type="text"
                id="company"
                name="company"
                value={updateClient.company}
                onChange={handleInputChange}
                placeholder="Company"
              />
            </div>
            <div>
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                value={updateClient.email}
                onChange={handleInputChange}
                placeholder="Email"
              />
            </div>
            <div>
              <label htmlFor="phone">Phone:</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={updateClient.phone}
                onChange={handleInputChange}
                placeholder="Phone"
              />
            </div>
            <div>
              <label htmlFor="address">Address:</label>
              <input
                type="text"
                id="address"
                name="address"
                value={updateClient.address}
                onChange={handleInputChange}
                placeholder="Address"
              />
            </div>
            <button type="submit">Save Changes</button>
            <button onClick={() => {setUpdateClient(client); setEditMode(false)}}>Cancel</button>
          </form>
        ) : (
            <div className='client-popout'>
                      
       <div className="client-header-with-button">
         <h3 style={{ fontSize: '21px' }}>{client.firstname} {client.lastname}</h3>
             {editMode ? (
            <button onClick={() => {setUpdateClient(client); setEditMode(false)}}>Cancel</button>
        ) : (
            <button onClick={() => setEditMode(true)}>
                <FontAwesomeIcon icon={faEdit} style={{ fontSize: '15px' }} />
            </button>
        )}
    </div>
    <h1 class="client-about-line"></h1>
              <div className="client-info">
                <div className="client-info-item"><strong>ID:</strong> {client.clientid}</div>
                <div className="client-info-item"><strong>Company:</strong> {client.company}</div>
                <div className="client-info-item"><strong>Email:</strong> {client.email}</div>
                <div className="client-info-item"><strong>Phone:</strong> {client.phone}</div>
                <div className="client-info-item"><strong>Address:</strong> {client.address}</div>
            </div>
            </div>
        )}
        
    </div>
  );
};

export default ClientTab;
