import React, { useState, useEffect } from 'react';
import '../../styles/General.css';
import '../../styles/MatterDetails.css';
import '../../styles/FinancialsTabs.css';
import getAuthHeaders from '../../functions/getAuthHeaders';
import LoadingSpinner from '../../components/LoadingSpinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTable, faCalendar, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useMsal } from '@azure/msal-react';
import BilliableCalendar from './BilliableCalander';

const BillableTime = ({ matterId }) => {
  const { accounts } = useMsal();
  const [showPopup, setShowPopup] = useState(false);
  const [matterDetails, setMatterDetails] = useState(null);
  const [manualTime, setManualTime] = useState('00:00:00');
  const [date, setDate] = useState(new Date().toISOString().slice(0, 10)); // default to today's date
  const [notes, setNotes] = useState('');
  const [loadingBilledTime, setLoadingBilledTime] = useState(false);
  const [billedTime, setBilledTime] = useState([]);
  const [usedBilledTime, setUsedBilledTime] = useState([]); // State for used billed times
  const [userInputTime, setUserInputTime] = useState('');
  const [editingBtId, setEditingBtId] = useState(null);
  const [editMode, setEditMode] = useState(null);
  const [tempTimeRecorded, setTempTimeRecorded] = useState(null);
  const [viewMode, setViewMode] = useState('Calendar');

  const calculateAmount = (billedRate, timeRecorded) => {
    billedRate = parseFloat(billedRate);
    if (isNaN(billedRate)) {
      console.error('Invalid billedRate:', billedRate);
      return '0.00';
    }

    if (!timeRecorded || typeof timeRecorded !== 'string' || !timeRecorded.includes(':')) {
      console.error('Invalid timeRecorded:', timeRecorded);
      return '0.00';
    }

    const [hours, minutes, seconds] = timeRecorded.split(':').map(Number);

    if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
      console.error('Invalid time components:', { hours, minutes, seconds });
      return '0.00';
    }

    const totalHours = hours + minutes / 60 + seconds / 3600;

    const amount = billedRate * totalHours;
    console.log('Calculated amount:', amount);
    return amount.toFixed(2);
  };

  const toggleEditMode = (bt_id) => {
    if (editingBtId === bt_id) {
      setEditingBtId(null); // If clicking the same button, exit edit mode
    } else {
      setEditingBtId(bt_id); // Enter edit mode for the clicked row
    }
  };

  const handleTimeRecordedFormat = (btId) => {
    let formattedValue = '00:00:00';
    if (!isNaN(tempTimeRecorded) && tempTimeRecorded.trim() !== '') {
      const totalMinutes = parseFloat(tempTimeRecorded) * 60;
      const hours = Math.floor(totalMinutes / 60);
      const minutes = Math.floor(totalMinutes % 60);
      formattedValue = `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:00`;
    }

    setBilledTime((current) =>
      current.map((item) =>
        item.bt_id === btId ? { ...item, time_recorded: formattedValue } : item
      )
    );

    setTempTimeRecorded(null);
  };

  const handleEditChange = (e, btId, field) => {
    let newValue = e.target.value;

    if (field === 'time_recorded') {
      if (!isNaN(newValue) && newValue.trim() !== '') {
        const totalMinutes = parseFloat(newValue) * 60;
        const hours = Math.floor(totalMinutes / 60);
        const minutes = Math.floor(totalMinutes % 60);
        newValue = `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:00`;
      } else {
        newValue = '00:00:00'; // Reset or handle invalid input
      }
    }

    setBilledTime((current) =>
      current.map((item) =>
        item.bt_id === btId
          ? {
              ...item,
              [field]: newValue,
              amount:
                field === 'billedrate' || field === 'time_recorded'
                  ? calculateAmount(
                      field === 'billedrate' ? newValue : item.billedrate,
                      field === 'time_recorded' ? newValue : item.time_recorded
                    )
                  : item.amount,
            }
          : item
      )
    );
  };

  const handleSave = async (btId) => {
    const editedEntry = billedTime.find((bt) => bt.bt_id === btId);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/edit_billed_time/${btId}`, {
        method: 'PUT',
        headers: await getAuthHeaders(),
        body: JSON.stringify({
          timeRecorded: editedEntry.editingTimeRecorded || editedEntry.time_recorded,
          notes: editedEntry.editingNotes || editedEntry.notes,
          billedrate: editedEntry.editingBilledRate || editedEntry.billedrate,
          date: editedEntry.editingDate || editedEntry.date,
          amount: calculateAmount(
            editedEntry.editingBilledRate || editedEntry.billedrate,
            editedEntry.editingTimeRecorded || editedEntry.time_recorded
          ), // Calculate amount on save
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      console.log('Entry updated successfully');
      fetchBilledTimeData(); // Refresh or directly update state to reflect changes
    } catch (error) {
      console.error('Error updating entry:', error);
    } finally {
      setEditMode(null); // Exit edit mode
    }
  };

  const handleDelete = async (btId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/delete_billed_time/${btId}`, {
        method: 'DELETE',
        headers: await getAuthHeaders(),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      console.log('Entry deleted successfully');
      setBilledTime((current) => current.filter((bt) => bt.bt_id !== btId)); // Update the state to remove the deleted entry
    } catch (error) {
      console.error('Error deleting entry:', error);
    }
  };

  const handleInputChange = (e) => {
    const input = e.target.value;
    setUserInputTime(input); // Update user input directly

    // Convert input to HH:MM:SS format only if it's a valid number
    if (!isNaN(input) && input.trim() !== '') {
      const totalMinutes = parseFloat(input) * 60;
      const hours = Math.floor(totalMinutes / 60);
      const minutes = Math.floor(totalMinutes % 60);
      const formattedTime = `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:00`;
      setManualTime(formattedTime); // Update internal time representation
    } else {
      setManualTime('00:00:00'); // Reset or handle invalid input
    }
  };

  const formatTime = (time) => {
    const milliseconds = ('0' + (Math.floor(time / 10) % 100)).slice(-2);
    const seconds = ('0' + (Math.floor(time / 1000) % 60)).slice(-2);
    const minutes = ('0' + (Math.floor(time / 60000) % 60)).slice(-2);
    const hours = ('0' + Math.floor(time / 3600000)).slice(-2);
    return `${hours}:${minutes}:${seconds}.${milliseconds}`;
  };

  const fetchMatterDetails = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/matter_by_id/${matterId}`, {
        headers: await getAuthHeaders(),
      });
      if (response.ok) {
        const data = await response.json();
        setMatterDetails(data);
      } else {
        throw new Error('Failed to fetch matter details');
      }
    } catch (error) {
      console.log('Error fetching matter details: ' + error);
    }
  };

  const fetchBilledTimeData = async () => {
    setLoadingBilledTime(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/get_billed_time/${matterId}`, {
        headers: await getAuthHeaders(),
      });
      const data = await response.json();
      console.log('Fetched billedTime data:', data);

      // Update amount for each entry based on billed rate and time recorded
      const updatedBilledTime = data.map((bt) => ({
        ...bt,
        amount: calculateAmount(bt.billedrate, bt.time_recorded),
      }));

      // Filter and set the billed times with and without invoice IDs
      setBilledTime(updatedBilledTime.filter((bt) => !bt.invoiceid));
      setUsedBilledTime(updatedBilledTime.filter((bt) => bt.invoiceid)); // Set the used billed times
    } catch (error) {
      console.error('Error fetching billed time data:', error);
    } finally {
      setLoadingBilledTime(false);
    }
  };

  const handleSubmitConfirmation = async () => {
    const { oid } = accounts[0].idTokenClaims;
    const timeToRecord = manualTime !== '00:00:00' ? manualTime : formatTime(0);

    // Assuming `billedRate` comes from the employee data
    const employee = billedTime.find((bt) => bt.employeeid === oid);
    const billedRate = employee ? parseFloat(employee.billedrate) : NaN;

    console.log('Employee Data:', employee); // Debug employee data
    console.log('Fetched billedRate:', billedRate); // Debug fetched billedRate

    const amount = calculateAmount(billedRate, timeToRecord); // Calculate the amount

    const submissionData = {
      matterId: matterDetails.matterid,
      timeRecorded: timeToRecord,
      date: date,
      notes: notes,
      employeeid: oid,
      amount: amount, // Include amount in submission
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/billed_time`, {
        method: 'POST',
        headers: await getAuthHeaders(),
        body: JSON.stringify(submissionData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseBody = await response.json();
      console.log('Time entry submitted successfully:', responseBody);
      fetchBilledTimeData();
    } catch (error) {
      console.error('Error submitting time entry:', error);
      // Handle error
    }
    setShowPopup(false);
  };

  const toggleView = (mode) => {
    setViewMode(mode);
  };

  useEffect(() => {
    fetchMatterDetails();
    fetchBilledTimeData();
  }, []);

  return (
    <div className="Tab-Container">
      <div className="Transactions">
        {showPopup && (
          <div className="timer-backdrop">
            <div className="timer-popup">
              <p>Submit this time to a matter:</p>
              <p>{matterDetails.mattername}</p>

              <label htmlFor="userInputTime">Time (Hours): </label>
              <input
                type="text"
                id="userInputTime"
                value={userInputTime}
                onChange={(e) => handleInputChange(e)}
                title="Enter time in hours (e.g., 8.5 for 8 hours and 30 minutes)"
              />

              <label htmlFor="date">Date: </label>
              <input type="date" id="date" value={date} onChange={(e) => setDate(e.target.value)} />

              <label htmlFor="notes">Notes: </label>
              <textarea id="notes" value={notes} onChange={(e) => setNotes(e.target.value)} />

              <button onClick={handleSubmitConfirmation}>Submit</button>
              <button onClick={() => { setShowPopup(false) }}>Cancel</button>
            </div>
          </div>
        )}
        <h2 className="section-toggle">
          <div className="view-toggle-icons">
            <button
              onClick={() => toggleView('calendar')}
              className={`view-toggle-btn ${viewMode === 'calendar' ? 'active' : ''}`}
            >
              <FontAwesomeIcon icon={faCalendar} title="Calendar View" />
            </button>

            <button
              onClick={() => toggleView('table')}
              className={`view-toggle-btn ${viewMode === 'table' ? 'active' : ''}`}
            >
              <FontAwesomeIcon icon={faTable} title="Table View" />
            </button>
          </div>
          <button onClick={() => setShowPopup(true)} className="BT-button">
            Create New
          </button>
        </h2>
        {viewMode === 'table' ? (
          <div className="Billable-Time-Tables">
            <div className="table-container">
              {loadingBilledTime ? (
                <LoadingSpinner />
              ) : !Array.isArray(billedTime) || billedTime.length === 0 ? (
                <p>No billables for this matter ID.</p>
              ) : (
                <table className="Unused-Billable-Time-table">
                  <thead>
                    <tr>
                      <th>Employee</th>
                      <th>Billed Rate ($/hr)</th>
                      <th>Time Recorded</th>
                      <th>Date</th>
                      <th>Notes</th>
                      <th>Billed Charge</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {billedTime.map((BT) => (
                      <tr key={BT.bt_id}>
                        <td>{BT.employee_name}</td>
                        <td>
                          {editMode === BT.bt_id ? (
                            <input
                              type="number"
                              step="0.01"
                              value={BT.editingBilledRate || BT.billedrate}
                              onChange={(e) => handleEditChange(e, BT.bt_id, 'billedrate')}
                            />
                          ) : (
                            BT.billedrate
                          )}
                        </td>
                        <td>
                          {editMode === BT.bt_id ? (
                            <input
                              type="text"
                              value={editMode === BT.bt_id ? tempTimeRecorded : BT.time_recorded}
                              onChange={(e) => setTempTimeRecorded(e.target.value)}
                              onBlur={() => handleTimeRecordedFormat(BT.bt_id)}
                            />
                          ) : (
                            BT.time_recorded
                          )}
                        </td>
                        <td>
                          {editMode === BT.bt_id ? (
                            <input
                              type="date"
                              value={BT.editingDate || BT.date}
                              onChange={(e) => handleEditChange(e, BT.bt_id, 'date')}
                            />
                          ) : (
                            BT.date
                          )}
                        </td>
                        <td>
                          {editMode === BT.bt_id ? (
                            <textarea
                              value={BT.editingNotes || BT.notes}
                              onChange={(e) => handleEditChange(e, BT.bt_id, 'notes')}
                            />
                          ) : (
                            BT.notes
                          )}
                        </td>
                        <td>
                          {editMode === BT.bt_id ? (
                            <input
                              type="number"
                              step="0.01"
                              value={BT.amount}
                              readOnly
                            />
                          ) : (
                            `$${BT.amount}`
                          )}
                        </td>
                        <td>
                          {editMode === BT.bt_id ? (
                            <button onClick={() => handleSave(BT.bt_id)}>Save</button>
                          ) : (
                            <button onClick={() => setEditMode(BT.bt_id)}>
                              <FontAwesomeIcon icon={faEdit} style={{ fontSize: '15px' }} />
                            </button>
                          )}
                          <button onClick={() => handleDelete(BT.bt_id)}>
                            <FontAwesomeIcon icon={faTrash} style={{ fontSize: '15px', color: 'red' }} />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>

            <div className="table-container">
              <h2 className="section-toggle">Used Billed Time</h2>
              {loadingBilledTime ? (
                <LoadingSpinner />
              ) : !Array.isArray(usedBilledTime) || usedBilledTime.length === 0 ? (
                <p>No used billables for this matter ID.</p>
              ) : (
                <table className="matter-table">
                  <thead>
                    <tr>
                      <th>Employee</th>
                      <th>Billed Rate ($/hr)</th>
                      <th>Time Recorded</th>
                      <th>Date</th>
                      <th>Notes</th>
                      <th>Invoice ID</th>
                      <th>Billed Charge</th>
                    </tr>
                  </thead>
                  <tbody>
                    {usedBilledTime.map((BT) => (
                      <tr key={BT.bt_id}>
                        <td>{BT.employee_name}</td>
                        <td>{BT.billedrate}</td>
                        <td>{BT.time_recorded}</td>
                        <td>{BT.date}</td>
                        <td>{BT.notes}</td>
                        <td>{BT.invoiceid}</td>
                        <td>{`$${BT.amount}`}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        ) : (
          <BilliableCalendar matterId={matterId} />
        )}
      </div>
    </div>
  );
};

export default BillableTime;
