import React from 'react';
import '../styles/General.css';
import getAuthHeaders from '../functions/getAuthHeaders';

const ReportingAgents = () => {
    const downloadReport = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/allAgents/excel`, {
                headers: await getAuthHeaders()
              });
                        
            if (response.ok) {
                const blob = await response.blob();
                let filename = 'Agents Report SEPT.xlsx';

                // Check if Content-Disposition header exists
                const contentDisposition = response.headers.get('Content-Disposition');
                if (contentDisposition && contentDisposition.includes('filename=')) {
                    filename = contentDisposition.split('filename=')[1].trim();
                }

                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove();
            } else {
                throw new Error(`Failed to download report: ${response.statusText}`);
            }
        } catch (error) {
            console.error('Error downloading report:', error);
        }
    };

    return (
        <div>
            <button onClick={downloadReport} className="MD-edit-edit-matter-button">Export</button>
        </div>
    );
};

export default ReportingAgents;
