import React, { useState } from 'react';
import './styles/General.css';
import './styles/Settings.css';

function SettingsPage() {
  // Dummy employee data for demonstration (replace with your actual data)
  const [employeeData, setEmployeeData] = useState({
    employeeid: 123,
    phone: '123-456-7890',
    employees: 'John Doe',
    email: 'johndoe@example.com',
    lastname: 'Doe',
    firstname: 'John',
  });

  const [apiKey, setApiKey] = useState('');
  const [dataRetentionPeriod, setDataRetentionPeriod] = useState(30);
  const [autoBackup, setAutoBackup] = useState(false);

  const handleApiKeyChange = (event) => {
    setApiKey(event.target.value);
  };

  const handleDataRetentionPeriodChange = (event) => {
    setDataRetentionPeriod(Number(event.target.value));
  };

  const handleAutoBackupChange = (event) => {
    setAutoBackup(event.target.checked);
  };

  const handleSaveSettings = (event) => {
    event.preventDefault();

    // Save settings logic goes here

    alert('Settings saved!');
  };

  const handleFullNameChange = (event) => {
    setEmployeeData({ ...employeeData, employees: event.target.value });
  };

  const handleEmailChange = (event) => {
    setEmployeeData({ ...employeeData, email: event.target.value });
  };

  const handlePhoneChange = (event) => {
    setEmployeeData({ ...employeeData, phone: event.target.value });
  };

  const handleSaveProfile = (event) => {
    event.preventDefault();

    // Save profile logic goes here

    alert('Profile saved!');
  };

  return (
<div>
      <ProfileSettings
        employeeData={employeeData}
        onFullNameChange={handleFullNameChange}
        onEmailChange={handleEmailChange}
        onPhoneChange={handlePhoneChange}
        onSaveProfile={handleSaveProfile}
      />
    </div>
  );
}

function ProfileSettings({
  employeeData,
  onFullNameChange,
  onEmailChange,
  onPhoneChange,
  onSaveProfile,
}) {
  const [fullName, setFullName] = useState(employeeData.employees);
  const [email, setEmail] = useState(employeeData.email);
  const [phone, setPhone] = useState(employeeData.phone);
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');

  const handleUserNameChange = (event) => {
    setUserName(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSaveProfile = (event) => {
    event.preventDefault();

    // Save profile logic goes here

    alert('Profile saved!');
  };

  return (
    <div className="settings-container">
      <h2 className="settings-title">Profile Settings</h2>
      <form onSubmit={onSaveProfile}>
        <label className="settings-label">
          Full Name:
          <input
            type="text"
            value={fullName}
            onChange={onFullNameChange}
            className="settings-input"
          />
        </label>
        <label className="settings-label">
          Email:
          <input
            type="text"
            value={email}
            onChange={onEmailChange}
            className="settings-input"
          />
        </label>
        <label className="settings-label">
          Phone:
          <input
            type="text"
            value={phone}
            onChange={onPhoneChange}
            className="settings-input"
          />
        </label>
        <label className="settings-label">
          User Name:
          <input
            type="text"
            value={userName}
            onChange={handleUserNameChange}
            className="settings-input"
          />
        </label>
        <label className="settings-label">
          Password:
          <input
            type="password"
            value={password}
            onChange={handlePasswordChange}
            className="settings-input"
          />
        </label>
        <button type="submit" className="settings-button">
          Save Profile
        </button>
      </form>
    </div>
  );
}

export default SettingsPage;
