import React, { useState } from 'react';

function WorkflowEditPage({ onSave, initialWorkflow }) {
  const [workflow, setWorkflow] = useState(initialWorkflow);

  const handleAddMilestone = () => {
    setWorkflow([...workflow, { name: '', partition: '', color: '#000000' }]);
  };

  const handleMilestoneChange = (index, key, value) => {
    const updatedWorkflow = [...workflow];
    updatedWorkflow[index][key] = value;
    setWorkflow(updatedWorkflow);
  };

  const handleDeleteMilestone = (index) => {
    setWorkflow(workflow.filter((_, i) => i !== index));
  };

  const handleSave = () => {
    onSave(workflow);
  };

  return (
    <div>
      {workflow.map((milestone, index) => (
        <div key={index}>
          <input
            type="text"
            placeholder="Name"
            value={milestone.name}
            onChange={(e) => handleMilestoneChange(index, 'name', e.target.value)}
          />
          <input
            type="text"
            placeholder="Partition"
            value={milestone.partition}
            onChange={(e) => handleMilestoneChange(index, 'partition', e.target.value)}
          />
          <input
            type="color"
            value={milestone.color}
            onChange={(e) => handleMilestoneChange(index, 'color', e.target.value)}
          />
          <button onClick={() => handleDeleteMilestone(index)}>Delete</button>
        </div>
      ))}
      <button onClick={handleAddMilestone}>Add Milestone</button>
      <button onClick={handleSave}>Save Workflow</button>
    </div>
  );
}

export default WorkflowEditPage;
