import React, { useRef, useEffect } from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

function BarGraph() {
  const chartRef = useRef(null);

  useEffect(() => {
    const root = am5.Root.new(chartRef.current);
    root.setThemes([am5themes_Animated.new(root)]);

    const chart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: true,
      panY: true,
      wheelX: "panX",
      wheelY: "zoomX",
      pinchZoomX: true
    }));

    const xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
      categoryField: "status",
      renderer: am5xy.AxisRendererX.new(root, {
        minGridDistance: 30
      })
    }));

    const yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      renderer: am5xy.AxisRendererY.new(root, {})
    }));

    const series = chart.series.push(am5xy.ColumnSeries.new(root, {
      name: "Status",
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: "value",
      categoryXField: "status",
      tooltip: am5.Tooltip.new(root, {
        labelText: "{valueY}"
      })
    }));

    series.columns.template.setAll({
      width: am5.percent(80),
      strokeOpacity: 0,
      cornerRadiusTL: 5,
      cornerRadiusTR: 5
    });

    const data = [
      { status: "Pending", value: 45 },
      { status: "Completed", value: 55 }
    ];

    xAxis.data.setAll(data);
    series.data.setAll(data);

    series.appear(1000);
    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, []);

  return (
    <div className="box open-matters-widget">
      <h2 style={{ textAlign: 'center', marginBottom: '20px', color: '#333' }}>Weekly Checklist Status</h2>
      <div ref={chartRef} style={{ width: "100%", height: "300px" }}></div>
    </div>
  );
}

export default BarGraph;
