import React, { useRef, useEffect } from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5map from "@amcharts/amcharts5/map";
import am5geodata_worldLow from "@amcharts/amcharts5-geodata/worldLow";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

function ClientsDispursed() {
  const chartRef = useRef(null);

  useEffect(() => {
    const root = am5.Root.new(chartRef.current);
    root.setThemes([am5themes_Animated.new(root)]);

    const chart = root.container.children.push(am5map.MapChart.new(root, {
      panX: "rotateX",
      panY: "rotateY",
      projection: am5map.geoOrthographic(),
      rotateX: -20,
      rotateY: -60,
      minZoomLevel: 1,
      maxZoomLevel: 1,
    }));

    // Create polygon series for countries
    const polygonSeries = chart.series.push(am5map.MapPolygonSeries.new(root, {
      geoJSON: am5geodata_worldLow
    }));

    polygonSeries.mapPolygons.template.setAll({
      tooltipText: "{name}",
      stroke: am5.color(0xffffff),
      strokeWidth: 0.5,
      fill: am5.color(0x1E90FF), // Dodger Blue for the ocean
    });

    // Create point series for clients
    const pointSeries = chart.series.push(am5map.MapPointSeries.new(root, {
      latitudeField: "latitude",
      longitudeField: "longitude",
      tooltipText: "{title}"
    }));

    const circleTemplate = am5.Template.new({});

    pointSeries.bullets.push(() => {
      const circle = am5.Circle.new(root, {
        radius: 5,
        tooltipText: "{title}",
        fill: am5.color(0xFF0000) // Red for points
      }, circleTemplate);

      return am5.Bullet.new(root, {
        sprite: circle
      });
    });

    // Add data
    const data = [
      { title: "Client 1", latitude: 40.7128, longitude: -74.0060 }, // New York
      { title: "Client 2", latitude: 34.0522, longitude: -118.2437 }, // Los Angeles
      { title: "Client 3", latitude: 51.5074, longitude: -0.1278 }, // London
      { title: "Client 4", latitude: 35.6895, longitude: 139.6917 }, // Tokyo
      { title: "Client 5", latitude: -33.8688, longitude: 151.2093 }, // Sydney
      { title: "Client 6", latitude: 48.8566, longitude: 2.3522 }, // Paris
      { title: "Client 7", latitude: 55.7558, longitude: 37.6176 }, // Moscow
      { title: "Client 8", latitude: -23.5505, longitude: -46.6333 }, // Sao Paulo
      { title: "Client 9", latitude: 19.4326, longitude: -99.1332 }, // Mexico City
      { title: "Client 10", latitude: 1.3521, longitude: 103.8198 } // Singapore
    ];

    pointSeries.data.setAll(data);

    // Create a line series to connect the points
    const lineSeries = chart.series.push(am5map.MapLineSeries.new(root, {}));
    lineSeries.mapLines.template.setAll({
      stroke: am5.color(0xFF0000), // Red color for the lines
      strokeWidth: 2,
      strokeDasharray: [5, 5] // Dotted line pattern
    });

    // Connect points with lines
    data.forEach((point, index) => {
      if (index < data.length - 1) {
        lineSeries.data.push({
          geometry: {
            type: "LineString",
            coordinates: [
              [point.longitude, point.latitude],
              [data[index + 1].longitude, data[index + 1].latitude]
            ]
          }
        });
      }
    });

    // Enable globe rotation
    chart.set("interactive", true);
    chart.chartContainer.set("wheelable", true);

    chart.seriesContainer.events.on("pointerdown", (ev) => {
      if (ev.pointer) {
        ev.target.set("downX", ev.pointer.x);
        ev.target.set("downY", ev.pointer.y);
        ev.target.set("rotationX", chart.get("rotationX"));
        ev.target.set("rotationY", chart.get("rotationY"));
        ev.target.set("rotationXStart", chart.get("rotationX"));
        ev.target.set("rotationYStart", chart.get("rotationY"));
      }
    });

    chart.seriesContainer.events.on("pointermove", (ev) => {
      if (ev.target.get("downX") !== undefined && ev.pointer) {
        const rotationX = ev.target.get("rotationXStart") + (ev.pointer.x - ev.target.get("downX")) / 10;
        const rotationY = ev.target.get("rotationYStart") + (ev.pointer.y - ev.target.get("downY")) / 10;
        chart.set("rotationX", rotationX);
        chart.set("rotationY", rotationY);
      }
    });

    chart.seriesContainer.events.on("pointerup", (ev) => {
      ev.target.set("downX", undefined);
      ev.target.set("downY", undefined);
    });

    return () => {
      root.dispose();
    };
  }, []);

  return (
    <div className="box open-matters-widget" >
      <h2 style={{ textAlign: 'center', marginBottom: '20px', color: '#333' }}>Clients Dispersed Globally</h2>
      <div ref={chartRef} style={{ width: "100%", height: "300px", overflow: "hidden" }}></div>
    </div>
  );
}

export default ClientsDispursed;
