import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaUserCircle, FaCog, FaClock, FaRobot, FaBell  } from 'react-icons/fa';
import '../styles/NavBar.css';
import '../styles/General.css';
import '../styles/Timer.css';
import Timer from '../timer'; 
// import { Auth } from 'aws-amplify';
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { InteractionStatus } from "@azure/msal-browser";

function NavBarAuth() {
    const { instance, inProgress, accounts } = useMsal();
    const [userName, setUserName] = useState("");
    const [accessToken, setAccessToken] = useState(null);
    const [showTimer, setShowTimer] = useState(false);
  
    const handleLogout = () => {
      instance.logoutRedirect();
    };
    
    const toggleTimer = () => {
      setShowTimer(!showTimer);
    };
    useEffect(() => {
      if (instance && accounts.length > 0) {
        // console.log(accounts)
        const request = {
          scopes: ["user.read.all"],
          account: accounts[0],
        };
        instance
          .acquireTokenSilent(request)
          .then((response) => {
            setAccessToken(response.accessToken);
          })
          .catch((error) => {
            // acquireTokenSilent can fail for a number of reasons, fallback to interaction
            if (error instanceof InteractionRequiredAuthError) {
              instance.acquireTokenPopup(request).then((response) => {
                setAccessToken(response.accessToken);
              });
            }
          });
      }
    }, [instance, accounts]);
  
    useEffect(() => {
      if (instance) {
        setUserName(instance.getActiveAccount().idTokenClaims.name);
      }
    }, [instance]);

    
    return (
        <div className="NavBar">
            <div className="NavBar-links"> 
            <Link to="/" style={{ color: 'black' }}><h1><strong>SEPT.</strong></h1></Link>
            <div className="NavBar-TextLinks">
                <Link to="/matters">Matters</Link>
                <Link to="/MyTasks">Tasks</Link>
                <Link to="/clients">Clients</Link>   
                <Link to="/agents">Agents</Link>   
            </div>
            <div className="NavBar-TextLinks">
            <Link to="/notifications"><FaBell size={24} /></Link>
                <Link onClick={toggleTimer} className="nav-link"> <FaClock size={24} /></Link> 
                {/* <Link to="/settings"><FaCog size={24} /></Link> */}
                <Link to="/Profile"> <FaUserCircle size={24} /> &nbsp; {userName}</Link>
            </div>
            <Link onClick={handleLogout} className="App_nav-links">Sign Out</Link>
                {showTimer && <Timer />} 
            </div>
        </div>
    );
  }

export default NavBarAuth;