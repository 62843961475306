import React, { useState, useEffect } from 'react';
import './styles/General.css';
import './styles/MatterDetails.css';
import 'font-awesome/css/font-awesome.min.css';
import './styles/Matter.css';
import '@react-pdf-viewer/core/lib/styles/index.css';
import ClientTab from './ClientDetails/ClientTab';
import ClientMattersTab from './ClientDetails/ClientMattersTab';
import { FaArrowLeft } from 'react-icons/fa';
import { Link } from 'react-router-dom';


const ClientDetails = () => {
    const [activeTab, setActiveTab] = useState('Client');

  return (
    <div className="backdrop">
     <Link to="/clients" className="matterDetails-back-button">
        <FaArrowLeft />
      </Link>
      <div>
<ClientTab/>
<ClientMattersTab/>
      </div>
    </div>
  );
};

export default ClientDetails;
