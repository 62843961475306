import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './styles/App.css';
import './styles/General.css';
import NavBar from './navbar/NavBar.js';
import Home from './Home';
import Matter from './Matter';
import NewMatter from './NewMatter';
import Notifications from './Notifications';
import MyTasks from './MyTasks';
import Profile from './Profile';
import MatterDetails from './MatterDetails.js';
import TaskDetails from './TaskDetails.js';
import Settings from './Settings';
import Clients from './Clients';
import CreateMatter from './CreateMatter.js';
import Milestone from './Milestone'; 
import Agents from './Agents.js';
import ClientDetails from './ClientDetails.js';
import Reporting_Test from './Reporting/Reporting_Test.js';
import ReportingClient from './Reporting/ReportingClient.js';
import Checklist from './functions/Checklist.js';
import Deadline from './functions/Deadline.js';
import TasksStatus from './Widgets/TasksStatus.js';
import ClientsDispursed from './Widgets/ClientsDispursed.js';

import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { ClientSideNavigation } from "./routes/client-side-navigation";

const App = ({ pca }) => {

  return (
    <ClientSideNavigation pca={pca}>
      <MsalProvider instance={pca}>
        <nav> 
          <NavBar/>
        </nav>
        <AuthenticatedTemplate>
          <div>
            <Routes>
              <Route path="/" element={<Home />}/>
              <Route path="/matters" element={<Matter />}/>
              <Route path="/NewMatter" element={<NewMatter />} />
              <Route path="/Notifications" element={<Notifications />} /> 
              <Route path="/MyTasks" element={<MyTasks />}/>
              <Route path="/Profile" element={<Profile/>}/>
              {/* <Route path="/settings" element={<Settings />} /> */}
              <Route path="/clients" element={<Clients />} />
              <Route path="/clients/:clientId" element={<ClientDetails />}/>
              <Route path="/agents" element={<Agents />} />
              <Route path="/CreateMatter" element={<CreateMatter />} />
              <Route path="/matters/:matterId" element={<MatterDetails />}/>
              {/* <Route path="/matters/:matterId/notes" element={<AdditionalNotes />}/> */}
              <Route path="/task/:taskId" element={<TaskDetails />} />
              <Route path="/Milestone" element={<Milestone />}  />
              <Route path="/Reporting_Test" element={<Reporting_Test />} />
              <Route path="/ReportingClient" element={<ReportingClient />} />
              <Route path="/Checklist" element={<Checklist />} />
              <Route path="/Deadline" element={<Deadline />} />
              <Route path="/TasksStatus" element={<TasksStatus />} />
              <Route path="/ClientsDispursed" element={<ClientsDispursed />} />

            </Routes>
          </div>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          SEPT Software
        </UnauthenticatedTemplate>
      </MsalProvider>
    </ClientSideNavigation>
  );
};

export default App;