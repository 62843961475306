import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import Milestone from '../Milestone';
import Checklist from '../functions/Checklist.js';
import Deadline from '../functions/Deadline.js';
import '../styles/General.css';
import '../styles/MatterDetails.css';
import '../styles/Deadline.css';
import '../styles/GeneralTab.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {   faUserTie, faBuilding,     } from '@fortawesome/free-solid-svg-icons';
import getAuthHeaders from '../functions/getAuthHeaders';
import LoadingSpinner from '../components/LoadingSpinner.js';

const GeneralTab = () => {
  const { matterId } = useParams();
  const [matterDetails, setMatterDetails] = useState(null);
  const [editDetails, setEditDetails] = useState({});
  const [tasks, setTasks] = useState([]);
  const [error, setError] = useState(null);
  const [isPopOutVisible, setIsPopOutVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentMatterToEdit, setCurrentMatterToEdit] = useState(null);
  const [selectedTask, setSelectedTask] = useState(null);
  const [editMode, setEditMode] = useState({});
  const togglePopOut = () => {
    setIsPopOutVisible(!isPopOutVisible);
  };
  
  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };
  const fetchMatterDetails = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/matter_by_id/${matterId}`, {
        headers: await getAuthHeaders()
      });
      if (response.ok) {
        const data = await response.json();
        setMatterDetails(data);
      } else {
        throw new Error('Failed to fetch matter details');
      }
    } catch (error) {
      setError('Error fetching matter details: ' + error.message);
    }
  };

  const handleFieldSave = async (fieldName, newValue) => {
    try {
      const updatedData = { [fieldName]: newValue };
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/edit_matter/${matterId}`, {
        method: 'PUT',
        headers: {
          ...await getAuthHeaders(),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(updatedData)
      });
  
      if (response.ok) {
        // Update local state to reflect the change
        setMatterDetails(prev => ({ ...prev, [fieldName]: newValue }));
        alert('Update successful');
      } else {
        alert('Failed to update');
      }
    } catch (error) {
      console.error('Error updating matter:', error);
      alert('Error updating: ' + error.message);
    }
  };
  
    

  const handleFullEditSave = async () => {
    const editedData = {
        mattername: editDetails.mattername,
        clientid: editDetails.clientid,
        employeeid: editDetails.employeeid,
        mattertype: editDetails.mattertype,
        opendate: editDetails.opendate,
        closedate: editDetails.closedate,
        amount: editDetails.amount,
        status: editDetails.status,
        description: editDetails.description,
        urgency: editDetails.urgency,
        country: editDetails.country,
        case_type: editDetails.case_type,
        application_date: editDetails.application_date,
        application_no: editDetails.application_no,
        registration_date: editDetails.registration_date,
        registration_no: editDetails.registration_no,
        publication_date: editDetails.publication_date,
        publication_no: editDetails.publication_no,
        application_status: editDetails.application_status,
        priority_data: editDetails.priority_data,
        office_action_date: editDetails.office_action_date,
        expiry_date: editDetails.expiry_date,
        workflowid: editDetails.workflowid
    };

    try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/edit_matter/${currentMatterToEdit.matterid}`, {
            method: 'PUT',
            headers: {
              ...await getAuthHeaders(),
              'Content-Type': 'application/json'
            },
            
            body: JSON.stringify(editedData)
        });

        if (response.ok) {
            alert('Matter updated successfully');
            setIsEditModalOpen(false); // Close the edit modal
            fetchMatterDetails(); // Refresh the matter details
        } else {
            const errorData = await response.json();
            alert(`Failed to update matter: ${errorData.message}`);
        }
    } catch (error) {
        console.error('Error updating matter:', error);
        alert('Error updating matter: ' + error.message);
    }
};
const handleEditClick = (matter) => {
  setCurrentMatterToEdit(matter); // Assuming matter includes all details you want to edit
  setIsEditModalOpen(true);
};

const EditableText = ({ type = "text", fieldName, value, onSave }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleSave = () => {
    onSave(fieldName, inputValue);
    setIsEditing(false);
  };

  return isEditing ? (
    <div>
      <input
        type={type} // Dynamically set the input type based on the type prop
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onBlur={handleSave}
        autoFocus
      />
      <button onClick={handleSave}>Save</button>
    </div>
  ) : (
    <div onClick={() => setIsEditing(true)}>{value || `Click to edit ${fieldName}`}</div>
  );
};

  


const fetchTasks = async () => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/tasks_by_matter/${matterId}`, {
      headers: await getAuthHeaders()
    });
    if (response.ok) {
      let data = await response.json();
      // Sort tasks by created_at in descending order and take the first 5
      data = data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)).slice(0, 5);
      setTasks(data);
    } else {
      throw new Error('Failed to fetch tasks');
    }
  } catch (error) {
    setError('Error fetching tasks: ' + error.message);
  }
};


  useEffect(() => {
    fetchMatterDetails();
    fetchTasks();
  }, [matterId]);

  if (!matterDetails) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }


  
  function calculateFontSize(title) {
    if (title.length <= 10) {
      return ' 35px'; // Larger size for shorter titles
    } else if (title.length <= 20) {
      return '27px'; // Slightly smaller size
    } else {
      return '20px'; // Smallest size for long titles
    }
  }

  const titleFontSize = matterDetails && matterDetails.mattername ? calculateFontSize(matterDetails.mattername) : 'defaultFontSize';
  const handleMarkPending = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/task-pending/${selectedTask.taskid}`, {
        method: 'PUT',
        headers: await getAuthHeaders(),
        body: JSON.stringify({ status: 'Pending' }),
      });

      if (response.ok) {
        // Update the task status locally after successful update
        setSelectedTask({ ...selectedTask, status: 'Pending' });
      }
    } catch (error) {
      console.log('Error marking task as Pending:', error);
    }
  };

  const handleMarkInProgress = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/task-inprogress/${selectedTask.taskid}`, {
        method: 'PUT',
        headers: await getAuthHeaders(),
        body: JSON.stringify({ status: 'In Progress' }),
      });

      if (response.ok) {
        // Update the task status locally after successful update
        setSelectedTask({ ...selectedTask, status: 'In Progress' });
      }
    } catch (error) {
      console.log('Error marking task as in progress:', error);
    }
  };

  const handleMarkCompleted = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/task-complete/${selectedTask.taskid}`, {
        method: 'PUT',
        headers: await getAuthHeaders(),
        body: JSON.stringify({ status: 'Completed' }),
      });

      if (response.ok) {
        // Update the task status locally after successful update
        setSelectedTask({ ...selectedTask, status: 'Completed' });
      }
    } catch (error) {
      console.log('Error marking task as completed:', error);
    }
  };

  if (!tasks) {
    return <LoadingSpinner />;
  }

  const getProgressBarColor = () => {
    if(selectedTask){
      if (selectedTask.status === 'Pending') {
        return 'red';
      } else if (selectedTask.status === 'In Progress') {
        return 'yellow';
      } else if (selectedTask.status === 'Completed') {
        return 'green';
      }
      return 'gray';
    }
  };

  const getProgressBarPartition = () => {
    if(selectedTask){
      if (selectedTask.status === 'Pending') {
        return '33.33%';
      } else if (selectedTask.status === 'In Progress') {
        return '66.66%';
      } else if (selectedTask.status === 'Completed') {
        return '100%';
      }
      return '0%';
    }
  };

  const progressBarContainerStyle = {
    width: '50%',
    maxWidth: '400px',
    height: '20px',
    backgroundColor: 'lightgray',
    borderRadius: '4px',
    overflow: 'hidden',
    position: 'relative',
  };

  const progressBarPartitionStyle = {
    height: '100%',
    width: getProgressBarPartition(),
    backgroundColor: getProgressBarColor(),
    transition: 'width 0.3s ease',
    position: 'relative',
  };

  const statusTextStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: 'black',
    fontWeight: 'bold',
  };


  return (

      <div className="Tab-Container">
        
          <div>
            <div>

            <div>
              <div className="milestone-about-container">
           <div>
             <div className="about-container" >
              <div className="about-text-container">
                <div>
                <div className="about-headingtext-container"> 
                <button className="MD-edit-edit-matter-button" onClick={toggleEditMode}>      {isEditMode ? 'Cancel' : 'Edit'}   </button>     
                  <p className="about-text-title" style={{ fontSize: titleFontSize }}>  
                  {isEditMode ? (
                      <EditableText
                        fieldName="mattername"
                        value={matterDetails.mattername || ''}
                        onSave={handleFieldSave}
                      />
                    ) : (
                      <span>{matterDetails.mattername || 'N/A'}</span>
                    )}
                  </p>
                  </div>
                  <div className="about-text-subheading">
                  <p><strong>ID:</strong> {matterId}</p>
                   <p><strong>Created:</strong>

                        {isEditMode ? (
                      <EditableText
                      type="date"
                      fieldName="opendate"
                      value={matterDetails.opendate || ''}
                      onSave={handleFieldSave}
                      />
                    ) : (
                      <span> {matterDetails.opendate || 'N/A'}</span>
                    )} </p>

                    <p><strong>Due:</strong>   
              {isEditMode ? (
                      <EditableText
                      type="date"
                      fieldName="closedate"
                      value={matterDetails.closedate || ''}
                      onSave={handleFieldSave}
                      />
                    ) : (
                      <span> {matterDetails.closedate || 'N/A'}</span>
                    )}   </p>
                  </div>
                
                  </div>
                  <h1 className="about-line"></h1>
                  <div className="leader-stakeholder-container">
              <div >
              <p className="about-Leader-text"><FontAwesomeIcon icon={faUserTie} className="leader-icon" /><strong>Leader: </strong>    {matterDetails.employee_name}</p>
               </div>
                <div className="stakeholder-info-container">
                <FontAwesomeIcon icon={faBuilding} className="building-icon" />
                <p className="about-stakeholder-text">
                <strong>Client :</strong> {matterDetails.client_name}
              <p>
                  <strong>Client Type:</strong> 
              </p>
                </p>
              </div>
              </div>
                  <div class="popout-flex-container">
                  <div class="popout-left-container">
                  <div className="popout-case-data">
                    
                    <p><strong>IP Type:</strong>    
                 {isEditMode ? (
                      <EditableText
                      fieldName="mattertype"
                      value={matterDetails.mattertype || ''}
                      onSave={handleFieldSave}
                      />
                    ) : (
                      <span> {matterDetails.mattertype || 'N/A'}</span>
                    )}   
                    </p>
                    <p><strong>Case Type:</strong>    
                 {isEditMode ? (
                      <EditableText
                      fieldName="case_type"
                      value={matterDetails.case_type || ''}
                      onSave={handleFieldSave}
                      />
                    ) : (
                      <span> {matterDetails.case_type || 'N/A'}</span>
                    )}   
                    </p>
                    <p><strong>Country:</strong>
                   {isEditMode ? (
                      <EditableText
                      fieldName="country"
                      value={matterDetails.country || ''}
                      onSave={handleFieldSave}
                      />
                    ) : (
                      <span> {matterDetails.country || 'N/A'}</span>
                    )} 
                    </p>
                  </div>
                    <div className="popout-general-date"> 
                    <p><strong>Office Action Date:</strong> 
                   {isEditMode ? (
                   <EditableText
                      type="date"
                      fieldName="office_action_date"
                      value={matterDetails.office_action_date || ''}
                      onSave={handleFieldSave}
                      />
                    ) : (
                      <span> {matterDetails.office_action_date || 'N/A'}</span>
                    )} 
                        </p>
                    <p><strong>Expiry Date:</strong>    
                {isEditMode ? (
                   <EditableText
                   type="date"
                   fieldName="expiry_date"
                   value={matterDetails.expiry_date || ''}
                   onSave={handleFieldSave}
                      />
                    ) : (
                      <span> {matterDetails.expiry_date || 'N/A'}</span>
                    )} 
                        </p>
                    </div>
                    </div>

                    <div class="popout-right-container">
                <div className="popout-application-data">
                <p><strong>Application #:</strong> 
                {isEditMode ? (
                   <EditableText
                   type="text"
                   fieldName="application_no"
                   value={matterDetails.application_no || ''}
                   onSave={handleFieldSave}
                      />
                    ) : (
                      <span> {matterDetails.application_no || 'N/A'}</span>
                    )} 
                    </p>
                <p><strong>Application Date:</strong> 
                 {isEditMode ? (
                   <EditableText
                   type="date"
                   fieldName="application_data"
                   value={matterDetails.application_data || ''}
                   onSave={handleFieldSave}
                      />
                    ) : (
                   <span> {matterDetails.application_data || 'N/A'}</span>
                    )} 
                  </p>
                <p><strong>Application Status:</strong> 
                  {isEditMode ? (
                   <EditableText
                   fieldName="application_status"
                   value={matterDetails.application_status || ''}
                   onSave={handleFieldSave}
                      />
                    ) : (
                   <span> {matterDetails.application_status || 'N/A'}</span>
                    )} 
                  </p>
                </div>
                
                <div className="popout-registration-data">
                    <p><strong>Registration #:</strong>  
                  {isEditMode ? (
                   <EditableText
                   fieldName="registration_no "
                   value={matterDetails.registration_no || ''}
                   onSave={handleFieldSave}
                      />
                    ) : (
                   <span> {matterDetails.registration_no || 'N/A'}</span>
                    )}</p>           
                    <p><strong>Registration Date:</strong>    
                 {isEditMode ? (
                   <EditableText
                   type="date"
                   fieldName="registration_date"
                   value={matterDetails.registration_date || ''}
                   onSave={handleFieldSave}
                      />
                    ) : (
                   <span> {matterDetails.registration_date || 'N/A'}</span>
                    )}
                       </p>
                </div>
                </div>
                <div className="popout-publication-data">
                    <p><strong>Publication #:</strong>    
                {isEditMode ? (
                   <EditableText
                   fieldName="publication_no"
                   value={matterDetails.publication_no || ''}
                   onSave={handleFieldSave}
             />
                    ) : (
                   <span> {matterDetails.publication_no || 'N/A'}</span>
                    )}
                        </p>           
                    <p><strong>Publication Date:</strong>    
                  {isEditMode ? (
                   <EditableText
                   type="date"
                   fieldName="publication_date"
                   value={matterDetails.publication_date || ''}
                   onSave={handleFieldSave}
               />
                    ) : (
                   <span> {matterDetails.publication_date || 'N/A'}</span>
                    )}
                                  </p>
              </div>
              </div>
                    <p><strong>Description:</strong>      
               {isEditMode ? (
                   <EditableText
                   fieldName="description"
                   value={matterDetails.description || ''}
                   onSave={handleFieldSave}
               />
                    ) : (
                   <span> {matterDetails.description || 'N/A'}</span>
                    )}
                        </p>
                </div>
              </div>
            </div>

   <div className= "Milestone-task-container">
    <Milestone workflowid={matterDetails.workflowid} matterId={matterId} matterStatus={matterDetails.status} />
    <div className="checklistTasks-container" >    
  
    <div className= "deadline-Modal"><Deadline/></div>

      <div className= "checklist-Modal"><Checklist/></div>
      </div>
      {/* <div className="financial-container">
                    <p>
                     <FontAwesomeIcon icon={faDollarSign} className="financial-icon" />
                      <span className="amount">{matterDetails.amount}</span>
                    </p>
               </div> */}
               </div>
              </div>
              
              <div>
          
              </div>
              </div>
              <div>
 
              </div>

    <div className={`side-banner ${selectedTask ? 'visible' : ''}`}>
    <div className="side-banner-header">
        <h3>Task Details</h3>
        <button className="side-banner-button" onClick={() => setSelectedTask(null)}>Close</button>
    </div>

    {selectedTask && (
        <div className='task-details-container'>
            <div className="task-title-container">
                <p className="task-title">{selectedTask.task_title}</p>
                <div className="task-metadata-subheading">
                    <span><strong>ID:</strong> {selectedTask.taskid}</span>
                    <span><strong>Created By:</strong> {selectedTask.createdby}</span>
                </div>
            </div>

            <div className="status-container">
                <div className="Task-progress-bar-container" style={progressBarContainerStyle}>
                    <div className="Task-progress-bar-partition" style={progressBarPartitionStyle}></div>
                    <span className="status-text">{selectedTask.status}</span>
                </div>

                <div className="Task-milestone-buttons-container">
                    {selectedTask.status === 'Pending' && (
                        <button className="task-milestone-buttons" onClick={handleMarkInProgress}>Mark In Progress</button>
                    )}
                    {selectedTask.status === 'In Progress' && (
                        <>
                            <button className="task-milestone-buttons" onClick={handleMarkPending}>Mark Pending</button>
                            <button className="task-milestone-buttons" onClick={handleMarkCompleted}>Mark Completed</button>
                        </>
                    )}
                    {selectedTask.status === 'Completed' && (
                        <button className="task-milestone-buttons" onClick={handleMarkInProgress}>Mark In Progress</button>
                    )}
                </div>
            </div>

            <div className="task-info-container">
                <p><strong>Description:</strong> {selectedTask.description}</p>
                <p><strong>Due Date:</strong> {selectedTask.duedate}</p>
                <p><strong>Assigned To:</strong> {selectedTask.assignedto}</p>
                <p><strong>Status:</strong> {selectedTask.status}</p>
                <p><strong>Notes:</strong> {selectedTask.notes}</p>
            </div>
        </div>
    )}
</div>
            </div>
          </div>
        </div>

  );
};

export default GeneralTab;
