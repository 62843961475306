import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaUserCircle, FaCog, FaClock, FaRobot, FaBell  } from 'react-icons/fa';
import '../styles/NavBar.css';
import '../styles/General.css';
import '../styles/Timer.css';
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "../config/auth-config";
import { InteractionStatus } from "@azure/msal-browser";
import NavBarAuth from './NavBarAuth';

function NavBar() {
    const { instance, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const handleLogin = () => {
      instance.loginRedirect(loginRequest);
    };
    
    // return (  
    //   <div className="NavBar">
    //     <div className="NavBar-links"> 
    //       <Link to="/" style={{ color: 'black' }}><h1><strong>SEPT.</strong></h1></Link>
    //       <div className="NavBar-TextLinks">
    //         <Link to="/matters">Matters</Link>
    //         <Link to="/MyTasks">Tasks</Link>
    //         <Link to="/clients">Clients</Link>   
    //         <Link to="/agents">Agents</Link>   
    //       </div>
    //       <div className="NavBar-TextLinks">
    //       <Link to="/notifications"><FaBell size={24} /></Link>
    //         <Link onClick={toggleTimer} className="nav-link"> <FaClock size={24} /></Link> 
    //         {/* <Link to="/settings"><FaCog size={24} /></Link> */}
    //         <Link to="/Profile"> <FaUserCircle size={24} /> &nbsp; {userName}</Link>
    //       </div>
    //       <Link className="App_nav-links">Sign Out</Link>
    //         {showTimer && <Timer />} 
    //     </div>
    //   </div>
    // );

    if (isAuthenticated) {
      return (
        <NavBarAuth></NavBarAuth>
      );
    } else if (
      inProgress !== InteractionStatus.Startup &&
      inProgress !== InteractionStatus.HandleRedirect
    ) {
      // inProgress check prevents sign-in button from being displayed briefly after returning from a redirect sign-in. Processing the server response takes a render cycle or two
      return (
        <div className="NavBar">
          <div className="NavBar-links">
            <button onClick={handleLogin}>Log In</button>
          </div> 
        </div>
      );
    } else {
      return null;
    }
  }

export default NavBar;