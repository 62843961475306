import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './styles/tableStyle.css';
import './styles/General.css';
import './styles/Matter.css';
import CreateMatter from './CreateMatter';
import 'font-awesome/css/font-awesome.min.css';
import { FaRegTrashAlt } from 'react-icons/fa';
import getAuthHeaders from './functions/getAuthHeaders';
import LoadingSpinner from './components/LoadingSpinner';
import Reporting_Test from './Reporting/Reporting_Test.js';
import ReportingMyMatters from './Reporting/ReportingMyMatters.js';
import { useMsal } from '@azure/msal-react';

const Matters = ({ searchQuery }) => {
  const [matters, setMatters] = useState([]);
  const [myMatters, setMyMatters] = useState([]);
  const [searchBy, setSearchBy] = useState('');
  const [filterQuery, setFilterQuery] = useState('');
  const [activeTab, setActiveTab] = useState('myMatters');
  const [loadingMyMatters, setLoadingMyMatters] = useState(true);
  const { accounts } = useMsal();
  const [currentPageMy, setCurrentPageMy] = useState(1);
  const [currentPageAll, setCurrentPageAll] = useState(1);
  const mattersPerPage = 7;

  useEffect(() => {
    if (activeTab === 'myMatters') {
      fetchMyMatters();
    } else if (activeTab === 'allMatters') {
      fetchMatters();
    }
  }, [activeTab]);

  const handleDelete = async (matterId) => {
    const confirmation = window.confirm(
      'All corresponding items will be deleted with this matter. Are you sure you want to delete?'
    );
    if (!confirmation) {
      return;
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/delete/${matterId}`, {
        method: 'DELETE',
        headers: await getAuthHeaders(),
      });
      if (response.ok) {
        setMatters((prevMatters) => prevMatters.filter((matter) => matter.matterid !== matterId));
        setMyMatters((prevMatters) => prevMatters.filter((matter) => matter.matterid !== matterId));
      } else {
        console.error('Failed to delete matter');
      }
    } catch (error) {
      console.error('Error deleting matter:', error);
    }
  };

  const fetchMatters = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/allmatters`, {
        headers: await getAuthHeaders(),
      });
      const data = await response.json();
      setMatters(Array.isArray(data) ? data : []);
    } catch (error) {
      console.log('Error fetching matters:', error);
    }
  };

  const fetchMyMatters = async () => {
    if (myMatters.length === 0) {
      setLoadingMyMatters(true);
    }
    try {
      const { oid } = accounts[0].idTokenClaims;
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/mymatters/${oid}`, {
        headers: await getAuthHeaders(),
      });
      const data = await response.json();
      setMyMatters(Array.isArray(data) ? data : []);
    } catch (error) {
      console.log('Error fetching my matters:', error);
    }
    setLoadingMyMatters(false);
  };

  const handleSearchByChange = (event) => {
    setSearchBy(event.target.value);
  };

  const handleSearchChange = (event) => {
    setFilterQuery(event.target.value);
  };

  const filterBy = (column) => {
    const sortedMatters = [...matters];
    sortedMatters.sort((a, b) => {
      if (column === 'mattername') {
        return a[column].toLowerCase().localeCompare(b[column].toLowerCase());
      } else if (column === 'matterid' || column === 'employeeid' || column === 'clientid') {
        return a[column] - b[column];
      } else if (column === 'status') {
        return a[column].toLowerCase().localeCompare(b[column].toLowerCase());
      } else if (column === 'opendate' || column === 'closedate') {
        const dateA = new Date(a[column]);
        const dateB = new Date(b[column]);
        return dateA - dateB;
      }
      return 0;
    });
    setMatters(sortedMatters);
  };

  const highlightText = (text, query) => {
    if (!query) return text;
    const regex = new RegExp(`(${query})`, 'gi');
    return text.replace(regex, '<mark>$1</mark>');
  };

  const filteredMatters = matters.filter((matter) => {
    if (!searchBy || !filterQuery) return true;
    const field = matter[searchBy]?.toString().toLowerCase();
    return field?.includes(filterQuery.toLowerCase());
  });

  const filteredMyMatters = myMatters.filter((matter) => {
    if (!searchBy || !filterQuery) return true;
    const field = matter[searchBy]?.toString().toLowerCase();
    return field?.includes(filterQuery.toLowerCase());
  });

  const indexOfLastMy = currentPageMy * mattersPerPage;
  const indexOfFirstMy = indexOfLastMy - mattersPerPage;
  const currentMyMatters = filteredMyMatters.slice(indexOfFirstMy, indexOfLastMy);

  const indexOfLastAll = currentPageAll * mattersPerPage;
  const indexOfFirstAll = indexOfLastAll - mattersPerPage;
  const currentAllMatters = filteredMatters.slice(indexOfFirstAll, indexOfLastAll);

  const handleNextMy = () => {
    const totalPages = Math.ceil(filteredMyMatters.length / mattersPerPage);
    setCurrentPageMy((prevPage) => (prevPage < totalPages ? prevPage + 1 : prevPage));
  };

  const handlePreviousMy = () => {
    setCurrentPageMy((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
  };

  const handleNextAll = () => {
    const totalPages = Math.ceil(filteredMatters.length / mattersPerPage);
    setCurrentPageAll((prevPage) => (prevPage < totalPages ? prevPage + 1 : prevPage));
  };

  const handlePreviousAll = () => {
    setCurrentPageAll((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
  };

  return (
    <div className="backdrop">
    <div className="Add-matter-Matters-container"></div>
    <div className="tabs-container">
      <button
        className={`tab ${activeTab === 'myMatters' ? 'active-tab' : ''}`}
        onClick={() => setActiveTab('myMatters')}
      >
        My Matters
      </button>
      <button
        className={`tab ${activeTab === 'allMatters' ? 'active-tab' : ''}`}
        onClick={() => setActiveTab('allMatters')}
      >
        All Matters
      </button>
      <button
        className={`tab ${activeTab === 'CreateMatter' ? 'active-tab' : ''}`}
        onClick={() => setActiveTab('CreateMatter')}
      >
        Create a Matter
      </button>
    </div>

      <div>
        {activeTab === 'myMatters' && (
          <div className="table-container">
            {loadingMyMatters ? (
              <LoadingSpinner />
            ) : !Array.isArray(myMatters) || myMatters.length === 0 ? (
              <p>No matters assigned to you</p>
            ) : (
              <>
                <div className="Matter-add-search-sort-container">
                  <div className="Matter-search-sort-container"></div>
                  <div className="Matter-search-sort-container">
                    <div style={{ marginBottom: '9px' }}>
                      <ReportingMyMatters />
                    </div>
                    <div>
                      <input
                        type="text"
                        placeholder="Search"
                        value={filterQuery}
                        onChange={handleSearchChange}
                      />
                    </div>
                    <div className="MD-button-container" style={{ marginBottom: '25px' }}>
                      <select value={searchBy} onChange={handleSearchByChange} searchQuery={searchQuery}>
                        <option value="">Filter</option>
                        <option value="mattername">Matter Name</option>
                        <option value="matterid">Matter ID</option>
                        <option value="employeeid">Leader</option>
                        <option value="status">Status</option>
                        <option value="clientid">Client</option>
                        <option value="opendate">Open Date</option>
                        <option value="closedate">Close Date</option>
                      </select>
                    </div>
                  </div>
                </div>

                <table className="matter-table">
                  <thead>
                    <tr>
                      <th>
                        <button
                          onClick={() => filterBy(searchBy === 'mattername' ? '' : 'mattername')}
                          className="matter-link"
                        >
                          Matter Name
                        </button>
                      </th>
                      <th>
                        <button onClick={() => filterBy(searchBy === 'matterid' ? '' : 'matterid')}>
                          Matter ID
                        </button>
                      </th>
                      <th>
                        <button onClick={() => filterBy(searchBy === 'status' ? '' : 'status')}>Status</button>
                      </th>
                      <th>
                        <button onClick={() => filterBy(searchBy === 'clientid' ? '' : 'clientid')}>Client</button>
                      </th>
                      <th>
                        <button onClick={() => filterBy(searchBy === 'opendate' ? '' : 'opendate')}>Open Date</button>
                      </th>
                      <th>
                        <button onClick={() => filterBy(searchBy === 'closedate' ? '' : 'closedate')}>
                          Close Date
                        </button>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentMyMatters.map((myMatter) => (
                      <tr key={myMatter.matterid}>
                        <td>
                          <Link to={`/matters/${myMatter.matterid}`}>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: highlightText(myMatter.mattername, filterQuery),
                              }}
                            />
                          </Link>
                        </td>
                        <td>{myMatter.matterid}</td>
                        <td>{myMatter.status}</td>
                        <td>{myMatter.clientname}</td>
                        <td>{myMatter.opendate}</td>
                        <td>{myMatter.closedate}</td>
                      </tr>
                    ))}
                  </tbody>
                  <div className="pagination">
                    <button onClick={handlePreviousMy}>Previous</button>
                    <span>{currentPageMy}</span>
                    <button onClick={handleNextMy}>Next</button>
                  </div>
                </table>
              </>
            )}
          </div>
        )}

        {activeTab === 'allMatters' && (
          <div className="table-container">
            <div className="Matter-add-search-sort-container">
              <div className="Matter-search-sort-container"></div>
              <div className="Matter-search-sort-container">
                <div style={{ marginBottom: '9px' }}>
                  <Reporting_Test />
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="Search"
                    value={filterQuery}
                    onChange={handleSearchChange}
                  />
                </div>
                <div className="MD-button-container" style={{ marginBottom: '25px' }}>
                  <select value={searchBy} onChange={handleSearchByChange} searchQuery={searchQuery}>
                    <option value="">Filter</option>
                    <option value="mattername">Matter Name</option>
                    <option value="matterid">Matter ID</option>
                    <option value="employeeid">Leader</option>
                    <option value="status">Status</option>
                    <option value="clientid">Client</option>
                    <option value="opendate">Open Date</option>
                    <option value="closedate">Close Date</option>
                  </select>
                </div>
              </div>
            </div>

            <table className="matter-table">
              <thead>
                <tr>
                  <th>
                    <button
                      onClick={() => filterBy(searchBy === 'mattername' ? '' : 'mattername')}
                      className="matter-link"
                    >
                      Matter Name
                    </button>
                  </th>
                  <th>
                    <button onClick={() => filterBy(searchBy === 'matterid' ? '' : 'matterid')}>Matter ID</button>
                  </th>
                  <th>
                    <button onClick={() => filterBy(searchBy === 'employeeid' ? '' : 'employeeid')}>Leader</button>
                  </th>
                  <th>
                    <button onClick={() => filterBy(searchBy === 'status' ? '' : 'status')}>Status</button>
                  </th>
                  <th>
                    <button onClick={() => filterBy(searchBy === 'clientid' ? '' : 'clientid')}>Client</button>
                  </th>
                  <th>
                    <button onClick={() => filterBy(searchBy === 'opendate' ? '' : 'opendate')}>Open Date</button>
                  </th>
                  <th>
                    <button onClick={() => filterBy(searchBy === 'closedate' ? '' : 'closedate')}>Close Date</button>
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentAllMatters.map((matter) => (
                  <tr key={matter.matterid}>
                    <td>
                      <Link to={`/matters/${matter.matterid}`}>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: highlightText(matter.mattername, filterQuery),
                          }}
                        />
                      </Link>
                    </td>
                    <td>{matter.matterid}</td>
                    <td>{matter.employeename}</td>
                    <td>{matter.status}</td>
                    <td>{matter.clientname}</td>
                    <td>{matter.opendate}</td>
                    <td>{matter.closedate}</td>
                  </tr>
                ))}
              </tbody>
              <div className="pagination">
                <button onClick={handlePreviousAll}>Previous</button>
                <span>{currentPageAll}</span>
                <button onClick={handleNextAll}>Next</button>
              </div>
            </table>
          </div>
        )}
        {activeTab === 'CreateMatter' && <CreateMatter />}
      </div>
    
    
    <div className="add-task-form"> </div>
  
  </div>
  );
};

export default Matters;
